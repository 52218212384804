import React from 'react'
import { headerChange } from 'src/main/store-models/header/header'
import { useDispatch } from 'react-redux'
import { HeaderStyled, Logo, MenuHandle } from './styles'
import UserWidget from '../user_widget'
import LogoImage from '../../assets/images/logo.png'
import { Icons } from '../ui/components'

const Header = (): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <HeaderStyled>
      <MenuHandle onClick={() => dispatch(headerChange())}>
        <Icons
          name="RiMenuFill"
        />
      </MenuHandle>
      <Logo src={LogoImage} alt="LIGA CEVA" />
      <UserWidget />
    </HeaderStyled>
  )
}

export default Header
