import styled from 'styled-components'
import { theme } from '../../../../styles/styles'

interface ButtonInterface {
  backgroundColor?: string
}

const getColor = (color: string | null): any => (color ? color.match(/\w\w/g)?.map((x) => parseInt(x, 16)) : [0, 0, 0])

export const Button = styled.button`
  text-align: center;
  border: 1px solid transparent;
  transition: all .3s ease;
  cursor: pointer;
  font-weight: normal;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  font-size: ${theme.fontSizes.md};
  background: ${theme.colors.primary};
  color: ${theme.colors.black};
  max-width: 100%;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.15);

  &:hover {
    background-color: ${theme.colors.secondary};
    color: ${theme.colors.backgroundColor};
  }
  &:disabled {
    background: ${() => {
    const [r, g, b] = getColor(theme?.colors?.green)
    return `rgba(${r}, ${g}, ${b}, .7)`
  }};
    color: ${theme.colors.white};
    border-color: transparent;
    cursor: not-allowed;
  }
`

export const ButtonCancel = styled(Button)`
  background: ${theme.colors.gray['500']};
  color: ${theme.colors.white};

  &:hover {
    background-color: transparent;
    color: ${theme.colors.backgroundColor};
    border-color: ${theme.colors.backgroundColor};
  }

  &:disabled {
    background: ${() => {
    const [r, g, b] = getColor(theme?.colors?.backgroundColor)
    return `rgba(${r}, ${g}, ${b}, .3)`
  }};
      color: ${theme.colors.white};  
    };
`

export const ButtonOutline = styled(Button)`
  background-color: transparent;
  color: ${theme.colors.primary};
  border-color: ${theme.colors.primary};

  &:hover {
    background-color: rgba(0,0,0,0.05);
    color: ${theme.colors.secondary};
    border-color: ${theme.colors.secondary};
  }
  &:disabled {
    background: ${() => {
    const [r, g, b] = getColor(theme?.colors?.backgroundColor)
    return `rgba(${r}, ${g}, ${b}, .3)`
  }};
    color: ${theme.colors.white};
    border-color: transparent;
    cursor: not-allowed;
  }
`

export const ButtonRegistration = styled(Button)`
  background: #00C247;
  color: ${theme.colors.white};
  width: fit-content;
  padding: 0 30px;
  white-space: nowrap;
  margin-top: -5px;

  &:hover {
    background: #269653;
  }

  &:disabled {
    background: ${() => {
    const [r, g, b] = getColor(theme?.colors?.backgroundColor)
    return `rgba(${r}, ${g}, ${b}, .3)`
  }};
      color: ${theme.colors.white};  
    };
`

export const ActionButton = styled.button<ButtonInterface>`
  border: none;
  background-color: ${(props) => props.backgroundColor || theme.colors.lightGrayBlue};
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 0 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
