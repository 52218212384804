/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { makeRemoteClassification, makeRemoteClientType } from 'src/main/usecases/classification/classification'

export interface ClassificationStateInterface {
  list: any;
  loading: boolean;
  loaded: boolean;
  size?: number;
  page?: number;
  totalPages?: number;
  totalElements?: number;
  filterString?: string;
  classification?: string;
  filterType?: string;
  sort?: string;
  order?: string;
  clientsTypes: any[]
}

// Define the initial state using that type
const initialState: ClassificationStateInterface = {
  list: [],
  clientsTypes: [],
  loading: true,
  loaded: false,
}

export interface ClassificationParamsInterface {
  page: number;
  size: number;
  // sort?: string;
  filterString?: string;
  filterType?: string;
}

export const fetchClassification = createAsyncThunk(
  'classification/index',
  async (params: ClassificationParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(classificationLoading())

    const response = await makeRemoteClassification().index(params)

    thunkAPI.dispatch(classificationReceived())
    thunkAPI.dispatch(classificationLoaded())
    return response
  },
)

export const fetchClientsTypes = createAsyncThunk(
  'classification-clients/index',
  async () => {
    const response = await makeRemoteClientType().index()

    return response
  },
)

const slice = createSlice({
  name: 'classification',
  initialState,
  reducers: {
    classificationLoaded(state) {
      state.loaded = true
    },
    classificationLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    classificationReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
    classificationFilter(state, action) {
      state.filterType = action.payload.filterType
      state.filterString = action.payload.filterString
      state.classification = action.payload.classification
    },
    classificationFilterSort(state, action) {
      state.sort = action.payload
    },
    classificationFilterOrder(state, action) {
      state.order = action.payload
    },
    classificationOrderAndSort(state, action) {
      state.order = action.payload.order
      state.sort = action.payload.sort
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchClassification.fulfilled, (state, action) => {
      state.list = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
    builder.addCase(fetchClientsTypes.fulfilled, (state, action) => {
      state.clientsTypes = action.payload
    })
  },
})

export const {
  classificationLoaded,
  classificationLoading,
  classificationReceived,
  classificationFilter,
  classificationFilterSort,
  classificationFilterOrder,
  classificationOrderAndSort,
} = slice.actions

export default slice.reducer
