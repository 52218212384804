/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions, import/no-unresolved */
import Qs from 'qs'
import fileDownload from 'js-file-download'
import moment from 'moment'
import { HttpStatusCode } from '../../protocols/http/http-client'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'

export class RemoteDistributions {
  url: string

  httpClient: any

  constructor(url: string, httpClient: any) {
    this.url = url
    this.httpClient = httpClient
  }

  async index(params?: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      params,
      paramsSerializer(paramsAux: any) {
        return Qs.stringify(paramsAux, { arrayFormat: 'repeat' })
      },
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async update(body: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'put',
      data: body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.noContent: return true
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  async create(data: any, params?: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      params,
      data,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.noContent: return undefined
      case HttpStatusCode.created: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async delete() {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'delete',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.noContent: return true
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }

  async export(params: any) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/export`,
      method: 'get',
      responseType: 'arraybuffer',
      params,
      paramsSerializer(paramsAux: any) {
        return Qs.stringify(paramsAux, { arrayFormat: 'repeat' })
      },
    })

    fileDownload(httpResponse.body, `DISTRIBUIDORES_${moment(new Date()).format('yyyy-MM-DD')}.xlsx`)
  }
}
