import { RemoteAddGoal } from '../../../data/usecases/add-goal/add-goal'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory-no-redirect'

export const makeRemoteAddGoal = ():any => new RemoteAddGoal('/meta/grupo', makeAxiosHttpClient())

export const makeRemotePeopleAll = ():any => new RemoteAddGoal('/pessoa/all', makeAxiosHttpClient())

export const makeRemotePeopleByBu = (divisao: string):any => new RemoteAddGoal(`/pessoa/bydivisao/${divisao}`, makeAxiosHttpClient())

export const makeRemotePeopleByBuCode = (divisao: string, codigo: number):any => new RemoteAddGoal(`/pessoa/manager/${divisao}/${codigo}`, makeAxiosHttpClient())
