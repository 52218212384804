/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  makeRemoteQuotasManagers,
  makeRemoteQuotasManagersManager,
  makeRemoteQuotasManagersTotal,
  makeRemoteQuotasManagersStatus,
  makeRemoteQuotasManagersItemsPopUp,
  makeRemoteUpdateLine,
  makeUpdateGrowth,
  makeUpdateActualQuota,
  makeUpdateQtdQuota,
  makeUpdateDivisionQuota,
} from '../../usecases/quotas-managers/quotas-managers'

interface QuotaManagersStatus {
  readOnly: boolean,
  init?: boolean,
  status: string,
  showButtonAprovacao?: boolean,
  showButtonReprovacao?: boolean,
  showButtonRevisao?: boolean,
  showButtonEnviarAprovacao?: boolean,
  dataReferencia?: string
  aprovacoes: {
    dataHoraConfirmacao: string,
    ordem: number,
    papel: string,
    statusAprovacao: string,
    usuario: string
  }[],
  comentarios: {
    dataHora: string,
    reprovacao: boolean,
    texto: string,
    usuario: string
  }[],
}
export interface QuotasManagersStateInterface {
  quotas: any,
  loading: boolean,
  loaded: boolean,
  size?: number,
  page?: number,
  totalPages?: number,
  totalElements?: number,
  filters: any[]
  othersFilters?: {
    hasMesValor: boolean,
    shareAcimaPendente: boolean,
    shareAbaixoPendente: boolean,
  };
  status: QuotaManagersStatus,

  // headers
  percentualCrescimento?: number,
  valorTotalLiquidoHistorico?: number,
  valorTotalLiquido?: number,
  valorTotal?: number,
  percentualTotalCotasFilhas?: number,
  headerLoading: boolean,
  onlyHeaderLoading?: boolean,
  headerLoaded: boolean,
  nome?: string,

  // managers
  managers: any[],
  managerLoading: boolean,
  managerloaded: boolean,
}

export interface QuotasManagersParamsInterface {
  divisao: string,
  page?: number,
  size?: number,
  sort?: string,
  hasMesValor?: boolean,
  descricaoItem?: string[],
  numeroItem?: string[],
  marca?: string[],
  configuracaoPeriodoId?: number,
  codigos: string[],
  shareAcimaPendente?: boolean,
  shareAbaixoPendente?: boolean,
  id?: string,
  gerente: any,
}

export interface QuotasManagersHeaderUpdateInterface {
  divisao: string,
  codigos: string[],
  gerente: any,
  data: {
    percentualCrescimento: number,
    configuracaoPeriodoId?: number,
  }
}

export interface GetLineQuotas {
  divisao: string,
  numeroItem: string,
  gerente: any,
  codigos: string[],
  configuracaoPeriodoId: number,
}

export interface UpdateLineQuotas {
  divisao: string,
  gerente: string,
  codigos: string[],
  numeroItem: string,
  configuracaoPeriodoId: number,
  percentualCrescimento?: number,
  valor: number,
  quantidade: number,
  divisionToUpdate: string,
}

// Define the initial state using that type
const initialState: QuotasManagersStateInterface = {
  quotas: [],
  loading: false,
  loaded: false,
  filters: [],
  managers: [],
  status: {
    readOnly: true,
    status: 'NAO_CADASTRADA',
    aprovacoes: [],
    comentarios: [],
  },

  headerLoading: true,
  headerLoaded: false,

  managerLoading: false,
  managerloaded: false,
}

export const fetchQuotasManagersStatus = createAsyncThunk(
  'quotas-managers/status',
  async (params: QuotasManagersParamsInterface) => {
    const {
      divisao, gerente, codigos, ...newParams
    } = params

    const response = await makeRemoteQuotasManagersStatus({
      divisao, gerente, codigos,
    }).index(newParams)
    return response
  },
)

export const fetchQuotasManagers = createAsyncThunk(
  'quotas-managers/current',
  async (params: QuotasManagersParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(quotasManagersLoading())

    const {
      divisao, gerente, codigos, ...newParams
    } = params

    const response = await makeRemoteQuotasManagers({
      divisao, gerente, codigos,
    }).index(newParams)

    thunkAPI.dispatch(quotasManagersReceived())
    thunkAPI.dispatch(quotasManagersLoaded())
    return response
  },
)

export const fetchQuotasManagersManager = createAsyncThunk(
  'quotas-managers/manager',
  async (params: QuotasManagersParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(quotasManagersManagerLoading())

    const {
      divisao, gerente, codigos, ...newParams
    } = params

    const response = await makeRemoteQuotasManagersManager({
      divisao, gerente, codigos,
    }).index(newParams)

    thunkAPI.dispatch(quotasManagersManagerReceived())
    thunkAPI.dispatch(quotasManagersManagerLoaded())
    return response
  },
)

export const fetchQuotasManagersHeader = createAsyncThunk(
  'quotas-managers/header',
  async (params: QuotasManagersParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(quotasManagersHeaderLoading())

    const {
      divisao, gerente, codigos, ...newParams
    } = params

    const response = await makeRemoteQuotasManagersTotal({
      divisao, gerente, codigos,
    }).index(newParams)

    thunkAPI.dispatch(quotasManagersHeaderReceived())
    thunkAPI.dispatch(quotasManagersHeaderLoaded())
    return response
  },
)

// only loading header
export const OnlyQuotasManagersHeader = createAsyncThunk(
  'quotas-managers/get-header',
  async (params: QuotasManagersParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(quotasOnlyManagersHeaderLoading(true))

    const {
      divisao, gerente, codigos, ...newParams
    } = params

    const response = await makeRemoteQuotasManagersTotal({
      divisao, gerente, codigos,
    }).index(newParams)

    thunkAPI.dispatch(quotasOnlyManagersHeaderLoading(false))
    return response
  },
)

export const fetchQuotasManagersHeaderUpdate = createAsyncThunk(
  'quotas-managers/header-update',
  async (params: QuotasManagersHeaderUpdateInterface, thunkAPI) => {
    thunkAPI.dispatch(quotasOnlyManagersHeaderLoading(true))

    const {
      divisao, gerente, codigos, data,
    } = params

    const response = await makeRemoteQuotasManagers({
      divisao, gerente, codigos,
    }).update(data)

    thunkAPI.dispatch(quotasOnlyManagersHeaderLoading(false))
    return response
  },
)

export const fetchManagersItemsPopUp = createAsyncThunk(
  'quotas-managers/items-popUp',
  async (params: QuotasManagersParamsInterface) => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      divisao, gerente, codigos, id, ...newParams
    } = params

    const response = await makeRemoteQuotasManagersItemsPopUp(params).load(id, newParams)

    return response
  },
)

export const getLineQuotasManagers = createAsyncThunk(
  'quotas-managers/get-line',
  async (params: GetLineQuotas, thunkAPI) => {
    thunkAPI.dispatch(quotasManagersLineLoading(params.numeroItem))

    const response = await makeRemoteUpdateLine({ ...params, id: params.numeroItem })
      .load('', { configuracaoPeriodoId: params.configuracaoPeriodoId })

    return response
  },
)

export const updateLineQuotasManagers = createAsyncThunk(
  'quotas-managers/update-line',
  async (params: UpdateLineQuotas, thunkAPI) => {
    thunkAPI.dispatch(quotasManagersLineLoading(params.numeroItem))

    if (typeof params.percentualCrescimento !== 'undefined') {
      const response = await makeUpdateGrowth(params).updateLine({
        percentualCrescimento: params.percentualCrescimento,
        configuracaoPeriodoId: params.configuracaoPeriodoId,
      })

      return response
    }

    if (typeof params.valor !== 'undefined') {
      const response = await makeUpdateActualQuota(params).updateLine({
        valor: params.valor,
        configuracaoPeriodoId: params.configuracaoPeriodoId,
      })

      return response
    }
    if (typeof params.quantidade !== 'undefined') {
      const response = await makeUpdateQtdQuota(params).updateLine({
        quantidade: params.quantidade,
        configuracaoPeriodoId: params.configuracaoPeriodoId,
      })

      return response
    }
    if (typeof params.divisionToUpdate !== 'undefined') {
      const response = await makeUpdateDivisionQuota(params).updateLine({
        divisao: params.divisionToUpdate,
        configuracaoPeriodoId: params.configuracaoPeriodoId,
      })

      return response
    }

    return Promise.reject(new Error('Campo não habilidato para edição'))
  },
)

const slice = createSlice({
  name: 'quotas-managers',
  initialState,
  reducers: {
    quotasManagersLineLoading(state: QuotasManagersStateInterface, action) {
      state.quotas = state.quotas.map((item: any) => {
        if (item.numeroItem === action.payload) {
          return ({
            ...item,
            loading: true,
          })
        }
        return item
      })
    },
    quotasManagersLoaded(state: QuotasManagersStateInterface) {
      state.loaded = true
    },
    quotasManagersLoading(state: QuotasManagersStateInterface) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    quotasManagersReceived(state: QuotasManagersStateInterface) {
      if (state.loading) {
        state.loading = false
      }
    },
    quotasManagersManagerLoaded(state: QuotasManagersStateInterface) {
      state.managerloaded = true
    },
    quotasManagersManagerLoading(state: QuotasManagersStateInterface) {
      if (!state?.managerLoading) {
        state.managerLoading = true
      }
    },
    quotasManagersManagerReceived(state: QuotasManagersStateInterface) {
      if (state.managerLoading) {
        state.managerLoading = false
      }
    },

    quotasManagersHeaderLoaded(state: QuotasManagersStateInterface) {
      state.headerLoaded = true
    },
    quotasManagersHeaderLoading(state: QuotasManagersStateInterface) {
      if (!state?.headerLoading) {
        state.headerLoading = true
      }
    },
    quotasOnlyManagersHeaderLoading(state: QuotasManagersStateInterface, action) {
      state.onlyHeaderLoading = !!action.payload
    },
    quotasManagersHeaderReceived(state: QuotasManagersStateInterface) {
      if (state.headerLoading) {
        state.headerLoading = false
      }
    },
    quotasManagersAllLoading(state: QuotasManagersStateInterface) {
      state.loading = true
      state.headerLoading = true
      state.managerLoading = true
    },
    quotasManagersFilters(state: QuotasManagersStateInterface, action) {
      state.filters = action.payload
    },
    quotasManagersUpdateOthersFilters(state: QuotasManagersStateInterface, action) {
      state.othersFilters = action.payload
    },
    resetState(state: QuotasManagersStateInterface) {
      state.quotas = []
      state.loading = false
      state.loaded = false
      state.filters = []
      state.managers = []
      state.status = {
        readOnly: true,
        status: 'NAO_CADASTRADA',
        aprovacoes: [],
        comentarios: [],
      }

      state.headerLoading = true
      state.headerLoaded = false

      state.managerLoading = false
      state.managerloaded = false

      state.percentualCrescimento = undefined
      state.valorTotalLiquidoHistorico = undefined
      state.valorTotalLiquido = undefined
      state.valorTotal = undefined
      state.percentualTotalCotasFilhas = undefined
      state.onlyHeaderLoading = undefined
      state.nome = undefined
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchQuotasManagers.fulfilled, (state, action) => {
      state.quotas = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
    builder.addCase(fetchQuotasManagersManager.fulfilled, (state, action) => {
      state.managers = action.payload
    })
    builder.addCase(fetchQuotasManagersHeader.fulfilled, (state, action) => {
      state.percentualCrescimento = action.payload.percentualCrescimento
      state.valorTotalLiquidoHistorico = action.payload.valorTotalLiquidoHistorico
      state.valorTotalLiquido = action.payload.valorTotalLiquido
      state.valorTotal = action.payload.valorTotal
      state.percentualTotalCotasFilhas = action.payload.percentualTotalCotasFilhas
      state.nome = action.payload.nome
    })
    builder.addCase(OnlyQuotasManagersHeader.fulfilled, (state, action) => {
      state.percentualCrescimento = action.payload.percentualCrescimento
      state.valorTotalLiquidoHistorico = action.payload.valorTotalLiquidoHistorico
      state.valorTotalLiquido = action.payload.valorTotalLiquido
      state.valorTotal = action.payload.valorTotal
      state.percentualTotalCotasFilhas = action.payload.percentualTotalCotasFilhas
      state.nome = action.payload.nome
    })
    builder.addCase(fetchQuotasManagersStatus.fulfilled, (state, action) => {
      const temp = action.payload.status === 'NAO_CADASTRADA' && !action.payload.readOnly
      state.status = action.payload
      state.status.init = temp
      state.status.readOnly = temp || action.payload.readOnly

      if (temp) {
        state.loading = false
        state.loaded = true
        state.managerLoading = false
        state.managerloaded = true
        state.headerLoading = false
        state.headerLoaded = true
      }
    })
    builder.addCase(getLineQuotasManagers.fulfilled, (state, action) => {
      state.quotas = state.quotas.map((item: any) => {
        if (item.numeroItem === action?.payload.numeroItem) {
          return action.payload
        }

        return item
      })
    })
    builder.addCase(updateLineQuotasManagers.fulfilled, (state, action) => {
      state.quotas = state.quotas.map((item: any) => {
        if (item.numeroItem === action?.payload.numeroItem) {
          return action.payload
        }
        return item
      })
    })
  },
})

export const {
  quotasManagersLoaded,
  quotasManagersLoading,
  quotasManagersReceived,
  quotasManagersManagerLoaded,
  quotasManagersManagerLoading,
  quotasManagersManagerReceived,
  quotasManagersHeaderLoaded,
  quotasManagersHeaderLoading,
  quotasManagersHeaderReceived,
  quotasManagersAllLoading,
  quotasManagersLineLoading,
  quotasManagersFilters,
  quotasOnlyManagersHeaderLoading,
  quotasManagersUpdateOthersFilters,
  resetState,
} = slice.actions

export default slice.reducer
