export const settings = {
  siteName: process.env.REACT_APP_TITLE,
  API_URL: process.env.REACT_APP_API_URL,
  TOKEN: process.env.REACT_APP_TOKEN || 'TOKEN',
  DOMAIN: process.env.REACT_APP_DOMAIN || 'localhost',
  perPage: 15,
  perPageBu: 5,
  perPageOptions: [15, 30, 50, 100],
  perPageBuOptions: [5, 10, 20, 30],
  pagination: {
    pageRangeDisplayed: 3,
    hideDisabled: true,
    hideFirstLastPages: false,
    hideNavigation: true,
    linkClassFirst: 'arrow first',
    linkClassPrev: 'arrow prev',
    linkClassNext: 'arrow next',
    linkClassLast: 'arrow last',
  },
}
