/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteCustomerGoals } from '../../usecases/customer-goals/customer-goals'

export interface CustomerGoalsParamsProps {
  ano?: number
  configuracaoPeriodoId?: number
  divisao?: string,
  filterString?: string,
  filterType?: string,
  status?: string,
  size?: number,
  page?: number,
  sort?: string
}
export interface CustomerGoalStateInterface {
  list: any,
  loading: boolean,
  loaded: boolean,
  totalPages?: number,
  totalElements?: number,
  params: CustomerGoalsParamsProps
}

// Define the initial state using that type
const initialState: CustomerGoalStateInterface = {
  list: [],
  loading: true,
  loaded: false,
  params: {
    size: 5,
    page: 0,
  },
}

export interface DashboardKpiParamsInterface {
  id?: string,
  codigoGrupo?: number|null,
  numeroItem?: string|null,
  configuracaoPeriodoId?: number|null,
  divisao?: string|null,
  trimestre?: number|null,
  size?: number,
  page?: number
}

export const fetchCustomerGoals = createAsyncThunk(
  'customer-goals/index',
  async (params: CustomerGoalsParamsProps, thunkAPI) => {
    thunkAPI.dispatch(customerGoalsLoading())

    const response = await makeRemoteCustomerGoals().index({
      page: 0,
      size: 5,
      ...params,
    })

    thunkAPI.dispatch(customerGoalsReceived())
    thunkAPI.dispatch(customerGoalsLoaded())
    return response
  },
)

const slice = createSlice({
  name: 'customer-goals',
  initialState,
  reducers: {
    customerGoalsLoaded(state) {
      state.loaded = true
    },
    resetCustomerGoals(state) {
      Object.assign(state, initialState)
    },
    customerGoalsLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    customerGoalsReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
    updateParamsCustomerGoals(state, action) {
      state.params.configuracaoPeriodoId = action.payload?.configuracaoPeriodoId
      state.params.divisao = action.payload?.divisao
      state.params.filterString = action.payload?.filterString
      state.params.filterType = action.payload?.filterType
      state.params.page = action.payload?.page
      state.params.size = action.payload?.size
      state.params.sort = action.payload?.sort
      state.params.status = action.payload?.status
    },

  },

  extraReducers: (builder) => {
    builder.addCase(fetchCustomerGoals.fulfilled, (state, action) => {
      state.list = action.payload.content
      state.params.size = action.payload.size
      state.params.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
  },
})

export const {
  customerGoalsLoaded,
  customerGoalsLoading,
  customerGoalsReceived,
  updateParamsCustomerGoals,
  resetCustomerGoals,
} = slice.actions

export default slice.reducer
