/* eslint-disable @typescript-eslint/no-use-before-define, no-param-reassign */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { TokenHelper } from 'src/presentation/helpers/token-helper/token-helper'
import { makeVerifyAcceptProgramRules } from '../../usecases/program-rules/program-rules'
import { ProgramRulesVerifyResponseInterface } from '../../interfaces/models/program-rules'
import { LoginRequestInterface, LoginResponseInterface } from '../../interfaces/models/auth'
import { makeRemoteSignIn, makeRemoteMe } from '../../usecases/auth/auth'

export interface AuthStateInterface {
  user: LoginResponseInterface | undefined,
  accepted: ProgramRulesVerifyResponseInterface | undefined
  loading: boolean,
  loaded: boolean,
}

interface LoginRequestInterfaceBase extends LoginRequestInterface{
  rememberAccess: boolean
  login: string
  senha: string
}

// Define the initial state using that type
const initialState: AuthStateInterface = {
  user: undefined,
  accepted: undefined,
  loading: true,
  loaded: false,
}

export const signInUser = createAsyncThunk(
  'auth/signIn',
  async ({ login, senha, rememberAccess }: LoginRequestInterfaceBase, thunkAPI) => {
    thunkAPI.dispatch(userLoading())

    const responseSignIn = await makeRemoteSignIn().post({
      login,
      senha,
    })

    const response = { ...responseSignIn }

    if (responseSignIn?.token) {
      TokenHelper.set(responseSignIn.token, rememberAccess)
    }

    thunkAPI.dispatch(userReceived())
    thunkAPI.dispatch(userLoaded())

    return response
  },
)

export const loadUser = createAsyncThunk(
  'auth/load',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(userLoading())

    const response = await makeRemoteMe().get()

    thunkAPI.dispatch(userReceived())
    thunkAPI.dispatch(userLoaded())

    return response
  },
)

export const verifyAccepted = createAsyncThunk(
  'auth/accepted',
  async (params: {ano: number}) => {
    const response = await makeVerifyAcceptProgramRules().load(params)

    return response
  },
)

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userLoaded(state: AuthStateInterface) {
      state.loaded = true
    },
    userLoading(state: AuthStateInterface) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    userReceived(state: AuthStateInterface) {
      if (state.loading) {
        state.loading = false
      }
    },
    userLogout(state: AuthStateInterface) {
      state.loaded = false
      state.loading = false
      state.user = undefined
      state.accepted = undefined

      TokenHelper.remove()
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadUser.fulfilled, (state, action) => {
      state.user = action.payload
    })
    builder.addCase(verifyAccepted.fulfilled, (state, action) => {
      state.accepted = action.payload
    })
  },
})

export const {
  userLoaded,
  userLoading,
  userReceived,
  userLogout,
} = slice.actions

export default slice.reducer
