/* eslint-disable react/require-default-props */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import React from 'react'

interface IconProps {
  name: string,
  [key: string]: any
}

export const Icons = ({ name, ...props }: IconProps):JSX.Element => {
  const { [name]: Component } = require('react-icons/ri')
  return <Component {...props} />
}
