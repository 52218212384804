import React from 'react'
import Select from 'react-select'
import { theme } from 'src/presentation/styles/styles'

interface SelectInterface {
  disabled?: boolean,
  options: {label: string, value: any}[],
  placeholder?: string,
  [key: string]: any,
}

const SelectComp = ({ ...props }: SelectInterface): JSX.Element => (
  <Select
    styles={{
      control: (base: any) => ({
        ...base,
        borderRadius: 10,
        borderWidth: 0.5,
        borderColor: '#868686',
        boxShadow: undefined,
        paddingTop: 5,
        paddingBottom: 5,
        fontSize: theme.fontSizes.uxs,
        backgroundColor: !props.disabled ? 'transparent' : '#c4c4c433',
        ':hover': {
          borderColor: '#868686',
        },
      }),
      menu: (base: any) => ({ ...base, borderRadius: 8 }),
      placeholder: (base: any) => ({
        ...base,
        fontSize: theme.fontSizes.uxs,
        color: '#acacac',
      }),
      option: (base: any) => ({
        ...base,
        fontSize: theme.fontSizes.uxs,
      }),
      singleValue: (base: any) => ({
        ...base,
        fontSize: theme.fontSizes.uxs,
      }),
      indicatorSeparator: (base: any) => ({
        ...base,
        display: 'none',
      }),

    }}
    noOptionsMessage={() => <>Sem opções</>}
    {...props}
    isDisabled={props.disabled}
  />
)

export default SelectComp
