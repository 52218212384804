/* eslint-disable import/extensions, import/no-unresolved */

import { RemoteDashboard } from '../../../data/usecases/dashboard/dashboard'
import { makeAxiosHttpClient as makeAxiosNoRedirect } from '../../factories/http/axios-http-client-factory-no-redirect'

/* Endpoints Filtros */
export const makeDashboardFiltroPeriodo = (): any => new RemoteDashboard('/dashboard/validacao/filtro/configuracaoperiodo', makeAxiosNoRedirect())
export const makeDashboardFiltroDivisao = (): any => new RemoteDashboard('/dashboard/validacao/filtro/divisao', makeAxiosNoRedirect())
export const makeDasboardMarca = ():any => new RemoteDashboard('/dashboard/validacao/filtro/marca', makeAxiosNoRedirect())
export const makeDashboardNacional = (): any => new RemoteDashboard('/dashboard/validacao/filtro/nacional', makeAxiosNoRedirect())
export const makeDashboardRegional = (): any => new RemoteDashboard('/dashboard/validacao/filtro/regional', makeAxiosNoRedirect())
export const makeDashboardVendedor = (): any => new RemoteDashboard('/dashboard/validacao/filtro/vendedor', makeAxiosNoRedirect())
export const makeDashboardTipo = (): any => new RemoteDashboard('/dashboard/validacao/filtro/tipo', makeAxiosNoRedirect())
export const makeDashboardGrupo = (): any => new RemoteDashboard('/dashboard/validacao/filtro/grupo', makeAxiosNoRedirect())

/* Endpoints Empresa */
export const makeValidationEmpresa = (): any => new RemoteDashboard('/dashboard/validacao/empresa', makeAxiosNoRedirect())
export const makeValidationEmpresaDivisao = (): any => (
  new RemoteDashboard('/dashboard/validacao/empresa/detalhe-divisao', makeAxiosNoRedirect())
)
export const makeValidationEmpresaKpi = (): any => (
  new RemoteDashboard('/dashboard/validacao/empresa/detalhe-kpi', makeAxiosNoRedirect())
)

/* Endpoints Nacional */
export const makeValidationNacional = (): any => new RemoteDashboard('/dashboard/validacao/nacional', makeAxiosNoRedirect())
export const makeValidationNacionalManager = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/nacional/${codigoJDE}`, makeAxiosNoRedirect())
)
export const makeValidationNacionalManagerDivisao = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/nacional/${codigoJDE}/detalhe-divisao`, makeAxiosNoRedirect())
)
export const makeValidationNacionalManagerKpi = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/nacional/${codigoJDE}/detalhe-kpi`, makeAxiosNoRedirect())
)

/* Endpoints Regional */
export const makeValidationRegional = (): any => new RemoteDashboard('/dashboard/validacao/regional', makeAxiosNoRedirect())
export const makeValidationRegionalManager = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/regional/${codigoJDE}`, makeAxiosNoRedirect())
)
export const makeValidationRegionalManagerDivisao = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/regional/${codigoJDE}/detalhe-divisao`, makeAxiosNoRedirect())
)
export const makeValidationRegionalManagerKpi = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/regional/${codigoJDE}/detalhe-kpi`, makeAxiosNoRedirect())
)

/* Endpoints Vendedor */
export const makeValidationVendedor = (): any => new RemoteDashboard('/dashboard/validacao/vendedor', makeAxiosNoRedirect())
export const makeValidationVendedorManager = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/vendedor/${codigoJDE}`, makeAxiosNoRedirect())
)
export const makeValidationVendedorManagerDivisao = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/vendedor/${codigoJDE}/detalhe-divisao`, makeAxiosNoRedirect())
)
export const makeValidationVendedorManagerKpi = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/vendedor/${codigoJDE}/detalhe-kpi`, makeAxiosNoRedirect())
)

/* Endpoints Cliente */
export const makeValidationCliente = (): any => new RemoteDashboard('/dashboard/validacao/cliente', makeAxiosNoRedirect())
export const makeValidationClienteManager = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/cliente/${codigoJDE}`, makeAxiosNoRedirect())
)
export const makeValidationClienteManagerDivisao = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/cliente/${codigoJDE}/detalhe-divisao`, makeAxiosNoRedirect())
)
export const makeValidationClienteManagerKpi = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/validacao/cliente/${codigoJDE}/detalhe-kpi`, makeAxiosNoRedirect())
)
