import {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  CurrentUser, StoreStateInterface, loadUser, updateCurrentUser,
} from 'src/main/interfaces/redux/redux'
import { closeHeader, closeUserEdit, headerChange } from 'src/main/store-models/header/header'
import { PermissionHelper } from 'src/presentation/helpers/permission-helper/permission-helper'

import { theme } from 'src/presentation/styles/styles'
import { showPopUp } from 'src/main/store-models/modal/modal'
import { AppDispatch } from '@store'
import Footer from '../../footer'
import Header from '../../header'
import { Icons } from '../../ui/components'
import CurrentUserUpdate from '../../current_user_registration'
import SidebarItem from './sidebar-item'

import * as S from './styles'

interface BaseLayoutProps {
  children: JSX.Element | JSX.Element[];
}

const BaseLayout = ({ children }: BaseLayoutProps): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>()
  const header = useSelector((state: StoreStateInterface) => state.header)
  const auth = useSelector((state: StoreStateInterface) => state.auth)
  const { isOpenUserEdit } = useSelector((state: StoreStateInterface) => state.header)
  const [leftBarWidth, setLeftBarWidth] = useState<number>(0)
  const refLeftBar = useRef<HTMLDivElement>(null)
  const [pages, setPages] = useState<any[]>([])

  useLayoutEffect(() => {
    if (auth.user) {
      const isClient = PermissionHelper.start().isClient()
      if (isClient && typeof auth?.accepted !== 'boolean') {
        return
      }

      const temp = PermissionHelper.allPagesCanAccess()
      setPages(temp)
    }
  }, [auth.user, auth.accepted])

  useEffect(() => {
    if (refLeftBar?.current?.offsetWidth) {
      setLeftBarWidth(refLeftBar.current.offsetWidth)
    }
  }, [header?.isOpen, refLeftBar?.current?.offsetWidth])

  const updateCurrentUserLocal = async (data: CurrentUser): Promise<void> => {
    const temp = {
      email: data.email,
      login: data.login,
      nome: data.nome,
    }

    if (data.senha) {
      Object.assign(temp, {
        senha: data.senha,
      })
    }

    try {
      dispatch(closeUserEdit())
      dispatch(showPopUp({
        title: 'Atualizando',
        subTitle: 'Atualizando usuário...',
      }))

      await dispatch(updateCurrentUser(temp)).unwrap()
      await dispatch(loadUser())
      dispatch(showPopUp({
        title: 'Sucesso',
        subTitle: 'Edição realizada com sucesso',
      }))
    } catch (error) {
      dispatch(showPopUp({
        title: 'Oops',
        subTitle: (error as Error).message,
        buttons: [{
          title: 'OK',
          action() {
            dispatch(closeUserEdit())
          },
        }],
      }))
    }
  }

  return (
    <S.Container>
      <S.InternContainer>
        <S.BackDrop
          open={header?.isOpen}
          onClick={() => {
            dispatch(closeHeader())
          }}
        />
        {pages.length > 0 && (
          <S.LeftBar className="menu" open={header?.isOpen} ref={refLeftBar}>
            <S.IconMenu
              className="iconMenu"
              onClick={() => {
                dispatch(headerChange())
              }}
            >
              <Icons
                name="RiMenuLine"
                size={22}
                color={theme.colors.lightGrayBlue}
              />
            </S.IconMenu>
            {pages.map((item: any) => (
              <SidebarItem key={item.title} item={item} />
            ))}
          </S.LeftBar>
        )}

        <S.RightContent className="teste" leftBar={leftBarWidth || 0}>
          {auth.user && isOpenUserEdit && (
            <CurrentUserUpdate
              handleModal={() => {
                dispatch(closeUserEdit())
              }}
              onSubmit={updateCurrentUserLocal}
              data={auth.user}
              editModel={header.editModel}
              title={header.title}
              onlyAuth={header.onlyAuth}
            />
          )}
          <Header />
          <S.Content>{children}</S.Content>
        </S.RightContent>
      </S.InternContainer>
      <Footer />
    </S.Container>
  )
}

export default BaseLayout
