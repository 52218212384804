import React from 'react'
import { Button as ButtonUI } from '../index'

interface ButtonProps {
  title: string,
  submittingText: string,
  isSubmitting: boolean,
  isValid?: boolean
  withDisable?: boolean
  dirty?: boolean
}

const Button = ({
  title, submittingText, isSubmitting, withDisable, ...el
}: ButtonProps): JSX.Element => {
  const disabledAttr = withDisable
    ? {
      disabled: !(el.isValid && el.dirty),
    }
    : {}

  return (
    <ButtonUI disabled={isSubmitting} type="submit" {...disabledAttr}>{!isSubmitting ? title : submittingText}</ButtonUI>
  )
}

Button.defaultProps = {
  isValid: false,
  withDisable: false,
  dirty: false,
}

export default Button
