import { configureStore } from '@reduxjs/toolkit'

import CompaniesReducer from '../store-models/companies/companies'
import UsersReducer from '../store-models/users/users'
import ApprovalGroupReducer from '../store-models/approval-group/approval-group'
import ClientReducer from '../store-models/clients/clients'
import AuthReducer from '../store-models/auth/auth'
import ModalReducer from '../store-models/modal/modal'
import HeaderReducer from '../store-models/header/header'
import QuotasReducer from '../store-models/quotas/quotas'
import DashboardMetaReducer from '../store-models/dashboard/dashboard'
import GoalCalculationsReducer from '../store-models/goal-calculations/goal-calculations'
import QuotasBuReducer from '../store-models/quotas-bu/quotas-bu'
import QuotasManagersReducer from '../store-models/quotas-managers/quotas-managers'
import DivisionsReducer from '../store-models/divisions/divisions'
import CustomerGoalsReducer from '../store-models/customer-goals/customer-goals'
import CustomerGoalsDataReducer from '../store-models/customer-goals-data/customer-goals-data'
import DistributionsReducer from '../store-models/distributions/distributions'
import BonusProductsReducer from '../store-models/bonus-products/bonus-products'
import ProgramRulesReducer from '../store-models/program-rules/program-rules'
import DuplicateDiscountReducer from '../store-models/duplicate-discount/duplicate-discount'
import ClassificationReducer from '../store-models/classification/classification'
import AnnualRecoveryReducer from '../store-models/annual-recovery/annual-recovery'
import AddGoalReducer from '../store-models/add-goal/add-goal'
import TypesReducer from '../store-models/types/types'
import DashboardReportReducer from '../store-models/dashboard-report/dashboard-report'
import DashboardValidationReducer from '../store-models/dashboard-validation/dashboard-validation'
// cada entidade com seus reduces

export const store = configureStore({
  reducer: {
    companies: CompaniesReducer,
    users: UsersReducer,
    clients: ClientReducer,
    auth: AuthReducer,
    modal: ModalReducer,
    header: HeaderReducer,
    groups: ApprovalGroupReducer,
    quotas: QuotasReducer,
    dashboardMeta: DashboardMetaReducer,
    goalCalculations: GoalCalculationsReducer,
    quotasBu: QuotasBuReducer,
    quotasManagers: QuotasManagersReducer,
    divisions: DivisionsReducer,
    customerGoals: CustomerGoalsReducer,
    customerGoalsData: CustomerGoalsDataReducer,
    distributions: DistributionsReducer,
    bonusProducts: BonusProductsReducer,
    programRules: ProgramRulesReducer,
    duplicateDiscount: DuplicateDiscountReducer,
    classification: ClassificationReducer,
    annualRecovery: AnnualRecoveryReducer,
    addGoal: AddGoalReducer,
    types: TypesReducer,
    dashboardReport: DashboardReportReducer,
    dashboardValidation: DashboardValidationReducer,
  },
})

export type AppDispatch = typeof store.dispatch

export default store
