/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteCurrentUser, makeRemoteUsers } from 'src/main/usecases/users/users'

export interface UsersStateInterface {
  users: any,
  loading: boolean,
  loaded: boolean,
  size?: number,
  page?: number,
  totalPages?: number,
  totalElements?: number,
  filterString?: string,
  filterType?: string
  sort?: string,
  order?: string
}

// Define the initial state using that type
const initialState: UsersStateInterface = {
  users: [],
  loading: true,
  loaded: false,
}

export interface UserParamsInterface {
  page: number,
  size: number,
  sort?: string,
  filterString?: string,
  filterType?: string
}

export interface CurrentUser {
  email: string,
  login: string,
  nome: string,
  senha?: string,
}

export const fetchUsers = createAsyncThunk(
  'users/index',
  async (params: UserParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(usersLoading())

    const response = await makeRemoteUsers().index(params)

    thunkAPI.dispatch(usersReceived())
    thunkAPI.dispatch(usersLoaded())
    return response
  },
)

export const updateCurrentUser = createAsyncThunk(
  'users-current/update',
  async (params: CurrentUser) => {
    const response = await makeRemoteCurrentUser().create(params)
    return response
  },
)

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    usersLoaded(state) {
      state.loaded = true
    },
    usersLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    usersReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
    usersFilter(state, action) {
      state.filterType = action.payload.filterType
      state.filterString = action.payload.filterString
      state.size = action.payload.size
      state.sort = action.payload.sort
      state.page = action.payload.page
    },
    usersClearFilters(state) {
      state.filterType = undefined
      state.filterString = undefined
      state.size = undefined
      state.sort = undefined
      state.page = undefined
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.users = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
  },
})

export const {
  usersLoaded,
  usersLoading,
  usersReceived,
  usersFilter,
  usersClearFilters,
} = slice.actions

export default slice.reducer
