/* eslint-disable import/extensions, import/no-unresolved */

import { RemoteQuotasManagers } from '../../../data/usecases/quotas-managers/quotas-managers'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'
import { makeAxiosHttpClient as AxiosNoRedirect } from '../../factories/http/axios-http-client-factory-no-redirect'

export const makeRemoteQuotasManagers = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)

  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeRemoteQuotasManagersStatus = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)

  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(`${url}/status`, makeAxiosHttpClient())
}

export const makeRemoteQuotasManagersManager = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/{GERENTES}'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)
  url = url.replace('{GERENTES}', 'rateio')

  return new RemoteQuotasManagers(`${url}`, makeAxiosHttpClient())
}

export const makeRemoteQuotasManagersTotal = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(`${url}/total`, makeAxiosHttpClient())
}
export const makeRemoteQuotasManagersItemsPopUp = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/item/{ID}/detalhe'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeRemoteParentLine = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/item/{ITEM}/rateio'
  let urlBu = '/cota/divisao/{DIVISAO}/item/{ITEM}/rateio'

  if (data.bu) {
    urlBu = urlBu.replace('{DIVISAO}', data.divisao)
    urlBu = urlBu.replace('{ITEM}', data.id)
    return new RemoteQuotasManagers(urlBu, AxiosNoRedirect())
  }

  url = url.replace('{GERENTE}', data.gerente)
  url = url.replace('{DIVISAO}', data.divisao)
  url = url.replace('{ITEM}', data.id)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeRemoteUpdateLine = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/item/{ITEM}'
  let urlBu = '/cota/divisao/{DIVISAO}/item/{ITEM}'

  if (data.bu) {
    urlBu = urlBu.replace('{DIVISAO}', data.divisao)
    urlBu = urlBu.replace('{ITEM}', data.id)
    return new RemoteQuotasManagers(urlBu, AxiosNoRedirect())
  }

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  url = url.replace('{ITEM}', data.id)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeUpdateGrowth = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/item/{ITEM}/percentualcrescimento'

  url = url.replace('{GERENTE}', data.gerente)
  url = url.replace('{DIVISAO}', data.divisao)
  url = url.replace('{ITEM}', data.numeroItem)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeUpdateQtdQuota = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/item/{ITEM}/quantidade'

  url = url.replace('{GERENTE}', data.gerente)
  url = url.replace('{DIVISAO}', data.divisao)
  url = url.replace('{ITEM}', data.numeroItem)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeUpdateDivisionQuota = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/item/{ITEM}/divisao'

  url = url.replace('{GERENTE}', data.gerente)
  url = url.replace('{DIVISAO}', data.divisao)
  url = url.replace('{ITEM}', data.numeroItem)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeUpdateActualQuota = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/item/{ITEM}/valor'

  url = url.replace('{GERENTE}', data.gerente)
  url = url.replace('{DIVISAO}', data.divisao)
  url = url.replace('{ITEM}', data.numeroItem)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeRemoteManagersForManagers = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/rateio/preview'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeRemoteAllManagersForManagers = ():any => (new RemoteQuotasManagers('/pessoa/all/paged', AxiosNoRedirect()))

export const makeUpdateManagersShare = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/item/{ITEM}/rateio'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  url = url.replace('{ITEM}', data.id)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeUpdateManagersPeople = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/pessoa'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeUpdateManagersForManagers = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/rateio'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeRemoteApprove = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/aprovacao'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeRemoteDisapprove = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/reprovacao'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeRemoteReview = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/revisar'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeRemoteSendToApprove = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/enviaraprovacao'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(url, AxiosNoRedirect())
}

export const makeRemoteInitQuota = (data: any):any => {
  let url = '/cota/{GERENTE}/{DIVISAO}/{CODIGO}/init'

  url = url.replace('{GERENTE}', data.gerente.key)
  url = url.replace('{DIVISAO}', data.divisao)
  const codigos = data.codigos.reduce((prev: string, current: string) => {
    if (prev) {
      return `${prev}/${current}`
    }
    return current
  }, '')

  url = url.replace('{CODIGO}', codigos)

  return new RemoteQuotasManagers(`${url}`, AxiosNoRedirect())
}
