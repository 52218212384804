import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '../box'
import { propTypesStyledHTML } from '../../../../utils/styled_helpers'

interface SpaceProps extends propTypesStyledHTML {
  name: string
}

const Space = ({ name, ...el }: SpaceProps): JSX.Element => (
  <Box {...el} className={name} />
)

Space.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Space
