import React, { useState } from 'react'
import { Field } from 'formik'
import { FormInput, IcoView } from '../form'
import TplGroup from './tpl_group'
import { Icons } from '../icons/icons'

interface InputPasswordProps {
  name: string,
  disabled: boolean,
  autoComplete?: boolean,
  title: string,
  placeholder: string,
  isRequired: boolean,
  tabIndex: number,
  maxLength?: number,
  values: any,
  setFieldValue:(name: string, value: string) => void,
  errors: {
    [key: string]: string
  }
}

const InputPassword = ({
  name, title, placeholder, isRequired, tabIndex, disabled, ...el
}: InputPasswordProps): JSX.Element => {
  const [viewPass, setViewPass] = useState(false)

  const handleChange = (event: any): void => {
    if (!!el.maxLength && event.target.value.length > el.maxLength) {
      return
    }

    if (el.setFieldValue) {
      el.setFieldValue(name, event.target.value.replace(/\s/g, ''))
    }
  }

  return (
    <TplGroup name={name} title={title} isRequired={isRequired} {...el}>
      <>
        <IcoView
          role="button"
          aria-label="Exibir conteúdo"
          onClick={() => setViewPass((prev) => !prev)}
        >
          <Icons
            color="#231F20"
            name={viewPass ? 'RiEyeFill' : 'RiEyeOffFill'}
          />
        </IcoView>
        <Field
          type={viewPass ? 'text' : 'password'}
          name={name}
          placeholder={placeholder || ''}
          tabIndex={tabIndex}
          required={isRequired}
          disabled={!!disabled}
          onChange={handleChange}
          as={FormInput}
          autoComplete={el.autoComplete ? 'on' : 'new-password'}
          value={el.values[name]}
        />
      </>
    </TplGroup>
  )
}
export default InputPassword
