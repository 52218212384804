/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import { Field, FieldProps } from 'formik'
import styled from 'styled-components'
import { FormControl, FormLabel } from '../form'
import { theme } from '../../../../styles/styles'

interface Option {
  value: string,
  text: string,
}
interface CheckboxOptionButtonProps {
  name: string,
  title: string,
  options: Option[] | [],
  tabIndex: number,
  maxChecked: number | undefined,
  checked: boolean,
  value: Option[] | [],
  setFieldValue: (name: string, value: any[]) => void,
  onChange: (item: any) => void,
  border: number,
}

const CheckboxOptionButton = ({
  name, title, tabIndex, maxChecked, options = [], ...el
}: CheckboxOptionButtonProps): JSX.Element => {
  const [check, setCheck] = useState<Option[] | []>(el.value || [])

  const isSelected = (option: Option): boolean => {
    if (check.filter((item) => item.text === option.text).length > 0) return true
    return false
  }

  const handleChange = (value: Option): void => {
    if (isSelected(value)) {
      const newValues = check.filter((item) => item.text !== value.text)

      if (el.onChange) {
        const all = options.map((item) => {
          if (newValues.find((ck) => ck.text === item.text)) {
            return ({
              ...item,
              value: true,
            })
          }

          return ({
            ...item,
            value: false,
          })
        })
        el.onChange(all)
      }

      setCheck(newValues)
      if (el.setFieldValue) {
        el.setFieldValue(name, newValues)
      }
      return
    }

    if (maxChecked && check.length >= maxChecked) {
      return
    }

    const temp = [...check, value]

    if (el.onChange) {
      const all = options.map((item) => {
        if (temp.find((ck) => ck.text === item.text)) {
          return ({
            ...item,
            value: true,
          })
        }

        return ({
          ...item,
          value: false,
        })
      })
      el.onChange(all)
    }
    setCheck(temp)
    if (el.setFieldValue) {
      el.setFieldValue(name, temp)
    }
  }

  return (
    <FormControl style={{ marginBottom: 15 }}>
      {
        title && (
          <Label htmlFor={name}>
            {title}
          </Label>
        )
      }

      <FormLabel htmlFor={name}>
        <Field name={name}>
          {({ field }: FieldProps) => (
            <Container>
              {
                options.map((option, index) => (
                  <Item className="checkbox" key={`${name}-${index}`}>
                    <input
                      type="checkbox"
                      {...field}
                      tabIndex={tabIndex}
                      checked={isSelected(option)}
                      id={index.toString()}
                      onChange={() => handleChange(option)}
                    />
                    <span>{option.text}</span>
                    <div className="checkmark" />
                  </Item>
                ))
              }
            </Container>
          )}
        </Field>
      </FormLabel>
    </FormControl>
  )
}

export default CheckboxOptionButton

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Label = styled(FormLabel)`
  display: block;
  margin-bottom: 20px;
`

const Item = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin: 0 20px 20px 0;
  padding-left: 25px;
  box-sizing: border-box;

  @media (max-width: ${theme.breakpoints[2]}){
    min-width: calc(50% - 20px);
  }

  &.disabled {
    opacity: .2;
    cursor: progress;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      &:after {
        display: block;
      }
    }
  }

  span {
    line-height: 1;
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 8px;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 18px;
    width: 18px;
    border: solid #828282 2px;
    transform: translateY(-50%);
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      display: none;

      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid ${theme.colors.black};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      @media (max-width: ${theme.breakpoints[2]}){
        width: 10px;
        height: 15px;
      }
    }

    @media (max-width: ${theme.breakpoints[2]}){
      height: 25px;
      width: 25px;
    }
  }
`
