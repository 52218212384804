/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteApprovalGroup, makeRemoteAllApprovalGroup } from 'src/main/usecases/approval-group/approval-group'

export interface ApprovalGroupStateInterface {
  groups: any[],
  allGroups: any[],
  loading: boolean,
  loaded: boolean,
  size?: number,
  page?: number,
  totalPages?: number,
  totalElements?: number,
  filterString?: string,
  filterType?: string
  sort?: string,
  order?: string
}

// Define the initial state using that type
const initialState: ApprovalGroupStateInterface = {
  groups: [],
  allGroups: [],
  loading: true,
  loaded: false,
}

export interface ApprovalGroupParamsInterface {
  page: number,
  size: number,
  sort?: string,
  filterString?: string,
  filterType?: string
}

export const fetchApprovalGroup = createAsyncThunk(
  'groups/index',
  async (params: ApprovalGroupParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(groupsLoading())

    const response = await makeRemoteApprovalGroup().index(params)

    thunkAPI.dispatch(groupsReceived())
    thunkAPI.dispatch(groupsLoaded())
    return response
  },
)

export const fetchAllApprovalGroup = createAsyncThunk(
  'groups-all/index',
  async (params: string, thunkAPI) => {
    thunkAPI.dispatch(groupsLoading())

    const response = await makeRemoteAllApprovalGroup().index({ query: params || '' })

    thunkAPI.dispatch(groupsReceived())
    thunkAPI.dispatch(groupsLoaded())
    return response
  },
)

const slice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    groupsLoaded(state) {
      state.loaded = true
    },
    groupsLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    groupsReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
    groupsFilter(state, action) {
      state.filterType = action.payload.filterType
      state.filterString = action.payload.filterString
    },
    groupsFilterSort(state, action) {
      state.sort = action.payload
    },
    groupsFilterOrder(state, action) {
      state.order = action.payload
    },
    groupsClearSort(state) {
      state.sort = undefined
      state.order = undefined
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchApprovalGroup.fulfilled, (state, action) => {
      state.groups = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
    builder.addCase(fetchAllApprovalGroup.fulfilled, (state, action) => {
      state.allGroups = action.payload
    })
  },
})

export const {
  groupsLoaded,
  groupsLoading,
  groupsReceived,
  groupsFilter,
  groupsFilterSort,
  groupsFilterOrder,
  groupsClearSort,
} = slice.actions

export default slice.reducer
