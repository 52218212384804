/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  makeRemoteCustomerGoalData,
  makeRemoteCustomerGoalDataItem,
  makeRemoteCustomerGoalDataTotal,
  makeRemoteCustomerGoalDataStatus,
} from '../../usecases/customer-goals/customer-goals'

export interface CustomerGoalsStatus {
  readOnly: boolean,
  status: string,
  showButtonAprovacao?: boolean,
  showButtonReprovacao?: boolean,
  showButtonRevisao?: boolean,
  showButtonEnviarAprovacao?: boolean,
  dataReferencia?: string,
  metaClienteId?: number,
  aprovacoes: {
    dataHoraConfirmacao: string,
    ordem: number,
    papel: string,
    statusAprovacao: string,
    usuario: string
  }[],
  comentarios: {
    dataHora: string,
    reprovacao: boolean,
    texto: string,
    usuario: string
  }[],
}

export interface CustomerGoalDataStateInterface {
  list: any,
  loading: boolean,
  loaded: boolean,
  size?: number,
  page?: number,
  codigoGrupo ?: number,
  configuracaoPeriodoId?: number,
  divisao?: string,
  totalPages?: number,
  totalElements?: number,
  status: CustomerGoalsStatus
}

// Define the initial state using that type
const initialState: CustomerGoalDataStateInterface = {
  list: [],
  loading: true,
  loaded: false,
  status: {
    readOnly: true,
    status: 'NAO_REGISTRADA',
    aprovacoes: [],
    comentarios: [],
  },
}

export interface CustomerGoalDataParamsProps {
  numeroItem?: number,
  codigoGrupo?: number,
  configuracaoPeriodoId?: number,
  divisao?: string,
  ano?: number
  division?: string,
  filterString?: string,
  filterType?: string,
  status?: string,
  size?: number,
  page?: number,
  sort?: string
}

export const fetchCustomerGoalData = createAsyncThunk(
  'customer-goals-data/index',
  async (params: CustomerGoalDataParamsProps, thunkAPI) => {
    thunkAPI.dispatch(customerGoalsDataLoading())

    const response = await makeRemoteCustomerGoalData().index(params)

    thunkAPI.dispatch(customerGoalsDataReceived())
    thunkAPI.dispatch(customerGoalsDataLoaded())
    return response
  },
)

export const fetchCustomerGoalDataStatus = createAsyncThunk(
  'customer-goals-data/status',
  async (params?: CustomerGoalDataParamsProps) => {
    const response = await makeRemoteCustomerGoalDataStatus().index(params)

    return response
  },
)

export const fetchCustomerGoalDataTotal = createAsyncThunk(
  'customer-goals/total',
  async (params: CustomerGoalDataParamsProps) => {
    const response = await makeRemoteCustomerGoalDataTotal().index(params)
    return response
  },
)

export const fetchCustomerGoalDataItem = createAsyncThunk(
  'customer-goals/item',
  async (params: CustomerGoalDataParamsProps) => {
    if (params?.numeroItem) {
      const response = await makeRemoteCustomerGoalDataItem().index(params)
      return response
    }
    return null
  },
)

const slice = createSlice({
  name: 'customer-goals-data',
  initialState,
  reducers: {
    customerGoalsDataLoaded(state) {
      state.loaded = true
    },
    customerGoalsDataLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    customerGoalsDataReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
    customerGoalsFilter(state, action) {
      if (action.payload.codigoGrupo) {
        state.codigoGrupo = action.payload.codigoGrupo
      }
      if (action.payload.configuracaoPeriodoId) {
        state.configuracaoPeriodoId = action.payload.configuracaoPeriodoId
      }
      if (action.payload.divisao) {
        state.divisao = action.payload.divisao
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCustomerGoalData.fulfilled, (state, action) => {
      state.list = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
    builder.addCase(fetchCustomerGoalDataStatus.fulfilled, (state, action) => {
      state.status = action.payload
    })
  },
})

export const {
  customerGoalsDataLoaded,
  customerGoalsDataLoading,
  customerGoalsDataReceived,
  customerGoalsFilter,
} = slice.actions

export default slice.reducer
