import styled, { css, CSSProp } from 'styled-components'
import { theme } from '../../../../styles/styles'

const renderHeading = (attr: string | undefined): CSSProp => {
  switch (attr) {
    case 'h1':
    case 'h2':
      return css`
      font-weight: 400;
      line-height: 2.55rem;
      text-align: center;
      color: ${theme.colors.secondary};
    `
    case 'h3': return css`
      font-size: ${theme.fontSizes.sm};
      text-align: center;
      font-family: ${theme.font.roboto};
      &:after {
        margin-top: 5px !important;
      }
      
      @media (min-width: ${theme.breakpoints[2]}) {
        text-align: left;
        &:after {
          margin-left: 0 !important;
        }
      }
    `
    default: return css``
  }
}

interface Heading {
  attr?: 'h1'| 'h2'| 'h3'
}

export const Heading = styled.h2.attrs(({ attr = 'h2' }: Heading) => ({
  as: attr,
}))`
  ${({ attr }: Heading) => renderHeading(attr)};
  position: relative;
  margin-bottom: 40px;
  color: ${theme.colors.backgroundColor};
  font-size: ${theme.fontSizes.xl};
  font-weight: bold;

  /* &:after {
    content: '';
    background: ${theme.colors.primary};
    height: 3px;
    width: 43px;
    display: flex;
    margin: 7px auto 0;
  } */
`
