import React, { useEffect, useRef, useState } from 'react'
import { showPopUp } from 'src/main/store-models/modal/modal'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { AppDispatch } from 'src/main/store/store'
import { Icons } from '../ui/components'
import { StoreStateInterface, openUserEdit } from '../../../main/interfaces/redux/redux'
import CurrentUserUpdate from '../current_user_registration'
import {
  UserWidgetStyled,
  Profile,
  ProfileHandle,
  ProfileIcon,
  Content,
  ProfileContent,
  MetaInfo,
  LogoutButton,
} from './styles'

const UserWidget = (): JSX.Element => {
  const [open, setOpen] = useState<boolean>()
  const [openEdit, setEditOpen] = useState<boolean>(false)
  const { user } = useSelector((state: StoreStateInterface) => state.auth)
  const dispatch = useDispatch<AppDispatch>()
  const history = useHistory()
  const userInfo = useRef<HTMLHeadingElement>(null)

  useEffect(() => {
    const handleOutsideClick: any = (event: any) => {
      if (userInfo.current && !userInfo.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('click', handleOutsideClick)
  }, [userInfo])

  const logOut = (): void => {
    setOpen(false)

    dispatch(showPopUp({
      title: 'Sair',
      subTitle: 'Deseja realmente sair?',
      buttons: [
        {
          title: 'Cancelar',
          outline: true,
        },
        {
          title: 'Confirmar',
          action: () => history.push('/sair'),
        },
      ],
    }))
  }

  return (
    <UserWidgetStyled ref={userInfo}>
      {user && (
        <>
          <Profile onClick={() => setOpen((prev) => !prev)}>
            <ProfileIcon>
              <Icons name="RiUserLine" />
            </ProfileIcon>
            <h2>{user.nome}</h2>
            <ProfileHandle />
          </Profile>

          {open && (
            <ProfileContent>
              <Content
                onClick={() => {
                  setOpen(false)
                  dispatch(openUserEdit({ editModel: false, onlyAuth: true }))
                  setEditOpen(true)
                }}
              >
                <Icons name="RiUserLine" />

                <MetaInfo>
                  <h3>Meus dados:</h3>
                  <div>
                    <p>{user.email}</p>
                  </div>
                  <div>
                    <span>Login:</span>
                    <p>{user.login}</p>
                  </div>
                </MetaInfo>
              </Content>
              <LogoutButton onClick={logOut}>
                <Icons name="RiLogoutBoxLine" />
                <span>Sair</span>
              </LogoutButton>
            </ProfileContent>
          )}
        </>
      )}
    </UserWidgetStyled>
  )
}

export default UserWidget
