/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions, import/no-unresolved */

import { UserDataProps } from '@interfaces/models/users'
import { HttpStatusCode } from '../../protocols/http/http-client'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'

export class RemoteApprovalGroup {
  url: string

  httpClient: any

  constructor(url: string, httpClient: any) {
    this.url = url
    this.httpClient = httpClient
  }

  async index(params?: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async create(body: UserDataProps) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      data: body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new Error('Login e/ou senha incorretos.')
      case HttpStatusCode.notFound: throw new Error('Login e/ou senha incorretos.')
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.created: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async update(body: UserDataProps) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'put',
      data: body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new Error('Login e/ou senha incorretos.')
      case HttpStatusCode.notFound: throw new Error('Login e/ou senha incorretos.')
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }
}
