/* eslint-disable no-shadow */
interface TypesInterface {
  ADMINISTRADOR: 'ADMINISTRADOR',
  AUDITORIA: 'AUDITORIA',
  NACIONAL: 'NACIONAL',
  REGIONAL: 'REGIONAL',
  VENDEDOR: 'VENDEDOR',
  DIRETORIA: 'DIRETORIA',
  MARKETING: 'MARKETING',
  VTC: 'VTC',
  CLIENTE: 'CLIENTE',
  'KEY ACCOUNT': 'KEY_ACCOUNT',
}

export const USERS_TYPES: TypesInterface = {
  ADMINISTRADOR: 'ADMINISTRADOR',
  AUDITORIA: 'AUDITORIA',
  DIRETORIA: 'DIRETORIA',
  MARKETING: 'MARKETING',
  NACIONAL: 'NACIONAL',
  REGIONAL: 'REGIONAL',
  VENDEDOR: 'VENDEDOR',
  VTC: 'VTC',
  CLIENTE: 'CLIENTE',
  'KEY ACCOUNT': 'KEY_ACCOUNT',
}
