import React, { useState } from 'react'
import { Field } from 'formik'
import moment from 'moment'
import { FormInput } from '../form'
import TplGroup from './tpl_group'

interface DatePickerProps {
  name: string,
  title: string,
  placeholder: string,
  isRequired: boolean,
  tabIndex: number,
  disabled: boolean,
  setFieldValue: (name: string, value: string) => void,
  value: string,
  errors: {
    [key: string]: string
  }
}

const DatePiker = ({
  name, title, placeholder, isRequired, tabIndex, disabled, ...el
}: DatePickerProps): JSX.Element => {
  const [inputValue, setInputValue] = useState(moment(el.value || '').format('yyyy-MM-DD'))

  const handleChange = (event: any): void => {
    setInputValue(event.target.value)
    if (el.setFieldValue) {
      el.setFieldValue(name, event.target.value)
    }
  }

  return (
    <TplGroup name={name} title={title} isRequired={isRequired} {...el}>
      <Field
        type="date"
        value={inputValue}
        name={name}
        placeholder={placeholder || ''}
        tabIndex={tabIndex}
        disabled={!!disabled}
        onChange={(event: any) => handleChange(event)}
        as={FormInput}
      />
    </TplGroup>
  )
}
export default DatePiker
