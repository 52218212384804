import styled from 'styled-components'

export const Orderer = styled.button`
  border: none;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: relative;
  height: 40px;
  width: 25px;

  padding: 0;
  margin-left: 15%;

  cursor: pointer;

  .first {
    position: absolute;
    top: 0;
  }

  .last {
    position: absolute;
    bottom: 0;
  }
`
