import { theme } from 'src/presentation/styles/styles'
import styled from 'styled-components'

export const FooterStyled = styled.footer`
  margin-top: 10px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${theme.colors.white};
  z-index: 2;

  p {
    font-size: ${theme.fontSizes.uxs};
    color: ${theme.colors.black};
    margin: 0 25px;
    line-height: 18px;

    @media (max-width: ${theme.breakpoints[2]}){
      font-size: 10px;
    }
  }
  
  button {
    margin: 0 25px;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;

    svg {
      fill: #EB5757;
      font-size: 18px;
      margin-right: 10px;
    }

    span {
      font-size: 14px;
      color: #090909;
      font-weight: 400;
    }
  }
`
