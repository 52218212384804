import styled, { css, CSSProp } from 'styled-components'
import { theme } from '../../../../styles/styles'

const newSize = (source: string, number: number): string => {
  const num = Number(source?.replace(/[A-z]/g, ''))
  return `${num - number}rem`
}

const renderTypography = (attr: string): CSSProp => {
  switch (attr) {
    case 'h1':
    case 'h2':
      return css`
        position: relative;
        margin-bottom: 30px;
        font-size: ${newSize(theme.fontSizes.lg, 0.350)};
        
        @media (min-width: ${theme.breakpoints[0]}) {
          font-size: ${theme.fontSizes.lg};
        }
        color: ${theme.colors.backgroundColor};
        line-height: 2.4rem;
        font-weight: 400;
        text-align: center;
      `
    case 'h3': return css`
      font-family: ${theme.font.roboto};
      font-weight: bold;
      font-size: ${theme.fontSizes.md};
      line-height: 1.375rem;
      color: ${theme.colors.secondary};
    `
    default: return css`
      font-size: ${theme.fontSizes.md};
      color: ${theme.colors.secondary};
    `
  }
}

interface TypographyProps {
  attr: 'div'| 'p'| 'h1'| 'h2'| 'h3'| 'h4'| 'h5',
  bar?: string,
}

export const Typography = styled.div.attrs(({ attr = 'div' }: TypographyProps) => ({
  as: attr,
}))`
  ${({ attr = 'div' }: TypographyProps) => renderTypography(attr)}
  ${({ bar }) => bar && css`
    &:after {
      content: '';
      background: ${theme.colors.primary};
      height: 3px;
      width: 43px;
      display: flex;
      margin: 7px auto 0;
    }
  `}
`
