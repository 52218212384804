/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteBonusProducts } from 'src/main/usecases/bonus-products/bonus-products'

export interface BonusProductsStateInterface {
  products: any,
  loading: boolean,
  loaded: boolean,
  size?: number,
  page?: number,
  totalPages?: number,
  totalElements?: number,
  query?: string,
  sort?: string,
  order?: string
}

// Define the initial state using that type
const initialState: BonusProductsStateInterface = {
  products: [],
  loading: true,
  loaded: false,
}

export interface BonusProductsParamsInterface {
  page: number,
  size: number,
  query?: string,
  sort?: string,
}

export const fetchBonusProducts = createAsyncThunk(
  'bonus-products/index',
  async (params: BonusProductsParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(bonusProductsLoading())

    const response = await makeRemoteBonusProducts().index(params)

    thunkAPI.dispatch(bonusProductsReceived())
    thunkAPI.dispatch(bonusProductsLoaded())

    return response
  },
)

const slice = createSlice({
  name: 'bonus-products',
  initialState,
  reducers: {
    bonusProductsLoaded(state) {
      state.loaded = true
    },
    bonusProductsLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    bonusProductsReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
    bonusProductsFilter(state, action) {
      state.query = action.payload.query
    },
    bonusProductsFilterSort(state, action) {
      state.sort = action.payload
    },
    bonusProductsFilterOrder(state, action) {
      state.order = action.payload
    },
    bonusProductsOrderAndSort(state, action) {
      state.order = action.payload.order
      state.sort = action.payload.sort
    },
    bonusProductsClearSort(state) {
      state.sort = undefined
      state.order = undefined
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchBonusProducts.fulfilled, (state, action) => {
      state.products = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
  },
})

export const {
  bonusProductsLoaded,
  bonusProductsLoading,
  bonusProductsReceived,
  bonusProductsFilter,
  bonusProductsFilterSort,
  bonusProductsFilterOrder,
  bonusProductsClearSort,
  bonusProductsOrderAndSort,
} = slice.actions

export default slice.reducer
