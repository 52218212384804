import React from 'react'
import { showPopUp } from 'src/main/store-models/modal/modal'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { RiLogoutBoxLine } from 'react-icons/ri'
import { FooterStyled } from './styles'

const EXCLUDE_LOGOUT = ['/login', '/recuperar-senha', '/404', '/403', '/500']

const Footer = (): JSX.Element => {
  const dispatch = useDispatch()
  const history = useHistory()

  const logOut = (): void => {
    dispatch(showPopUp({
      title: 'Sair',
      subTitle: 'Deseja realmente sair?',
      buttons: [
        {
          title: 'Cancelar',
          outline: true,
        },
        {
          title: 'Confirmar',
          action: () => history.replace('/sair'),
        },
      ],
    }))
  }

  return (
    <FooterStyled>
      <p>Copyright © 2023 Todos os direitos reservados.</p>
      {!EXCLUDE_LOGOUT.includes(window.location.pathname) && (

        <button
          type="button"
          onClick={logOut}
        >
          <RiLogoutBoxLine />
          <span>Sair</span>
        </button>
      )}
    </FooterStyled>
  )
}

export default Footer
