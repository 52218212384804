/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteAnnualRecovery } from 'src/main/usecases/annual-recovery/annual-recovery'

export interface AnnualRecoveryStateInterface {
  list: any,
  loading: boolean,
  loaded: boolean,
  size?: number,
  page?: number,
  totalPages?: number,
  totalElements?: number,
  filterString?: string,
}

// Define the initial state using that type
const initialState: AnnualRecoveryStateInterface = {
  list: [],
  loading: true,
  loaded: false,
}

export interface AnnualRecoveryParamsProps {
  filterString?: string,
  filterType?: string,
  size?: number,
  page?: number,
}

export interface AnnualRecoveryParamsInterface {
  id?: string,
  divisao?: string|null,
  size?: number,
  page?: number
}

export const fetchAnnualRecovery = createAsyncThunk(
  'annual-recovery/index',
  async (params: AnnualRecoveryParamsProps, thunkAPI) => {
    thunkAPI.dispatch(annualRecoveryLoading())

    const response = makeRemoteAnnualRecovery().index(params)

    thunkAPI.dispatch(annualRecoveryReceived())
    thunkAPI.dispatch(annualRecoveryLoaded())
    return response
  },
)

const slice = createSlice({
  name: 'annual-recovery',
  initialState,
  reducers: {
    annualRecoveryLoaded(state) {
      state.loaded = true
    },
    annualRecoveryLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    annualRecoveryReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },

  },

  extraReducers: (builder) => {
    builder.addCase(fetchAnnualRecovery.fulfilled, (state, action) => {
      state.list = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
  },
})

export const {
  annualRecoveryLoaded,
  annualRecoveryLoading,
  annualRecoveryReceived,
} = slice.actions

export default slice.reducer
