/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react'
import { Icons } from '../icons/icons'
import { Orderer } from './styles'

interface OrderList {
  title: string,
  onClick: (string: string) => void
}

export const OrderList = ({ title, onClick }: OrderList): JSX.Element => (
  <Orderer onClick={() => onClick(title)}>
    <Icons className="first" name="RiArrowDropUpFill" size={25} />
    <Icons className="last" name="RiArrowDropDownFill" size={25} />
  </Orderer>
)
