import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { StoreStateInterface } from 'src/main/interfaces/redux/redux'

import { closeHeader } from 'src/main/store-models/header/header'
import { MobileHelper } from 'src/presentation/helpers/mobile-helper/mobile-helper'
import { theme } from 'src/presentation/styles/styles'
import { Icons } from '../../ui/components'

import * as S from './styles'

type ItemProps = {
  hasDropdown?: {
    icon: string;
    path: string;
    title: string;
  }[];
  icon: string;
  path?: string;
  title: string;
};

type SidebarItemProps = {
  item: ItemProps;
};

const SidebarItem = ({ item }: SidebarItemProps): JSX.Element => {
  const dispatch = useDispatch()
  const header = useSelector((state: StoreStateInterface) => state.header)
  const location = useLocation()
  const [dropdown, setDropdown] = useState(false)
  const lowResolution = MobileHelper.lowResolution()

  const hideLeftBar = (): void => {
    if (lowResolution) dispatch(closeHeader())
  }

  useEffect(() => {
    setDropdown(false)
  }, [header.isOpen])

  if (!item.path) {
    return (
      <>
        <S.Dropdown
          key={item.title}
          onClick={() => setDropdown(!dropdown)}
          isActive={
            (item.hasDropdown
              && item.hasDropdown[0]?.path === location.pathname)
            || (item.hasDropdown
              && item.hasDropdown[1]?.path === location.pathname)
            || (item.hasDropdown
              && item.hasDropdown[2]?.path === location.pathname)
            || (item.hasDropdown
              && item.hasDropdown[3]?.path === location.pathname)
            || (item.hasDropdown
              && item.hasDropdown[4]?.path === location.pathname)
            || (item.hasDropdown
              && item.hasDropdown[5]?.path === location.pathname)
            || (item.hasDropdown
              && item.hasDropdown[6]?.path === location.pathname)
            || (item.hasDropdown
              && item.hasDropdown[7]?.path === location.pathname)
            || dropdown
          }
          dropdown={dropdown}
          title={item.title}
        >
          <Icons
            name={item.icon}
            color={dropdown ? theme.colors.black : theme.colors.lightGrayBlue}
            size={20}
          />
          {header?.isOpen && (
            <S.TitleContainer>
              <h6>{item.title}</h6>
              {dropdown ? (
                <Icons name="RiArrowDownSLine" size={22} color="#343C44" />
              ) : (
                <Icons name="RiArrowRightSLine" size={22} color="#fff" />
              )}
            </S.TitleContainer>
          )}
        </S.Dropdown>
        <S.DropdownItems>
          {dropdown
            && item.hasDropdown
            && item.hasDropdown.length
            && item.hasDropdown.map((itemDrop) => (
              <S.DropdownItem
                key={itemDrop.title}
                className="item"
                onClick={hideLeftBar}
                to={itemDrop.path}
                isActive={dropdown}
                title={itemDrop.title}
              >
                <Icons
                  name={itemDrop.icon}
                  color={
                    dropdown ? theme.colors.black : theme.colors.lightGrayBlue
                  }
                  size={20}
                />
                {header?.isOpen && <h6>{itemDrop.title}</h6>}
              </S.DropdownItem>
            ))}
        </S.DropdownItems>
      </>
    )
  }

  return (
    <S.Item
      key={item.title}
      className="item"
      onClick={hideLeftBar}
      to={item.path}
      isActive={item.path === location.pathname}
      title={item.title}
    >
      <Icons
        name={item.icon}
        color={
          item.path === location.pathname
            ? theme.colors.black
            : theme.colors.lightGrayBlue
        }
        size={20}
      />
      {header?.isOpen && <h6>{item.title}</h6>}
    </S.Item>
  )
}

export default SidebarItem
