import { theme } from 'src/presentation/styles/styles'
import styled from 'styled-components'

export const HeaderStyled = styled.header`
  width: calc(100% - 20px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px auto 0;
  padding: 0 20px;
  height: 70px;
  border-radius: 16px;
  background: ${theme.colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
`

export const Logo = styled.img`
  height: 30px;
`

export const MenuHandle = styled.button`
  border: none;
  background: none;
  padding: 0;

  @media (min-width: 992px) {
    display: none;
  }

  svg {
    fill: ${theme.colors.black};
    font-size: 30px;
  }
`
