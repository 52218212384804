import styled from 'styled-components'
import { applyProps, propTypesStyledHTML } from '../../../../utils/styled_helpers'

interface Flex extends propTypesStyledHTML {
  flexWrap?: 'wrap' | 'wrap-reverse' | 'nowrap',
  flexDir?: 'column'| 'column-reverse' | 'row' | 'row-reverse',
  alignItems?: 'baseline'| 'center'| 'flex-end'| 'flex-start'| 'normal'| 'stretch',
  alignContent?: 'stretch'| 'center'| 'flex-end'| 'flex-start'| 'space-between'| 'space-around',
  justifyItems?: 'baseline' | 'center' | 'end' | 'flex-end' | 'flex-start' | 'left' | 'legacy' | 'normal' | 'right' | 'revert' | 'self-end' | 'self-start' | 'start' | 'stretch',
  justifyContent?: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly',
}

export const Flex = styled.div`
  display: flex;
  ${(props: Flex) => applyProps(props)}
`
