import styled from 'styled-components'
import { theme } from '../../../../styles/styles'

export const FormControl = styled.div`
  position: relative;
  margin-bottom: 7px;
  width: 100%;

  .input, .input_money {
    width: 100%;
    height: 45px;
    border: none;
    border-radius: 10px;
    padding: 0 12px!important;
    border: 1px solid #868686;
    background: ${theme.colors.white};
  }
  .input_money {
    padding: 0;
    color: #585858;
  }

  select,
  input {
    padding: 0;
    border: 1px solid #868686;
    color: #585858;

    &::placeholder {
      color: #b1b1b1!important;
    }
  }
  
  label {
    a {
      color: ${theme.colors.primary};
      text-decoration: none;
    }
  }
  
  > div {
    position: relative;
    padding-top: 15px;

    label {
      position: absolute;
      pointer-events: none;
      transition: all .1s linear;
      top: -5px;
      left: 0;
      font-size: 14px;
      font-weight: 300;
      margin-top: -3px;
    }
   
    select[disabled],
    input[disabled] {
      background: rgba(196, 196, 196, .2);
      + label {
        content: '';
        width: 100%;
        top: -10px !important;
        padding: 4px 3px 0;
      }
    }

    &:not([data-name="phone"]) {
      textarea {
        + label {
          top: -5px;
          left: 0;
        }
      }

      input {
        transition: al .1s linear;
        -webkit-appearance: none;
        &:focus + label,
        &:not(:placeholder-shown) + label {
          top: -5px;
          left: 0;
        }
        &::placeholder {
          color: transparent;
        }
      }
      
      select + label {
        top: -5px;
        left: 0;
      }
    }
  }
`
