import React from 'react'
import { FormLabel, FormControl, FormInput } from '../form'

export interface InputStyledProps {
  disabled?: boolean,
  autofocus?: boolean,
  autoComplete?: boolean,
  title?: string,
  name?: string,
  type?: string,
  placeholder?: string,
  max?: number | undefined,
  min?: number | undefined,
  maxLength?: number | undefined,
  readonly?: number | undefined,
  value?: string,
  onChange?: (value: any) => void,
  onBlur?: (value: any) => void,
}

export const InputStyled = ({
  name, title, ...el
}:InputStyledProps): JSX.Element => (

  <FormControl>
    {title && (
    <FormLabel htmlFor={name}>
      {title}
    </FormLabel>
    )}
    <FormInput
      {...el}
      autoComplete="on"
    />
  </FormControl>
)
