import { theme } from '../styles/styles'
import { spinalCase } from './case_styles'

const allowNumber = ['z-index', 'opacity']

const propsAllowed = [
  'd',
  'w', 'h', 'maxW', 'minW', 'maxH', 'minH',
  'px', 'py', 'pl', 'pr', 'pt', 'pb', 'p',
  'mx', 'my', 'ml', 'mr', 'mt', 'mb', 'm',
  'display', 'flexWrap', 'flexDir', 'flexBasics', 'flexGrow',
  'alignItems', 'alignContent',
  'justifyItems', 'justifyContent',
  'textAlign', 'pos',
  'bg', 'bgc',
  'opacity', 'zIndex',
  'fw', 'fs',
]

const renderValue = (value: string, prop: string): string => (typeof value === 'number' && !allowNumber.includes(prop) ? `${value}px` : value)

const renderResponsive = (index: number, prop: string, value: string): string => {
  if (index) {
    return `
      @media (min-width: ${theme.breakpoints[index + 1]}) {
        ${prop}: ${renderValue(value, prop)};
      }
    `
  }
  return `${prop}: ${renderValue(value, prop)};`
}

const removeAbbr = (prop: string): string | string[] => {
  switch (prop) {
    case 'd': return 'display'
    case 'w': return 'width'
    case 'h': return 'height'
    case 'px': return ['padding-left', 'padding-right']
    case 'py': return ['padding-top', 'padding-bottom']
    case 'pl': return 'padding-left'
    case 'pr': return 'padding-right'
    case 'pt': return 'padding-top'
    case 'pb': return 'padding-bottom'
    case 'p': return 'padding'
    case 'mx': return ['margin-left', 'margin-right']
    case 'my': return ['margin-top', 'margin-bottom']
    case 'ml': return 'margin-left'
    case 'mr': return 'margin-right'
    case 'mt': return 'margin-top'
    case 'mb': return 'margin-bottom'
    case 'm': return 'margin'
    case 'pos': return 'position'
    case 'bg': return 'background'
    case 'bgc': return 'background-color'
    case 'fw': return 'font-weight'
    case 'fs': return 'font-size'
    case 'flexDir': return 'flex-direction'
    case 'maxW': return 'max-width'
    case 'minW': return 'min-width'
    case 'maxH': return 'max-height'
    case 'minH': return 'min-height'
    default: return prop
  }
}

const getProps = (key: string, value: string[] | string): string | string[] => {
  if (Array.isArray(value)) {
    return value.map((v, i) => renderResponsive(i, key, v)).join('')
  }

  return renderResponsive(0, key, value)
}

export const applyProps = (props: any): any => (
  Object.keys(props).filter((key) => propsAllowed.includes(key)).map((key: string) => {
    const attr = removeAbbr(key)

    if (typeof attr === 'object') {
      const aux: string | string[] = props[key]
      return attr.map((at) => getProps(spinalCase(at), aux)).join('\n')
    }

    const prop = getProps(spinalCase(attr), props[key])
    return typeof prop === 'object' ? prop.join('\n') : prop
  })
)

export interface propTypesStyledHTML {
  w?: string | number | string[] | number[],
  maxW?: string | number | string[] | number[],
  minW?: string | number | string[] | number[],
  h?: string | number | string[] | number[],
  maxH?: string | number | string[] | number[],
  minH?: string | number | string[] | number[],
  px?: string | number | string[] | number[],
  py?: string | number | string[] | number[],
  pl?: string | number | string[] | number[],
  pr?: string | number | string[] | number[],
  pt?: string | number | string[] | number[],
  pb?: string | number | string[] | number[],
  p?: string | number | string[] | number[],
  mx?: string | number | string[] | number[],
  my?: string | number | string[] | number[],
  ml?: string | number | string[] | number[],
  mr?: string | number | string[] | number[],
  mt?: string | number | string[] | number[],
  mb?: string | number | string[] | number[],
  m?: string | number | string[] | number[],
  bg?: string | number | string[] | number[],
  bgc?: string | number | string[] | number[],
  d?: string | number | string[] | number[],
  fw?: string | number | string[] | number[],
  fs?: string | number | string[] | number[],
}
