/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState } from 'react'
import { Field, FieldProps } from 'formik'
import { FormControl, FormLabel } from '../form'
import { FormErrorMessage } from '../form/form_error_message'

interface CheckboxProps {
  name: string,
  title: string | object,
  tabIndex: number,
  isRequired: boolean,
  checked: boolean,
  setFieldValue: (name: string, value: string | boolean) => void,
  errors: {
    [key: string]: string
  }
}

const Checkbox = ({
  name, title, isRequired, checked = false, ...el
}: CheckboxProps): JSX.Element => {
  const [check, setCheck] = useState<boolean>(false)

  const handleChange = (): void => {
    setCheck((prev) => !prev)
    if (el.setFieldValue) {
      if (isRequired) el.setFieldValue(name, check ? '' : true)
      else el.setFieldValue(name, !check)
    }
  }

  useEffect(() => {
    if (checked) setCheck(checked)
  }, [])

  return (
    <FormControl>
      {title && (
        <FormLabel className="checkbox-input" htmlFor={name} onClick={handleChange}>
          <>
            <Field name={name}>
              {({ field }: FieldProps) => (
                <input
                  type="checkbox"
                  {...field}
                  tabIndex={el?.tabIndex}
                  checked={check}
                />
              )}
            </Field>
            {typeof title === 'object' ? title : <span dangerouslySetInnerHTML={{ __html: title }} />}
          </>
        </FormLabel>
      )}
      <FormErrorMessage>{el.errors[name]}</FormErrorMessage>
    </FormControl>
  )
}

export default Checkbox
