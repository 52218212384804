/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/require-default-props */
import React from 'react'
import { Field } from 'formik'
import { FormInput } from '../form'
import TplGroup from './tpl_group'

export interface InputProps {
  disabled: boolean,
  isRequired: boolean,
  children: JSX.Element,
  autoComplete?: boolean,
  title: string,
  name: string,
  type: string,
  placeholder: string,
  className?: string,
  tabIndex: number,
  max?: number | undefined,
  min?: number | undefined,
  maxLength?: number | undefined,
  readonly?: number | undefined,
  values: any,
  validations?: string[] | undefined,
  setFieldValue: (name: string, value: string) => void,
  onChange?: (value: string) => void,
  errors: {
    [key: string]: string
  }
  step: number | string,
}

const Input = ({
  name, title, type, placeholder, isRequired, tabIndex, disabled, className, ...el
}: InputProps): JSX.Element => {
  const options = {
    ...(el.max) && { max: el.max },
    ...(el.min) && { min: el.min },
    ...(el.maxLength) && { maxLength: el.maxLength },
    ...(el.readonly) && { readonly: el.readonly },
  }

  const handleChange = (event: any): void => {
    let { value } = event.target
    if (!!el.maxLength && value.length > el.maxLength) {
      return
    }

    if (el.onChange) {
      el.onChange(event.currentTarget.value)
    }
    if (el.validations?.includes('email')) value = event.target.value.replace(/\s/g, '')

    if (el.setFieldValue) {
      el.setFieldValue(name, value)
    }
  }

  return (
    <TplGroup name={name} title={title} isRequired={isRequired} {...el}>
      <Field
        type={type || 'text'}
        name={name}
        required={isRequired}
        placeholder={placeholder || ''}
        tabIndex={tabIndex}
        min={0}
        disabled={!!disabled}
        {...options}
        className={`teste ${className}`}
        onChange={(event: any) => handleChange(event)}
        autoComplete={el.autoComplete ? 'on' : 'new-password'}
        as={FormInput}
        value={el.values[name]}
        step={el.step}
      />
    </TplGroup>
  )
}

Input.defaultProps = {
  max: undefined,
  min: undefined,
  maxLength: undefined,
  readonly: undefined,
  validations: undefined,
}

export default Input
