import { theme } from 'src/presentation/styles/styles'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const BgModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,.5);
`

export const ModalContent = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 615px;
  padding: 30px 45px;
  border-radius: 16px;
  background: ${theme.colors.white};
  max-height: 95%;
  overflow-y: auto;
  z-index: 9999;
  
  &::-webkit-scrollbar {
    width: 15px;
    border-radius: 10px;

    &-track {
      box-shadow: inset 0 0 10px 10px ${theme.colors.lightGrayBlue};
      border-radius: 10px;
      border: solid 3px transparent;
      
    }

    &-thumb {
      box-shadow: inset 0 0 10px 10px ${theme.colors.primary};
      border: solid 5px transparent;
      border-radius: 10px;
    }
  }

  @media (max-width: ${theme.breakpoints[2]}){
    bottom: 0;
    height: fit-content;
    transform: translate(0);
    left: 0;
    width: 100%;
    top: unset;
    border-radius: 16px 16px 0 0;
    padding: 40px 20px 20px;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  label {
    font-size: 14px!important;
    color: ${theme.colors.black}!important;
    font-weight: 300!important;
  }
`

export const FormSubTitle = styled.div`
  display: flex;
  margin: 10px 0 20px;
  align-items: center;
  position: relative;

  @media (max-width: ${theme.breakpoints[2]}){
    margin: 50px 0 20px;
  }

  h3 {
    font-size: 14px;
    background: ${theme.colors.white};
    padding-right: 15px;
  }

  &::after {
    content: '';
    height: 1px;
    width: 100%;
    background: #D9D9D9;
    position: absolute;
    left: 0;
    z-index: -1;
  }
`

export const FormHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;

  @media (max-width: ${theme.breakpoints[2]}){
    margin-bottom: 25px;
  }

  h3 {
    font-size: 24px;
    
    @media (max-width: ${theme.breakpoints[2]}){
      font-size: 16px;
    }
  }
`

export const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary};
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin-right: 10px;

  @media (max-width: ${theme.breakpoints[2]}){
    height: 35px;
    width: 35px;
  }
  
  svg {
    fill: ${theme.colors.black};
    font-size: 20px;
  }
`

export const CloseButton = styled.button`
  position: absolute;
  right: 15px;
  top: 15px;
  height: 25px;
  width: 25px;
  border: none;
  transform: rotate(45deg);
  font-size: 36px;
  padding: 0;
  margin: 0;
  background: none;
  cursor: pointer;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const EditButton = styled.button`
  background: ${theme.colors.primary};
  color: ${theme.colors.black};
  display: flex;
  align-items: center;
  padding: 7px 10px 6px;
  border-radius: 4px;
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;

  svg {
    margin-right: 3px;
  }

  @media (max-width: ${theme.breakpoints[2]}){
    padding: 4px 10px 3px;
  }
`

export const Form = styled.div`
  margin-bottom: 23px;

  small {
    font-size: 10px;
    color: #fe6969;
    margin-top: 3px;
    padding-top: 0;
  }
`
export const Label = styled.label`
    display: block;
    margin-bottom: 5px;
    pointer-events: none;
    -webkit-transition: all .1s linear;
    transition: all .1s linear;
    font-size: 14px;
    font-weight: 300;
    font-size: 14px;
    color: #090909;
    font-weight: 300;
`
