import React from 'react'
import { FormControl, FormErrorMessage, FormLabel } from '../form'

interface Props {
  isRequired: boolean,
  children: JSX.Element,
  title: string,
  name: string,
  errors: {
    [key: string]: string
  }
}

const TplGroup = ({
  name, title, children, ...el
}: Props): JSX.Element => (
  (
    <FormControl>
      <>
        <div data-name={name}>
          {children}
          {title && (
            <FormLabel htmlFor={name}>
              {title}
            </FormLabel>
          )}
        </div>
        <FormErrorMessage>{el.errors[name]}</FormErrorMessage>
      </>
    </FormControl>
  )
)

export default TplGroup
