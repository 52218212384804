import { RemoteClassification } from '../../../data/usecases/classification/classification'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory-no-redirect'

export const makeRemoteClassification = ():any => new RemoteClassification('/configuracaoclassificacao', makeAxiosHttpClient())

export const makeRemoteAllClassification = ():any => new RemoteClassification('/configuracaoclassificacao/all', makeAxiosHttpClient())

export const makeRemoteUpdateClassification = (id:number):any => new RemoteClassification(`/configuracaoclassificacao/${id}`, makeAxiosHttpClient())

export const makeRemoteClientType = ():any => new RemoteClassification('/tipocliente/', makeAxiosHttpClient())
