import { RouteComponentProps } from 'react-router-dom'
import { store } from 'src/main/store/store'
import { userLogout } from 'src/main/store-models/auth/auth'
import { makeAuth } from 'src/presentation/factories/auth'
import { getRedirectPath } from 'src/presentation/utils/routes'
import { PermissionHelper } from 'src/presentation/helpers/permission-helper/permission-helper'
import { TokenHelper } from 'src/presentation/helpers/token-helper/token-helper'

interface ToRouteProps extends RouteComponentProps {
  meta: any;
}

interface Next {
  (): void;
  props(payload: object): void;
  redirect(payload: string | object): void;
}

export const guardsRoutes = async (
  to: ToRouteProps,
  from: RouteComponentProps | null,
  next: Next,
): Promise<void> => {
  if (to.location.pathname === '/sair') {
    TokenHelper.remove()
    store.dispatch(userLogout())
    next.redirect('/login')
    return
  }

  if (to.meta?.auth) {
    try {
      const user = await makeAuth()

      if (user && PermissionHelper.start().isClient()) {
        try {
          const { auth } = store.getState()
          if (!auth.accepted) {
            next.redirect('/regras-programa')
            return
          }
        } catch (error) {
          next.redirect('/regras-programa')
          return
        }
      }

      if (!PermissionHelper.start(to.match.path).canAccess()) {
        next.redirect('/403')
        return
      }

      next()
    } catch (error) {
      store.dispatch(userLogout())
      next.redirect(`/login?redirect=${btoa(getRedirectPath())}`)
    }
  } else {
    next()
  }
}
