/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */
import { currentYear } from 'src/presentation/utils/dates'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
  makeRemoteGoalxAccomplishedChart,
  makeRemotePerformanceAnalysisChart,
  makeRemoteCalculationQuery,
  makeRemoteQuarterlyQuotas,
  makeRemoteMonthQuotas,
  makeRemoteHistoricChart,
  makeDashboardFiltroGrupo,
  makeDashboardFiltroPeriodo,
  makeDashboardFiltroDivisao,
  makeDashboardNacional,
  makeDashboardRegional,
  makeDashboardVendedor,

  makeRemoteDashboardKpi,
  makeRemoteDashboardKpiHeader,
  makeDashboardKpiItems,
  makeDashboardTipo,
} from 'src/main/usecases/dashboard/dashboard'
import {
  CalculationQueryDataProps,
  GoalxAccomplishedChartDataProps,
  HistoricChartDataProps,
  MonthQuotasDataProps,
  PerformanceAnalysisChartDataProps,
  QuarterlyDataProps,
} from '../../interfaces/models/dashboard'

export interface DashboardMetaStateInterface {
  goalxAccomplishedChartData: GoalxAccomplishedChartDataProps[];
  performanceAnalysisChartData: PerformanceAnalysisChartDataProps;
  calculationQueryData: CalculationQueryDataProps;
  quarterlyQuotasData: QuarterlyDataProps;
  monthQuotasData: MonthQuotasDataProps[];
  historicChartData: HistoricChartDataProps;
  loading: boolean;
  loaded: boolean;
  totalElements?: number |null;
  groupsClients: any[],
  divisoes: any[],
  years: any[],
  historicChartLoading: boolean,
  monthQuotasLoading: boolean,
  quarterlyQuotasLoading: boolean,
  calculationQueryLoading: boolean,
  performanceAnalysisChartLoading: boolean,
  goalxAccomplishedChartLoading: boolean,
  nacional: any[],
  regional: any[],
  vendedor: any[],
  canais: any[],

  // DASHBOARD KPI
  kpiheader?: any,
  kpilist: any[],
  kpilistItems: any[],
  kpiloading: boolean,
}

// Define the initial state using that type
const initialState: DashboardMetaStateInterface = {
  goalxAccomplishedChartData: [],
  performanceAnalysisChartData: {} as PerformanceAnalysisChartDataProps,
  calculationQueryData: {} as CalculationQueryDataProps,
  quarterlyQuotasData: {} as QuarterlyDataProps,
  monthQuotasData: [],
  historicChartData: {} as HistoricChartDataProps,
  loading: true,
  groupsClients: [],
  divisoes: [],
  years: [{ ano: currentYear }],
  loaded: false,
  historicChartLoading: true,
  monthQuotasLoading: true,
  quarterlyQuotasLoading: true,
  calculationQueryLoading: true,
  performanceAnalysisChartLoading: true,
  goalxAccomplishedChartLoading: true,
  totalElements: null,
  nacional: [],
  regional: [],
  vendedor: [],
  kpilist: [],
  canais: [],
  kpilistItems: [],
  kpiloading: true,
}

export interface ParamsInterface {
  codigoGrupo?: number | null;
  ano?: number | null;
  divisao?: string | null;
  trimestre?: number | null;
  nacional?: string,
  regional?: string,
  vendedor?: string,
  numeroItem?: number | null;
  tipoId?: number
}

export const fetchGroupClients = createAsyncThunk(
  'dashboard/meta/grupo-clients',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(dashboardLoading())
    const response = await makeDashboardFiltroGrupo().load()

    thunkAPI.dispatch(dashboardReceived())
    return response
  },
)

export const fetchDivisionsDash = createAsyncThunk(
  'dashboard/meta/divisoes',
  async (params: ParamsInterface) => {
    const response = await makeDashboardFiltroDivisao().load({
      codigoGrupo: params.codigoGrupo,
      ano: params.ano,
    })

    return response
  },
)

export const fetchYears = createAsyncThunk(
  'dashboard/meta/years',
  async (params: ParamsInterface) => {
    const response = await makeDashboardFiltroPeriodo().load({
      codigoGrupo: params.codigoGrupo,
    })

    return response
  },
)

export const fetchManagerNacional = createAsyncThunk(
  'dashboard/meta/nacional',
  async (params: ParamsInterface) => {
    const response = await makeDashboardNacional().load({
      codigoGrupo: params.codigoGrupo,
      divisao: params.divisao,
    })

    return response
  },
)

export const fetchManagerRegional = createAsyncThunk(
  'dashboard/meta/regional',
  async (params: ParamsInterface) => {
    const response = await makeDashboardRegional().load({
      codigoGrupo: params.codigoGrupo,
      divisao: params.divisao,
    })

    return response
  },
)

export const fetchManagerVendedor = createAsyncThunk(
  'dashboard/meta/vendedor',
  async (params: ParamsInterface) => {
    const response = await makeDashboardVendedor().load({
      codigoGrupo: params.codigoGrupo,
      divisao: params.divisao,
    })

    return response
  },
)

export const fetchCanaisDash = createAsyncThunk(
  'dashboard/canais',
  async (params?: ParamsInterface) => {
    const response = await makeDashboardTipo().load(params)

    return response
  },
)

export const fetchGoalxAccomplishedChart = createAsyncThunk(
  'dashboard/meta/metaxrealizado',
  async (params: ParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(GoalxAccomplishedChartLoading(true))

    const response = await makeRemoteGoalxAccomplishedChart().load(params)
    return response
  },
)

export const fetchPerformanceAnalysisChart = createAsyncThunk(
  'dashboard/meta/analise-de-performance',
  async (params: ParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(PerformanceAnalysisChartLoading(true))

    const response = await makeRemotePerformanceAnalysisChart().load(params)
    return response
  },
)

export const fetchCalculationQuery = createAsyncThunk(
  'dashboard/meta/consulta-de-apuracoes',
  async (params: ParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(CalculationQueryLoading(true))

    const response = await makeRemoteCalculationQuery().load(params)
    return response
  },
)

export const fetchQuarterlyQuotas = createAsyncThunk(
  'dashboard/meta/cotas',
  async (params: ParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(QuarterlyQuotasLoading(true))

    const response = await makeRemoteQuarterlyQuotas().load(params)
    return response
  },
)

export const fetchMonthQuotas = createAsyncThunk(
  'dashboard/meta/cotas-mensal',
  async (params: ParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(MonthQuotasLoading(true))

    const response = await makeRemoteMonthQuotas().load(params)
    return response
  },
)

export const fetchHistoricChart = createAsyncThunk(
  'dashboard/meta/historico',
  async (params: ParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(HistoricChartLoading(true))

    const response = await makeRemoteHistoricChart().load(params)
    return response
  },
)

export const fetchDashboardKpiHeader = createAsyncThunk(
  'dashboard/kpi/header',
  async (params: ParamsInterface) => {
    const response = await makeRemoteDashboardKpiHeader().load(params)
    return response
  },
)

export const fetchDashboardKpi = createAsyncThunk(
  'dashboard/kpi/index',
  async (params: ParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(dashboardKpiLoading())

    const response = await makeRemoteDashboardKpi().load(params)
    return response
  },
)

export const fecthDashboardKpiItems = createAsyncThunk(
  'dashboard/kpi/items',
  async (params: ParamsInterface) => {
    const response = await makeDashboardKpiItems().load(params)
    return response
  },
)

const slice = createSlice({
  name: 'dashboard-meta',
  initialState,
  reducers: {
    dashboardLoaded(state: any) {
      state.loaded = true
    },
    dashboardLoading(state: any) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    dashboardKpiLoading(state: any) {
      if (!state?.kpiloading) {
        state.kpiloading = true
      }
    },
    GoalxAccomplishedChartLoading(state: any, action: any) {
      state.goalxAccomplishedChartLoading = action.payload
    },
    PerformanceAnalysisChartLoading(state: any, action: any) {
      state.performanceAnalysisChartLoading = action.payload
    },
    CalculationQueryLoading(state: any, action: any) {
      state.calculationQueryLoading = action.payload
    },
    QuarterlyQuotasLoading(state: any, action: any) {
      state.quarterlyQuotasLoading = action.payload
    },
    MonthQuotasLoading(state: any, action: any) {
      state.monthQuotasLoading = action.payload
    },
    HistoricChartLoading(state: any, action: any) {
      state.historicChartLoading = action.payload
    },
    dashboardReceived(state: any) {
      if (state.loading) {
        state.loading = false
      }
    },
    dashboardReset(state: any, action: any) {
      state.years = [{
        ano: currentYear,
      }]
      state.divisoes = []
      state.nacional = []
      state.regional = []
      state.vendedor = []
      state.kpilistItems = []
      if (action.payload) {
        state.loading = true
      }
      state.historicChartLoading = true
      state.monthQuotasLoading = true
      state.quarterlyQuotasLoading = true
      state.calculationQueryLoading = true
      state.performanceAnalysisChartLoading = true
      state.goalxAccomplishedChartLoading = true
      state.kpiheader = undefined
    },
    graphicsReset(state: any) {
      state.kpilistItems = []
      state.kpiloading = true
      state.historicChartLoading = true
      state.monthQuotasLoading = true
      state.quarterlyQuotasLoading = true
      state.calculationQueryLoading = true
      state.performanceAnalysisChartLoading = true
      state.goalxAccomplishedChartLoading = true
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroupClients.fulfilled, (state, action) => {
      state.groupsClients = action.payload
    })
    builder.addCase(fetchDivisionsDash.fulfilled, (state, action) => {
      state.divisoes = action.payload
    })
    builder.addCase(fetchYears.fulfilled, (state, action) => {
      if (action.payload.length === 0) {
        state.years = [{
          ano: currentYear,
        }]
      } else {
        state.years = action.payload
      }
    })
    builder.addCase(fetchGoalxAccomplishedChart.fulfilled, (state, action) => {
      state.goalxAccomplishedChartData = action.payload
      state.goalxAccomplishedChartLoading = false
    })
    builder.addCase(fetchPerformanceAnalysisChart.fulfilled, (state, action) => {
      state.performanceAnalysisChartData = action.payload
      state.performanceAnalysisChartLoading = false
    })
    builder.addCase(fetchCalculationQuery.fulfilled, (state, action) => {
      state.calculationQueryData = action.payload
      state.calculationQueryLoading = false
    })
    builder.addCase(fetchQuarterlyQuotas.fulfilled, (state, action) => {
      state.quarterlyQuotasData = action.payload
      state.quarterlyQuotasLoading = false
    })
    builder.addCase(fetchMonthQuotas.fulfilled, (state, action) => {
      state.monthQuotasData = action.payload
      state.monthQuotasLoading = false
    })
    builder.addCase(fetchHistoricChart.fulfilled, (state, action) => {
      state.historicChartData = action.payload
      state.historicChartLoading = false
    })
    builder.addCase(fetchManagerNacional.fulfilled, (state, action) => {
      state.nacional = action.payload
    })
    builder.addCase(fetchManagerRegional.fulfilled, (state, action) => {
      state.regional = action.payload
    })
    builder.addCase(fetchManagerVendedor.fulfilled, (state, action) => {
      state.vendedor = action.payload
    })
    builder.addCase(fetchDashboardKpiHeader.fulfilled, (state, action) => {
      state.kpiheader = action.payload
    })
    builder.addCase(fetchDashboardKpi.fulfilled, (state, action) => {
      state.kpilist = action.payload
      state.kpiloading = false
    })
    builder.addCase(fecthDashboardKpiItems.fulfilled, (state, action) => {
      state.kpilistItems = action.payload
    })
    builder.addCase(fetchCanaisDash.fulfilled, (state, action) => {
      state.canais = action.payload
    })
  },
})

export const {
  dashboardLoaded,
  dashboardLoading,
  dashboardReceived,
  CalculationQueryLoading,
  GoalxAccomplishedChartLoading,
  HistoricChartLoading,
  MonthQuotasLoading,
  PerformanceAnalysisChartLoading,
  QuarterlyQuotasLoading,
  dashboardReset,
  dashboardKpiLoading,
  graphicsReset,
} = slice.actions

export default slice.reducer
