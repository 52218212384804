import * as Yup from 'yup'
import { isCPF } from '../yup-methods-validations/identity'
import { isValidDateExp } from '../yup-methods-validations/is_valid_date_exp'

Yup.addMethod(Yup.string, 'cpf', (message) => Yup.mixed().test('cpf', message, (value) => isCPF(value)))

Yup.addMethod(Yup.string, 'isValidCardNumber', (message) => Yup.mixed().test('isValidCardNumber', message, (value) => isValidCardNumber(value)))

Yup.addMethod(Yup.string, 'inputDateExp', (message) => Yup.mixed().test('isValidaDateExp', message, (value) => isValidDateExp(value)))

export const getValidationSchemaByName = (name) => {
  if (!Array.isArray(name)) {
    switch (name) {
      case 'inputRequired':
        return Yup.string()
          .required('Campo obrigatório')
      case 'inputRequiredObject':
        return Yup.object()
          .required('Campo obrigatório')
      case 'emailRequired':
        return Yup.string()
          .email('E-mail inválido')
          .required('Campo obrigatório')
      case 'passwordMinRequired':
        return Yup.string()
          .min(4, 'Mínimo de 4 caracteres')
          .required('Campo obrigatório')
      case 'passwordWithMatches':
        return Yup.string()
          .required('Campo obrigatório')
          .min(4, 'Mínimo de 4 caracteres')
          .max(24, 'Máximo de 24 caracteres')
          // .matches(/[0-9]/, I18n.get('ValidateOneNumber'))
          // .matches(/[a-zA-z]+/, I18n.get('ValidateOneLetter'))
          // .matches(/[a-z]/, I18n.get('ValidateOneLowerChar'))
          // .matches(/[A-Z]/, I18n.get('ValidateOneUpperChar'))
          // .matches(/[!@#$%*()_/\\\-+^&{}:;?.]/, I18n.get('ValidateOneSpecialChar'))
      case 'confirmPassword':
        return Yup.string().when('password', {
          is: (val) => val && val.length >= 6,
          then: Yup.string()
            .oneOf([Yup.ref('password')], 'As senhas não são iguais')
            .required('Campo obrigatório'),
        })
      case 'inputIdentity':
        return Yup.string()
        .cpf('CPF inválido')
        .required('Campo obrigatório')
      case 'inputDateExp':
        return Yup.string()
          .required('Campo obrigatório')
          .inputDateExp('Data de expiração inválida')
      case 'inputRequiredCode':
        return Yup.string()
          .required('Campo obrigatório')
          .matches(/[0-9]/, 'Somente números')
      case 'isValidName':
        return Yup.string()
          .required('Campo obrigatório')
          .min(2, 'Mínimo de 2 caracteres')
      case 'inputRequiredCodeJDE':
        return Yup.string()
          .required('Campo obrigatório')
          .matches(/[0-9]/, 'Somente números,')
          .max(9, 'Máximo de 9 caracteres')

      default: throw new Error(`Validation ${name} don't found`)
    }
  }
}
