import { loadUser, verifyAccepted } from '../../main/store-models/auth/auth'
import { store } from '../../main/store/store'
import { PermissionHelper } from '../helpers/permission-helper/permission-helper'
import { TokenHelper } from '../helpers/token-helper/token-helper'
import { currentYear } from '../utils/dates'

export const makeAuth = (): Promise<any> => new Promise((resolve, reject) => {
  const state = store.getState()

  if (!state?.auth.loaded || !state.auth.user) {
    const token = TokenHelper.get()

    if (!token) {
      reject(new Error('Sem token'))
    }

    store.dispatch(loadUser())
      .unwrap()
      .then((response) => {
        if (response?.token) {
          const whereToken = TokenHelper.getCookiesToken()
          TokenHelper.set(response.token, !!whereToken)
        }
        return response
      })
      .then(async (response) => {
        if (PermissionHelper.start().isClient()) {
          await store.dispatch(verifyAccepted({ ano: currentYear })).unwrap()
        }
        resolve(response)
      })
      .catch(reject)
  } else {
    resolve(state?.auth?.user)
  }
})
