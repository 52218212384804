/* eslint-disable  class-methods-use-this */

import axios from 'axios'
import { TokenHelper } from 'src/presentation/helpers/token-helper/token-helper'
import { HttpRequest, HttpResponse } from '../../../data/protocols/http/http-client'

export class AxiosHttpClient {
  api: any

  constructor(private readonly baseUrl: string | undefined) {
    const api = axios.create()
    api.interceptors.response.use(this.handleSuccess, this.handleError)
    this.api = api
  }

  async request({
    headers, method, url, data, params, ...options
  }: HttpRequest): Promise<HttpResponse> {
    let axiosResponse

    try {
      const token = this.updateToken()
      axiosResponse = await this.api.request({
        url: this.baseUrl + url,
        method,
        data,
        headers: {
          ...token,
          ...headers,
        },
        params,
        ...options,
      })
    } catch (error: any) {
      axiosResponse = error.response
    }

    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    }
  }

  updateToken = (): object => {
    try {
      const token = TokenHelper.get()
      if (token) {
        return {
          Authorization: `Bearer ${token}`,
        }
      }
      return {}
    } catch (_) {
      return {}
    }
  }

  handleSuccess = (response: any): object => response

  handleError = (error: { response: { status: any } }): Promise<object> => {
    const { status } = error.response
    switch (status) {
      case 401:
        this.redirectTo(document, '/sair')
        break
      case 403:
        this.redirectTo(document, '/403')
        break
      case 404:
        this.redirectTo(document, '/404')
        break
      default:
        this.redirectTo(document, '/500')
    }
    return Promise.reject(error)
  }

  redirectTo = (document: { location: object }, path: any): void => {
    // eslint-disable-next-line no-param-reassign
    document.location = path
  }
}
