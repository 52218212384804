import styled from 'styled-components'
import { Button as ButtonAux, ButtonOutline as ButtonAuxOutline } from '../buttons'

export const Container = styled.div``

export const Overlay = styled.div`
  position: fixed;
  background-color: rgba(0,0,0,.1);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999999;
`

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const Content = styled.div`
  border-radius: 10px;
  box-shadow: 1px 1px 10px -2px #838383;
  margin-bottom: 10%;
  background-color: #FFF;
  padding: 30px;
  width: 85%;
  max-width: 350px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;

  * {
    margin: 0 5px;
  }
`

export const ButtonOutline = styled(ButtonAuxOutline)`
  margin-bottom: 10px;
`
export const Button = styled(ButtonAux)`
  margin-bottom: 10px;
`

export const Title = styled.h3`
  font-size: 24px;
  color: #383E71;
  font-weight: 400;
  margin-bottom: 1.6em;
`

export const SubTitle = styled.h4`
  font-size: 18px;
  color: #383E71;
  margin-bottom: 2.5em;
  margin-top: 0;
  font-weight: 300;
`
