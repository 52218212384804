import React from 'react'
import { Field, FieldProps } from 'formik'
import InputMask from 'react-input-mask'
import TplGroup from './tpl_group'
import { FormInput } from '../form/form_input'

interface InputMaskedProps {
  mask: string,
  name: string,
  title: string,
  placeholder: string,
  className: string,
  isRequired: boolean,
  tabIndex: number,
  type: string,
  onChange: (ob: object) => void,
  setFieldValue: (name: string, value: string) => void,
  errors: {
    [key: string]: string
  }
}

const InputMasked = ({
  name, mask, title, placeholder, isRequired, tabIndex, className, ...el
}: InputMaskedProps): JSX.Element => (
  <TplGroup name={name} title={title} isRequired={isRequired} {...el}>
    <Field
      name={name}
    >
      {({ field }: FieldProps) => (
        <InputMask
          {...field}
          placeholder={placeholder || ''}
          tabIndex={tabIndex}
          type={el.type}
          mask={mask}
          className={`input ${className}`}
          onChange={(e) => {
            if (el.onChange) {
              el.onChange({
                name,
                value: e.target.value,
              })
            }
            if (el.setFieldValue) {
              el.setFieldValue(name, e.target.value)
            }
          }}
        />
      )}
    </Field>
  </TplGroup>
)

export default InputMasked
