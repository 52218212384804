/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */

const ComponentsList = {
  Input: require('../ui/components/formik/input').default,
  Textarea: require('../ui/components/formik/textarea').default,
  InputMasked: require('../ui/components/formik/input_masked').default,
  InputMoney: require('../ui/components/formik/input_money').default,
  InputPassword: require('../ui/components/formik/input_password').default,
  InputPhoneWithCode: require('../ui/components/formik/input_phone_with_code').default,
  Select: require('../ui/components/formik/select').default,
  SelectOption: require('../ui/components/formik/select-option').default,
  Button: require('../ui/components/formik/button').default,
  Checkbox: require('../ui/components/formik/checkbox').default,
  CheckboxSwitch: require('../ui/components/formik/checkboxSwitch').default,
  CheckboxOption: require('../ui/components/formik/checkboxOption').default,
  checkboxOptionButton: require('../ui/components/formik/checkboxOptionButton').default,
  checkboxOptionOne: require('../ui/components/formik/checkboxOptionOne').default,
  Space: require('../ui/components/formik/space').default,
  DatePiker: require('../ui/components/formik/date_piker').default,
  File: require('../ui/components/formik/file').default,
}

export default ComponentsList
