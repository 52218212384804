/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import PropTypes from 'prop-types'
import ComponentsList from './components_list.jsx'

const CallInputs = ({ component, ...rest }) => {
  if (component && typeof component === 'object') return component

  const Component = ComponentsList[component]

  if (!Component) return null

  return (
    <Component {...rest} value={rest?.value} />
  )
}

CallInputs.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
}

export default CallInputs
