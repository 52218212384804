import styled from 'styled-components'

export const Container = styled.div`
  min-width: 100%;
  display: flex;
  flex-wrap: nowrap;
  position: relative;
`

export const Wrapper = styled.ul`
  display: flex;
  position: relative;
  padding: 0 2.5%;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0;
  cursor: pointer;
  align-items: center;
  
  &.active {
    cursor: grab;
  }
`
