/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteAddGoal } from '../../usecases/add-goal/add-goal'

export interface AddGoalStateInterface {
  list: any,
  loading: boolean,
  loaded: boolean,
  size?: number,
  page?: number,
  totalPages?: number,
  totalElements?: number,
}

// Define the initial state using that type
const initialState: AddGoalStateInterface = {
  list: [],
  loading: true,
  loaded: false,
}

export interface AddGoalsParamsProps {
  configuracaoPeriodoId: number
}

export const fetchAddGoals = createAsyncThunk(
  'add-goals/index',
  async (params: AddGoalsParamsProps, thunkAPI) => {
    thunkAPI.dispatch(addGoalLoading())

    const response = await makeRemoteAddGoal().index(params)

    thunkAPI.dispatch(addGoalReceived())
    thunkAPI.dispatch(addGoalLoaded())
    return response
  },
)

const slice = createSlice({
  name: 'add-goals',
  initialState,
  reducers: {
    addGoalLoaded(state) {
      state.loaded = true
    },
    addGoalLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    addGoalReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },

  },

  extraReducers: (builder) => {
    builder.addCase(fetchAddGoals.fulfilled, (state, action) => {
      state.list = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
  },
})

export const {
  addGoalLoaded,
  addGoalLoading,
  addGoalReceived,
} = slice.actions

export default slice.reducer
