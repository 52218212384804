/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeProgramRules } from '../../usecases/program-rules/program-rules'

export interface ItemProgramRulesCreateInterface {
  conteudo1?: string,
  conteudo2?: string,
  divisao: string | null,
  tipoId: number,
  ano: number
}

export interface ItemProgramRulesDataInterface {
  ano?: number,
  conteudo1?: string,
  conteudo2?: string,
  divisao: string,
  marcas: {
    divisao: string,
    marca: string,
    percentualBronze: number,
    percentualPrata: number,
    percentualOuro: number,
  }[],
}

export interface ProgramRulesStateInterface {
  data?: ItemProgramRulesDataInterface,
  loading: boolean,
  loaded: boolean,
  loadingTable: boolean,
  loadedTable: boolean,
}

// Define the initial state using that type
const initialState: ProgramRulesStateInterface = {
  loading: true,
  loadingTable: true,
  loaded: false,
  loadedTable: false,
}

interface ProgramRulesInterface{
  ano?: number | null,
  divisao: string | null,
  tipoId: number
}

export const fetchProgramRulesData = createAsyncThunk(
  'program-rules/data',
  async (params: ProgramRulesInterface, thunkAPI) => {
    thunkAPI.dispatch(dataLoading())
    const { ano, ...rest } = params

    const response = await makeProgramRules(ano).load(rest)

    thunkAPI.dispatch(dataReceived())
    thunkAPI.dispatch(dataLoaded())
    return response
  },
)

export const updateProgramRulesTable = createAsyncThunk(
  'program-rules/update-create',
  async (data: ItemProgramRulesCreateInterface, thunkAPI) => {
    thunkAPI.dispatch(tableLoading())

    const response = await makeProgramRules().create(data)

    thunkAPI.dispatch(tableReceived())
    thunkAPI.dispatch(tableLoaded())
    return response
  },
)

const slice = createSlice({
  name: 'program-rules',
  initialState,
  reducers: {
    dataLoaded(state) {
      state.loaded = true
    },
    dataLoading(state) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    dataReceived(state) {
      if (state.loading) {
        state.loading = false
      }
    },
    tableLoaded(state) {
      state.loadedTable = true
    },
    tableLoading(state) {
      if (!state?.loadingTable) {
        state.loadingTable = true
      }
    },
    tableReceived(state) {
      if (state.loadingTable) {
        state.loadingTable = false
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchProgramRulesData.fulfilled, (state, action) => {
      state.data = action.payload
    })
  },
})

export const {
  dataLoaded,
  dataLoading,
  dataReceived,
  tableLoaded,
  tableLoading,
  tableReceived,
} = slice.actions

export default slice.reducer
