/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { MobileHelper } from 'src/presentation/helpers/mobile-helper/mobile-helper'

const lowResolution = MobileHelper.lowResolution()
export interface HeaderInterface {
  isOpen: boolean,
  isOpenUserEdit: boolean,
  onlyAuth: boolean,
  editModel: boolean,
  title?: string
}

// Define the initial state using that type
const initialState: HeaderInterface = {
  isOpen: !lowResolution,
  isOpenUserEdit: false,
  onlyAuth: false,
  editModel: true,
  title: '',
}

const slice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    headerChange(state: HeaderInterface) {
      state.isOpen = !state.isOpen
    },
    closeHeader(state: HeaderInterface) {
      state.isOpen = false
    },
    openHeader(state: HeaderInterface) {
      state.isOpen = true
    },
    openUserEdit(state: HeaderInterface, action?: any) {
      state.isOpenUserEdit = true
      if (typeof action?.payload?.editModel === 'boolean') {
        state.editModel = action?.payload.editModel
      }
      state.onlyAuth = !!action.payload.onlyAuth

      state.title = action?.payload?.title
    },
    closeUserEdit(state: HeaderInterface) {
      state.isOpenUserEdit = false
      state.editModel = false
    },
  },
})

export const {
  headerChange,
  closeHeader,
  openHeader,
  closeUserEdit,
  openUserEdit,
} = slice.actions

export default slice.reducer
