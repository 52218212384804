import React from 'react'
import styled from 'styled-components'
import { theme } from '../../../../styles/styles'

interface SubTitleProps {
  title: string
}

const SubTitleStyled = styled.h3`
  color: ${theme.colors.black};
  font-size: 28px;
  font-weight: 700;
  position: relative;
  margin-bottom: 20px;
  line-height: 1;
`

const SubTitle = ({ title }: SubTitleProps): JSX.Element => (
  <SubTitleStyled>{title}</SubTitleStyled>
)

export default SubTitle
