import styled from 'styled-components'

interface ImageProps {
  src: string,
  alt: string,
  full?: string,
  maxW?: string
}

export const Image = styled.img`
  width: ${({ full }: ImageProps) => (full ? '100%' : 'auto')};
  max-width: ${({ maxW }: ImageProps) => maxW || '100%'};
`
