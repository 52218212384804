/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { Field, FieldProps } from 'formik'
import { FormControl, FormLabel } from '../form'
import { FormErrorMessage } from '../form/form_error_message'

interface CheckboxSwitchProps {
  disabled?: boolean,
  name: string,
  title: string,
  tabIndex: number,
  isRequired: boolean,
  checked: boolean,
  setFieldValue: (name: string, value: string | boolean) => void,
  errors: {
    [key: string]: string
  }
}

const CheckboxSwitch = ({
  name, title, isRequired, checked = false, ...el
}: CheckboxSwitchProps): JSX.Element => {
  const [check, setCheck] = useState<boolean>(false)

  const handleChange = (): void => {
    if (!el.disabled) setCheck((prev) => !prev)
    if (el.setFieldValue) {
      if (isRequired) el.setFieldValue(name, check ? '' : true)
      else el.setFieldValue(name, !check)
    }
  }

  useEffect(() => {
    if (checked) setCheck(checked)
  }, [])

  return (
    <FormControl>
      {title && (
        <FormLabel className="checkbox-switch-input-label" htmlFor={name} onClick={handleChange}>
          {title}
          <div className="checkbox-switch-input">
            <Field name={name}>
              {({ field }: FieldProps) => (
                <>
                  <input
                    type="checkbox"
                    {...field}
                    disabled={el.disabled || false}
                    tabIndex={el?.tabIndex}
                    checked={check}

                  />
                  <span className="slider round" />
                </>
              )}
            </Field>
          </div>
        </FormLabel>
      )}

      {/* {title && (
      <FormLabel className="checkbox-switch-input" htmlFor={name} onClick={handleChange}>
        <>
          <p dangerouslySetInnerHTML={{ __html: title }} />

          <Field name={name}>
            {({ field }: FieldProps) => (
              <>
                <input
                  type="checkbox"
                  {...field}
                  tabIndex={el?.tabIndex}
                  checked={check}
                />
                <span className="slider round" />
              </>
            )}
          </Field>
        </>
      </FormLabel>
      )} */}
      <FormErrorMessage>{el.errors[name]}</FormErrorMessage>
    </FormControl>
  )
}

export default CheckboxSwitch
