/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'
import { theme } from 'src/presentation/styles/styles'

export interface FileInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  disabled: boolean,
  isRequired: boolean,
  title: string,
  name: string,
  placeholder: string,
  className?: string,
  tabIndex: number,
  readonly?: number | undefined,
  values: any,
  validations?: string[] | undefined,
  setFieldValue: (name: string, value: string) => void,
  errors: {
    [key: string]: string
  }
  step: number | string,
}

const FileInput = ({
  name, title, placeholder = 'File', values, ...el
}:FileInputProps): JSX.Element => {
  const handleChange = (event: any): void => {
    if (el.onChange) {
      el.onChange(event.target.files[0])
    }

    if (el.setFieldValue) {
      el.setFieldValue(name, event.target.files[0])
    }
  }

  const getFileName = (): string => {
    const value = values[name]
    if (!value) return ''

    return value.name
  }

  return (
    <Flex>
      <CustomForm>
        <label>
          {title}
          <span>*</span>
        </label>
        <label className="file" htmlFor={`file-${name}`}>{placeholder}</label>
        <input
          id={`file-${name}`}
          onChange={(e:any) => {
            handleChange(e)
          }}
          accept=".xlsx"
          {...el}
          type="file"
        />

        <span>
          {getFileName()}
        </span>
      </CustomForm>
    </Flex>
  )
}

FileInput.defaultProps = {
  readonly: undefined,
  validations: undefined,
}

export default FileInput

export const Flex = styled.div`
  display: flex;
  gap: 1em;

  button {
    max-width: 200px;
    margin-top: 2em;
  }

  @media (max-width: ${theme.breakpoints[2]}){
    flex-direction: column;
    gap: unset;

    button {
      max-width: 100%;
      margin-top: 0;
    }
  }
`

export const CustomForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    color: #090909;
    font-size: 14px;
    margin-bottom: 5px;
    display: block;

    span {
      color: red;
    }
  }

  > input {
    border: 1px solid #868686;
    height: 45px;
    border-radius: 10px;
    margin: 0;
    padding: 0 10px;
  }

  small {
    margin-bottom: 20px;
  }

  input[type="file"]{
    display: none;
  }

  label.file{
    border: 1px solid #868686;
    background-color: #F4F4F4;
    height: 45px;
    border-radius: 10px;
    margin: 0;
    padding: 0 42px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #000;
    cursor: pointer;
    width: 100%;
    justify-content: center;
  }

  span {
    margin-top: 5px;
    font-size: 14px;
    font-style: italic;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 1;
    -webkit-line-clamp: 1;
  }

  @media (max-width: ${theme.breakpoints[2]}){
    label.file{
      width: 100%;
      justify-content: center;
    }
  }

`
