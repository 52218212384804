export const currentYear = new Date().getFullYear()

export const currentQuarter = (): number | null => {
  const today = new Date()
  const month = today.getMonth()

  if (month < 4) {
    return 1
  } if (month < 7) {
    return 2
  } if (month < 10) {
    return 3
  } if (month < 13) {
    return 4
  }

  return null
}

export const currentMonth = (): number => {
  const today = new Date()
  const month = today.getMonth()

  return month + 1
}

export const monthsObject:any = {
  1: 'Jan',
  2: 'Fev',
  3: 'Mar',
  4: 'Abr',
  5: 'Mai',
  6: 'Jun',
  7: 'Jul',
  8: 'Ago',
  9: 'Set',
  10: 'Out',
  11: 'Nov',
  12: 'Dez',
}

export const monthsFullNameObject:any = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
}
