import styled from 'styled-components'
import { theme } from '../../../../styles/styles'

export const FormInput = styled.input`
  width: 100%;
  height: 45px;
  border: none;
  border-radius: 10px;
  padding: 0 12px!important;
  background: ${theme.colors.white};
`

export const FormSelect = styled(FormInput).attrs({ as: 'select' })``

export const TextArea = styled(FormInput).attrs({ as: 'textarea' })`
  height: 80px;
  resize: none;
`

export const IcoView = styled.div`
  position: absolute;
  bottom: 2px;
  right: 12px;
  cursor: pointer;
  color: #090909;
  font-size: 23px;
  cursor: pointer;
`
