/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteQuotas, makeRemoteQuotasDivisions, makeRemoteQuotasClient } from '../../usecases/quotas/quotas'

export interface QuotasStateInterface {
  quotas: any,
  loading: boolean,
  loaded: boolean,
  filter: string[]
  divisions: any
  clients: any,

  clientsLoaded: boolean,
  clientsLoading: boolean,
  clientsLoadingMore?: boolean,
  clientSize?: number,
  clientPage?: number,
  clientTotalPages?: number,
  clientTotalElements?: number,
  clientFilterString?: string,
  clientFilterType?: string
  clientSort?: string,
}

export interface QuotasParamsInterface {
  page?: number,
  size?: number,
  sort?: string,
  filterString?: string,
  filterType?: string,
  divisoes: string[] | string,
  configuracaoPeriodoId: number,
}

// Define the initial state using that type
const initialState: QuotasStateInterface = {
  quotas: [],
  loading: true,
  clientsLoading: true,
  clientsLoaded: false,
  loaded: false,
  filter: [],
  divisions: [],
  clients: [],
}

export const fetchQuotas = createAsyncThunk(
  'quotas/current',
  async (params: QuotasParamsInterface | undefined, thunkAPI) => {
    thunkAPI.dispatch(quotasLoading())

    const response = await makeRemoteQuotas().index(params)

    thunkAPI.dispatch(quotasReceived())
    thunkAPI.dispatch(quotasLoaded())
    return response
  },
)

export const fetchQuotasDivisions = createAsyncThunk(
  'quotas/divisions',
  async (params: QuotasParamsInterface | undefined) => {
    const response = await makeRemoteQuotasDivisions().load(params)

    return response
  },
)

export const fetchQuotasClients = createAsyncThunk(
  'quotas/clients',
  async (params: QuotasParamsInterface | undefined, thunkAPI) => {
    thunkAPI.dispatch(quotasClientLoading())

    const response = await makeRemoteQuotasClient().index(params)

    thunkAPI.dispatch(quotasClientsReceived())
    thunkAPI.dispatch(quotasClientsLoaded())
    return response
  },
)

export const fetchMoreQuotasClients = createAsyncThunk(
  'quotas/clients-more',
  async (params: QuotasParamsInterface | undefined, thunkAPI) => {
    thunkAPI.dispatch(quotasMoreClientLoading())

    const response = await makeRemoteQuotasClient().index(params)

    thunkAPI.dispatch(quotasMoreClientsReceived())
    return response
  },
)

const slice = createSlice({
  name: 'quotas',
  initialState,
  reducers: {
    quotasLoaded(state: QuotasStateInterface) {
      state.loaded = true
    },
    quotasClientsLoaded(state: QuotasStateInterface) {
      state.clientsLoaded = true
    },
    quotasLoading(state: QuotasStateInterface) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    quotasClientLoading(state: QuotasStateInterface) {
      if (!state?.clientsLoading) {
        state.clientsLoading = true
      }
    },
    quotasMoreClientLoading(state: QuotasStateInterface) {
      if (!state?.clientsLoadingMore) {
        state.clientsLoadingMore = true
      }
    },
    quotasReceived(state: QuotasStateInterface) {
      if (state.loading) {
        state.loading = false
      }
    },
    quotasClientsReceived(state: QuotasStateInterface) {
      if (state.clientsLoading) {
        state.clientsLoading = false
      }
    },
    quotasMoreClientsReceived(state: QuotasStateInterface) {
      if (state.clientsLoadingMore) {
        state.clientsLoadingMore = false
      }
    },
    quotasFilter(state, action) {
      state.filter = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchQuotas.fulfilled, (state, action) => {
      state.quotas = action.payload
    })
    builder.addCase(fetchQuotasDivisions.fulfilled, (state, action) => {
      state.divisions = action.payload
    })
    builder.addCase(fetchQuotasClients.fulfilled, (state, action) => {
      state.clients = action.payload.content
      state.clientSize = action.payload.size
      state.clientPage = action.payload.number
      state.clientTotalPages = action.payload.totalPages
      state.clientTotalElements = action.payload.totalElements
    })
    builder.addCase(fetchMoreQuotasClients.fulfilled, (state, action) => {
      state.clients = [...state.clients, ...action.payload.content]
      state.clientSize = action.payload.size
      state.clientPage = action.payload.number
      state.clientTotalPages = action.payload.totalPages
      state.clientTotalElements = action.payload.totalElements
    })
  },
})

export const {
  quotasLoaded,
  quotasLoading,
  quotasReceived,
  quotasFilter,
  quotasClientLoading,
  quotasClientsLoaded,
  quotasClientsReceived,
  quotasMoreClientLoading,
  quotasMoreClientsReceived,
} = slice.actions

export default slice.reducer
