import React from 'react'
import { Box } from '../../components'
import { theme } from '../../../../styles/styles'
import { propTypesStyledHTML } from '../../../../utils/styled_helpers'

interface ColProps extends propTypesStyledHTML {
  children: JSX.Element,
  [key: string]: any,
}

const Col = ({ children, ...rest }: ColProps): JSX.Element => {
  const positions = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
  const numColumns = 12
  const colValue = 100 / numColumns
  const size = new Array(positions.length).fill('100%')

  positions.forEach((value, index) => {
    if (rest[value]) {
      size.fill(`${parseFloat((rest[value] * colValue).toFixed(5))}%`, index)
      Reflect.deleteProperty(rest, value)
    }
  })

  return (
    <Box className="col" px={theme.grid.gutter} mb={`${parseInt(theme.grid.gutter, 10) * 2}px`} w={size} {...rest}>
      {children}
    </Box>
  )
}
export default Col
