import React from 'react'
import { FormInput } from '../form'

interface SelectProps {
  children: JSX.Element | JSX.Element[]
}

const Select = ({ children, ...props } : SelectProps): JSX.Element => (
  <FormInput as="select" {...props}>
    {children}
  </FormInput>
)

export default Select
