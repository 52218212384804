import { RemoteUsers } from '../../../data/usecases/users/users'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'
import { makeAxiosHttpClient as NoRedirect } from '../../factories/http/axios-http-client-factory-no-redirect'

export const makeRemoteUsers = ():any => new RemoteUsers('/usuario', NoRedirect())

export const makeRemoteAllUsers = ():any => new RemoteUsers('/usuario/all', makeAxiosHttpClient())

export const makeRemoteUpdateUser = (id:number):any => new RemoteUsers(`/usuario/${id}`, NoRedirect())

export const makeRemoteDivision = ():any => new RemoteUsers('/divisao/all', makeAxiosHttpClient())

export const makeRemoteDivisionParent = ():any => new RemoteUsers('/divisao/all/parent', makeAxiosHttpClient())

export const makeRemoteCurrentUser = ():any => new RemoteUsers('/usuario/me', makeAxiosHttpClient())

export const makeRemoteClientsEnlace = ():any => new RemoteUsers('/pessoa/cliente', NoRedirect())

export const makeRemoteEnlaceClient = ():any => new RemoteUsers('/pessoa/cliente', NoRedirect())

export const makeRemoteUserExport = ():any => new RemoteUsers('/usuario/exportar-excel', NoRedirect())

export const makeRemoteUserImport = ():any => new RemoteUsers('/usuario/import-usuarios', NoRedirect())
