import { settings } from 'src/main/configs/settings'
import { CookieHelper } from '../cookie-helper/cookie-helper'

export class TokenHelper {
  static getCookiesToken = (): boolean | null => {
    let token = CookieHelper.get(settings.TOKEN)
    if (token) {
      return true
    }

    token = sessionStorage.getItem(settings.TOKEN)

    if (token) {
      return false
    }

    return null
  }

  static get = (): string => {
    let token = CookieHelper.get(settings.TOKEN)

    if (!token) {
      token = sessionStorage.getItem(settings.TOKEN)
    }

    return token
  }

  static set = (token: string, onCookies: boolean): void => {
    if (onCookies) {
      CookieHelper.add(settings.TOKEN, token)
    } else {
      sessionStorage.setItem(settings.TOKEN, token)
    }
  }

  static remove = (): void => {
    CookieHelper.remove(settings.TOKEN)
    sessionStorage.removeItem(settings.TOKEN)
  }
}
