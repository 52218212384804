import React, { useEffect, useState } from 'react'
import { Icons } from '../ui/components'
import FormFactory from '../form_factory'
import { fields } from './fields'
import * as S from './styles'

interface RegistrationProps {
  data: any,
  handleModal: () => void,
  onSubmit: (values: any) => void,
  title?: string
  editModel?: boolean
  onlyAuth: boolean
}

const Registration = ({
  data: content, handleModal, onSubmit, title = 'Usuário atual', editModel = false, onlyAuth,
}: RegistrationProps): JSX.Element => {
  const [editable, setEditable] = useState<boolean>(editModel)

  useEffect(() => () => {
    handleModal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const submit = async (values: any): Promise<void> => {
    await onSubmit(values)
    handleModal()
  }

  return (
    <>
      <S.Wrapper>
        <S.BgModal />
        <S.ModalContent>
          <S.CloseButton onClick={handleModal}>+</S.CloseButton>
          <S.FormHeader>
            <S.ProfileIcon>
              <Icons name="RiUser3Line" />
            </S.ProfileIcon>
            <h3>{title}</h3>
            {!editable && (
              <S.EditButton onClick={() => setEditable(true)}>
                <Icons name="RiPencilFill" />
                <span>Editar</span>
              </S.EditButton>
            )}
          </S.FormHeader>

          <FormFactory
            data={fields({
              data: content, handleModal, editable, onlyAuth,
            })}
            onSubmit={submit}
          />
        </S.ModalContent>
      </S.Wrapper>
    </>
  )
}

export default Registration
