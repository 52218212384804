import React from 'react'
import { Field, FieldProps } from 'formik'
import InputMask from 'react-input-mask'
import { Box } from '../index'
import TplGroup from './tpl_group'

interface InputPhoneWithCodeProps {
  title: string,
  placeholder: string,
  isRequired: boolean,
  tabIndex: number,
  name: string,
  setFieldValue:(name: string, value: string) => void,
  errors: {
    [key: string]: string
  }
}

const InputPhoneWithCode = ({
  name, title, placeholder, isRequired, tabIndex, ...el
}: InputPhoneWithCodeProps): JSX.Element => {
  const mask = '(99) 99999-9999'

  return (
    <TplGroup name={name} title={title} isRequired={isRequired} {...el}>
      <Box w="80%">
        <Field name={name}>
          {({ field }: FieldProps) => (
            <InputMask
              {...field}
              type="tel"
              placeholder={placeholder || ''}
              // maskChar=""
              tabIndex={tabIndex}
              required={isRequired}
              mask={mask}
              onChange={(e) => {
                if (el.setFieldValue) {
                  el.setFieldValue(name, e.target.value)
                }
              }}
            >
              {
                // (inputProps: object) => <FormInput {...inputProps} />
              }
            </InputMask>
          )}
        </Field>
      </Box>
    </TplGroup>
  )
}

export default InputPhoneWithCode
