import styled from 'styled-components'
import { theme } from '../../../../styles/styles'

const sizes = ['sm', 'md', 'lg', 'xl', 'xxl']
const defaultSize = 'xl'

interface ContainerProps {
  size: 'sm' | 'md' | 'lg' | 'xl' | 'xxl',
  fluid: boolean,

}

const getPosSize = (size: string): number => {
  if (sizes.indexOf(size) >= 0) return sizes.indexOf(size)

  return sizes.indexOf(defaultSize)
}

const handleMediaContainer = (value: string): string => {
  switch (value) {
    case 'sm': return theme.breakpoints[0]
    case 'md': return theme.breakpoints[1]
    case 'lg': return theme.breakpoints[2]
    case 'xl': return theme.breakpoints[3]
    case 'xxl': return theme.breakpoints[4]
    default: return ''
  }
}

const handleMaxWContainer = (value: string): string => {
  switch (value) {
    case 'sm': return '540px'
    case 'md': return '720px'
    case 'lg': return '960px'
    case 'xl': return '1140px'
    case 'xxl': return '1320px'
    default: return ''
  }
}

export const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
  ${(props: ContainerProps): string => {
    if (!props.fluid) {
      let html = ''
      for (let i = 0; i <= getPosSize(props.size); i += 1) {
        html += `
          @media (min-width: ${handleMediaContainer(sizes[i])}) {
            max-width: ${handleMaxWContainer(sizes[i])};
          }      
        `
      }
      return html
    }
    return ''
  }}
`
