/* eslint-disable class-methods-use-this */
import { PAGES_SIDE_BAR } from 'src/presentation/enums/pages'
import { USERS_TYPES } from 'src/presentation/enums/users'
import { PERMISSIONS } from '../../enums/page_permissions'
import { store } from '../../../main/store/store'

export class PermissionHelper {
  permission: any

  path: string

  constructor(path: string) {
    const { auth } = store.getState()
    if (!auth.user?.papeisEnum) {
      throw new Error('Usuário não encontrado')
    }
    this.permission = auth.user?.papeisEnum
    this.path = path
  }

  static start(path?: string): PermissionHelper {
    const localPath = path || window.location.pathname
    return new PermissionHelper(localPath)
  }

  private getPermission = (): any => {
    // search page map by regex
    const permission = Object
      .values(PERMISSIONS)
      .find((pagePermission: any) => {
        if (pagePermission.regex) {
          return this.path.match(pagePermission.regex)
        }
        return false
      })

    const page = PERMISSIONS[this.path] || permission

    return page
  }

  canExport(): boolean {
    const papel = this.permission[0]
    if (papel === USERS_TYPES.ADMINISTRADOR) return true

    // search page map
    const page = this.getPermission()

    // not found page
    if (!page) return false

    // found page
    // has set no permission
    if (Object.keys(page).length === 0) {
      return true
    }

    if (!('export' in page)) {
      return true
    }

    // check permission
    if (page?.export?.includes(papel)) {
      return true
    }

    return false
  }

  canEdit(): boolean {
    const papel = this.permission[0]
    if (papel === USERS_TYPES.ADMINISTRADOR) return true

    // search page map
    const page = this.getPermission()

    // not found page
    if (!page) return false

    // found page
    // has set no permission
    if (Object.keys(page).length === 0) {
      return true
    }

    if (!('edit' in page)) {
      return true
    }

    // check permission
    if (page?.edit?.includes(papel)) {
      return true
    }

    return false
  }

  canAccess(): boolean {
    const papel = this.permission[0]

    // search page map
    const page = PERMISSIONS[this.path]

    if (page?.onlyDev && process.env.NODE_ENV !== 'development') {
      return false
    }

    if (papel === USERS_TYPES.ADMINISTRADOR) return true

    // verify is client and has accepeted the program rules
    if (this.isClientAndNotAccpetedProgramRules()) {
      if (page && Object.keys(page).length === 0) {
        return true
      }

      return false
    }

    // not found page
    if (!page) return false

    // found page
    // has set no permission
    if (Object.keys(page).length === 0) {
      return true
    }

    if (!('access' in page)) {
      return true
    }

    // check permission
    if (page?.access?.includes(papel)) {
      return true
    }

    return false
  }

  isAdmin(): boolean {
    const papel = this.permission[0]
    if (papel === USERS_TYPES.ADMINISTRADOR) return true

    return false
  }

  isClient(): boolean {
    if (this.permission.includes(USERS_TYPES.CLIENTE)) return true

    return false
  }

  isClientAndNotAccpetedProgramRules(): boolean {
    const { auth } = store.getState()

    if (this.isClient() && !auth.accepted) return true

    return false
  }

  static allPagesCanAccess(): any[] {
    return this.pagesCanAccess(PAGES_SIDE_BAR)
  }

  static pagesCanAccess(array: any[]): any[] {
    const pages: any[] = []

    array.forEach((page) => {
      if (!page.path) {
        const has = this.pagesCanAccess(page.hasDropdown)
        if (has.length > 0) {
          pages.push({
            ...page,
            hasDropdown: has,
          })
        }
      } else {
        const canAccess = new PermissionHelper(page.path).canAccess()
        if (canAccess) {
          pages.push(page)
        }
      }
    })

    return pages
  }
}
