/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions, import/no-unresolved */

import { NotFoundError } from 'src/domain/errors/not-found-error'
import { HttpStatusCode } from '../../protocols/http/http-client'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'

export class RemoteTypes {
  url: string

  httpClient: any

  constructor(url: string, httpClient: any) {
    this.url = url
    this.httpClient = httpClient
  }

  async index(params?: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async create(body: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      data: body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.notFound: throw new NotFoundError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async update(id: number, body: any) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'put',
      data: body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.notFound: throw new NotFoundError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.badRequest: throw new Error('Erro ao enviar os dados.')
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async delete(id: number) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'delete',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.noContent: return true
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}
