/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ButtonModalInterface {
  title: string,
  outline?: boolean,
  action?: () => void
}

export interface ModalState {
  isVisible: boolean,
  title?: string,
  subTitle?: string,
  notClose?: boolean,
  buttons?: ButtonModalInterface[] | undefined
}

interface ShowInterface {
  title: string,
  subTitle: string,
  notClose?: boolean,
  buttons?: ButtonModalInterface[] | undefined,
}

const initialState: ModalState = {
  isVisible: false,
  title: '',
  subTitle: '',
  buttons: undefined,
}

export const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    close(state: ModalState) {
      document.getElementsByTagName('body')[0].classList.remove('modal-open')

      state.isVisible = false
      state.buttons = undefined
      state.title = ''
      state.subTitle = ''
      state.notClose = false
    },

    showPopUp(state, action: PayloadAction<ShowInterface>) {
      state.isVisible = true
      state.title = action.payload.title
      state.subTitle = action.payload.subTitle
      state.buttons = action.payload.buttons
      state.notClose = !!action.payload.notClose

      if (action.payload.notClose) {
        document.getElementsByTagName('body')[0].classList.add('modal-open')
      }
    },
  },
})

export const {
  close,
  showPopUp,
} = slice.actions

export default slice.reducer
