/* eslint-disable import/extensions, import/no-unresolved */

import { RemoteDashboard } from '../../../data/usecases/dashboard/dashboard'
import { makeAxiosHttpClient as makeAxiosNoRedirect } from '../../factories/http/axios-http-client-factory-no-redirect'

export const makeDashboardFiltroPeriodo = (): any => (
  new RemoteDashboard('/dashboard/cota/filtro/configuracaoperiodo', makeAxiosNoRedirect())
)

export const makeDashboardFiltroDivisao = (): any => (
  new RemoteDashboard('/dashboard/cota/filtro/divisao', makeAxiosNoRedirect())
)

export const makeDasboardMarca = ():any => (
  new RemoteDashboard('/dashboard/cota/filtro/marca', makeAxiosNoRedirect())
)

export const makeDashboardNacional = (): any => new RemoteDashboard('/dashboard/cota/filtro/nacional', makeAxiosNoRedirect())
export const makeDashboardRegional = (): any => new RemoteDashboard('/dashboard/cota/filtro/regional', makeAxiosNoRedirect())
export const makeDashboardVendedor = (): any => new RemoteDashboard('/dashboard/cota/filtro/vendedor', makeAxiosNoRedirect())
export const makeDashboardCliente = (): any => new RemoteDashboard('/dashboard/cota/filtro/cliente', makeAxiosNoRedirect())

export const makeDashboardTipo = (): any => new RemoteDashboard('/dashboard/cota/filtro/tipo', makeAxiosNoRedirect())

export const makeDashboardGrupo = (): any => new RemoteDashboard('/dashboard/cota/filtro/grupo', makeAxiosNoRedirect())

export const makeCotaEmpresa = (): any => new RemoteDashboard('/dashboard/cota/empresa', makeAxiosNoRedirect())

export const makeCotaEmpresaDivisao = (): any => (
  new RemoteDashboard('/dashboard/cota/empresa/detalhe-divisao', makeAxiosNoRedirect())
)

export const makeCotaEmpresaMarca = (): any => (
  new RemoteDashboard('/dashboard/cota/empresa/detalhe-marca', makeAxiosNoRedirect())
)

export const makeCotaNacional = (): any => new RemoteDashboard('/dashboard/cota/nacional', makeAxiosNoRedirect())

export const makeCotaNacionalManager = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/nacional/${codigoJDE}`, makeAxiosNoRedirect())
)

export const makeCotaNacionalManagerDivisao = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/nacional/${codigoJDE}/detalhe-divisao`, makeAxiosNoRedirect())
)

export const makeCotaNacionalManagerMarca = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/nacional/${codigoJDE}/detalhe-marca`, makeAxiosNoRedirect())
)

export const makeCotaRegional = (): any => new RemoteDashboard('/dashboard/cota/regional', makeAxiosNoRedirect())

export const makeCotaRegionalManager = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/regional/${codigoJDE}`, makeAxiosNoRedirect())
)

export const makeCotaRegionalManagerDivisao = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/regional/${codigoJDE}/detalhe-divisao`, makeAxiosNoRedirect())
)

export const makeCotaRegionalManagerMarca = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/regional/${codigoJDE}/detalhe-marca`, makeAxiosNoRedirect())
)

export const makeCotaVendedor = (): any => new RemoteDashboard('/dashboard/cota/vendedor', makeAxiosNoRedirect())

export const makeCotaVendedorManager = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/vendedor/${codigoJDE}`, makeAxiosNoRedirect())
)

export const makeCotaVendedorManagerDivisao = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/vendedor/${codigoJDE}/detalhe-divisao`, makeAxiosNoRedirect())
)

export const makeCotaVendedorManagerMarca = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/vendedor/${codigoJDE}/detalhe-marca`, makeAxiosNoRedirect())
)

export const makeCotaCliente = (): any => new RemoteDashboard('/dashboard/cota/cliente', makeAxiosNoRedirect())

export const makeCotaClienteManager = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/cliente/${codigoJDE}`, makeAxiosNoRedirect())
)

export const makeCotaClienteManagerDivisao = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/cliente/${codigoJDE}/detalhe-divisao`, makeAxiosNoRedirect())
)

export const makeCotaClienteManagerMarca = (codigoJDE: string): any => (
  new RemoteDashboard(`/dashboard/cota/cliente/${codigoJDE}/detalhe-marca`, makeAxiosNoRedirect())
)
