import styled from 'styled-components'
import { theme } from '../../../../styles/styles'

interface FormLabelProps {
  htmlFor: string,
}

export const FormLabel = styled.label.attrs((props: FormLabelProps) => ({
  htmlFor: props.htmlFor,
}))`
  font-weight: 400 !important;
  font-size: 13px;

  span {
    padding-left: 2px;
    font-size: 14px;
    font-weight: normal;
  }

  &.checkbox-input {
    position: relative;
    cursor: pointer;

    input {
      display: none;

      &:checked ~ span:after {
        content: '';
        left: 9px;
        top: 5px;
        width: 5px;
        height: 9px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        position: absolute;
        top: 2px;
        left: 5px;
      }
    }

    span {
      margin-left: 20px;
      font-weight: 300;
      font-size: 16px;
    }

    &:after {
      content: '';
      width: 15px;
      height: 15px;
      border: 2px solid #fff;
      display: flex;
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  &.checkbox-switch-input-label {
    margin-top: -8px;
    display: block;
  }

  .checkbox-switch-input {
    position: absolute;
    display: inline-block;
    width: 55px;
    height: 28px;
    left: 0;
    bottom: -45px;

    span {
      color: ${theme.colors.black};

      &.slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #cbcbcb;
        border-radius: 34px;
        transition: .4s;
        
        &::before {
          border-radius: 50%;
          position: absolute;
          content: "";
          height: 22px;
          width: 22px;
          left: 3px;
          bottom: 3px;
          background-color: white;
          transition: .4s;
        }
      }
    }

    input { 
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .slider {
        background-color: #27AE60;

        &:before {
          transform: translateX(26px);
        }
      }
      
      &:focus + .slider {
        box-shadow: 0 0 1px #27AE60;
      }
    }
  }
`
