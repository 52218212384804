/* eslint-disable max-len */
import React, { useLayoutEffect, useRef } from 'react'
import { Container, Wrapper } from './styles'

export const Slider = ({ children }: any):JSX.Element => {
  const isDown = useRef<any>(null)
  const startX = useRef<any>(null)
  const scrollLeft = useRef<any>(null)
  const containerRef = useRef<HTMLUListElement>(null)

  const end = (): void => {
    isDown.current = false
    containerRef.current?.classList.remove('active')
  }

  const start = (e: any): void => {
    isDown.current = true
    containerRef?.current?.classList.add('active')
    startX.current = (e.pageX || e.touches[0].pageX) - (containerRef?.current?.offsetLeft || 0)
    scrollLeft.current = (containerRef?.current?.scrollLeft)
  }

  const move = (e: any): void => {
    e.preventDefault()
    if (!isDown?.current) return

    const x = e.pageX || e.touches[0].pageX - (containerRef?.current?.offsetLeft || 0)
    const dist = (x - startX.current || 0)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    containerRef.current!.scrollLeft = (containerRef?.current?.offsetLeft || 0) + scrollLeft.current - dist
  }

  useLayoutEffect(() => {
    containerRef?.current?.addEventListener('mousedown', start)
    containerRef.current?.addEventListener('touchstart', start)

    containerRef.current?.addEventListener('mousemove', move)
    containerRef.current?.addEventListener('touchmove', move)

    containerRef.current?.addEventListener('mouseleave', end)
    containerRef.current?.addEventListener('mouseup', end)
    containerRef.current?.addEventListener('touchend', end)
  }, [])

  return (
    <Container>
      <Wrapper ref={containerRef}>
        {
          children
        }
      </Wrapper>
    </Container>
  )
}
