/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { ButtonModalInterface, close } from '../../../../../main/store-models/modal/modal'
import Loading from '../../../loading/loading'
import {
  Container,
  Overlay,
  ModalStyled,
  Content,
  Title,
  SubTitle,
  ContainerButtons,
  Button,
  ButtonOutline,
} from './styles'

const PopUp = (): JSX.Element => {
  const dispatch = useDispatch()
  const modal = useSelector((state: any) => state?.modal)

  const action = (button: ButtonModalInterface | null): void => {
    dispatch(close())
    if (button?.action) {
      button?.action()
    }
  }

  if (!modal?.isVisible) {
    return <></>
  }

  return (
    <Container>
      <Overlay />
      <ModalStyled>
        <Content>
          {
            modal?.title && <Title>{modal?.title}</Title>
          }
          {
            modal?.subTitle && <SubTitle>{modal?.subTitle}</SubTitle>
          }

          {modal?.buttons ? (
            <ContainerButtons>
              {modal.buttons.map((button: ButtonModalInterface, index: number) => (
                button.outline ? (
                  <ButtonOutline onClick={() => action(button)} key={index}>
                    {button.title}
                  </ButtonOutline>

                ) : (
                  <Button onClick={() => action(button)} key={index}>
                    {button.title}
                  </Button>

                )
              ))}
            </ContainerButtons>
          ) : (
            <>
              {modal.notClose ? (
                <>
                  <Loading onlySpinner borderWidth="5px" />
                </>
              ) : (
                <Button onClick={() => action({ title: 'OK' })}>
                  OK
                </Button>
              )}
            </>
          )}

        </Content>
      </ModalStyled>

    </Container>
  )
}
export default PopUp
