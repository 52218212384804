import React from 'react'
import { Field, FieldProps } from 'formik'
import NumberFormat from 'react-number-format'
import TplGroup from './tpl_group'
import { FormInput } from '../form/form_input'

interface InputMoneyProps {
  name: string,
  title: string,
  placeholder: string,
  isRequired: boolean,
  tabIndex: number,
  type: string,
  onChange:(ob: object) => void,
  setFieldValue:(name: string, value: string) => void,
  disabled?: boolean,
  errors: {
    [key: string]: string
  }
  values: any,
}

const InputMoney = ({
  name, title, placeholder, isRequired, tabIndex, ...el
}: InputMoneyProps): JSX.Element => (
  <TplGroup name={name} title={title} isRequired={isRequired} {...el}>
    <Field name={name} as={FormInput}>
      {({ field }: FieldProps) => (
        <NumberFormat
          {...field}
          className="input_money"
          placeholder={placeholder || ''}
          tabIndex={tabIndex}
          value={el.values[name]}
          onValueChange={({ floatValue }) => {
            if (el.onChange) {
              el.onChange({
                name,
                value: floatValue,
              })
            }
            if (el.setFieldValue) {
              el.setFieldValue(name, floatValue?.toString() || '')
            }
          }}
          prefix="R$ "
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          allowEmptyFormatting
          disabled={el.disabled}
        />
      )}
    </Field>
  </TplGroup>
)

export default InputMoney
