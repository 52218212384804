import * as S from './styles'

type TooltipProps = {
  children: React.ReactNode
  text: string | React.ReactNode
  className?: string
}

const Tooltip = ({ children, text, className = '' }:TooltipProps):JSX.Element => (
  <S.TooltipContainer>
    {children}
    <S.TooltipText className={className}>{text}</S.TooltipText>
  </S.TooltipContainer>
)

export default Tooltip
