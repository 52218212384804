import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Icons } from '../../components'
import { theme } from '../../../../styles/styles'

interface TitleProps {
  children: React.ReactNode,
  navigate?: boolean,
  to?: string,
}

const TitleButton = styled(Link)`
  color: ${theme.colors.black};
  text-align: start;
  margin: 25px 0;
  margin-left: 5px;
  font-size: ${theme.fontSizes.hf};
  text-decoration: none;
  font-weight: bold;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: transparent;
  cursor: pointer;

  svg {
    margin-right: 8px;
  }
`
const TitleStyled = styled.h2`
  color: ${theme.colors.black}!important;
  text-align: start;
  margin: 25px 0;
  margin-left: 5px;
  font-size: ${theme.fontSizes.hf};

  span {
    font-weight: normal;
  }
`

const Title = ({
  children, navigate, to,
}: TitleProps): JSX.Element => {
  if (navigate) {
    return (
      <TitleButton to={{
        pathname: to || '/cotas',
        state: { back: true },
      }}
      >
        <Icons name="RiArrowLeftSLine" size={22} color={theme.colors.black} />
        {children}
      </TitleButton>
    )
  }

  return (
    <TitleStyled>
      {children}
    </TitleStyled>
  )
}

export default Title
