import styled from 'styled-components'
import { theme } from '../../../../styles/styles'

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;

  &:hover{
    span {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const TooltipText = styled.span`
  visibility: hidden;
  width: 100px;
  background: #828282;
  color: #fff;
  font-family: ${theme.font.poppins};
  font-size: ${theme.fontSizes.xs};
  font-weight: ${theme.font.normal};
  text-align: center;
  border-radius: 10px;
  padding: 8px;
  position: absolute;
  bottom: 125%;
  left: 50%;

  &::after{
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #828282 transparent transparent transparent;
  }
`
