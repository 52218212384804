import '../../assets/fonts'
import '../../../global.d'
import React from 'react'
import { Provider } from 'react-redux'
import { GlobalStyle } from '../../styles/global'
import Routes from '../routes'
import { store } from '../../../main/store/store'
import Modal from '../ui/components/pop_up/pop_up'

function App(): JSX.Element {
  return (
    <Provider store={store}>
      <GlobalStyle />
      <Routes />
      <Modal />
    </Provider>
  )
}

export default App
