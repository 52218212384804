/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export interface CompaniesStateInterface {
  companies: any,
  loading: boolean,
  loaded: boolean,
}

// Define the initial state using that type
const initialState: CompaniesStateInterface = {
  companies: undefined,
  loading: true,
  loaded: false,
}

export const fetchCompanies = createAsyncThunk(
  'companies/current',
  async (uuid: string, thunkAPI) => {
    thunkAPI.dispatch(companiesLoading())

    // const response = await makeRemoteCompanies().load()

    thunkAPI.dispatch(companiesReceived())
    thunkAPI.dispatch(companiesLoaded())
    // return response
  },
)

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    companiesLoaded(state: CompaniesStateInterface) {
      state.loaded = true
    },
    companiesLoading(state: CompaniesStateInterface) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    companiesReceived(state: CompaniesStateInterface) {
      if (state.loading) {
        state.loading = false
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchCompanies.fulfilled, (state, action) => {
      state.companies = action.payload
    })
  },
})

export const {
  companiesLoaded,
  companiesLoading,
  companiesReceived,
} = slice.actions

export default slice.reducer
