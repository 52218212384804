import React from 'react'
import Footer from '../../footer'
import { Container } from './styles'

interface LoginLayoutProps {
  children: JSX.Element | JSX.Element[]
}

const LoginLayout = ({ children }: LoginLayoutProps): JSX.Element => (
  <>
    <Container>
      {children}
      <Footer />
    </Container>
  </>
)

export default LoginLayout
