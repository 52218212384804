import React from 'react'
import { Button } from '../ui/components'

interface FieldsProps {
  data?: any,
  handleModal: () => void,
  editable: boolean,
  onlyAuth: boolean,
}

export const fields = ({
  data, handleModal, editable, onlyAuth,
}: FieldsProps): any => ([
  {
    items: [
      {
        name: 'email',
        title: 'E-mail',
        disabled: !editable || onlyAuth,
        placeholder: 'Digite aqui',
        col: ['100%'],
        validations: 'inputRequired',
        component: 'Input',
        value: data?.email,
      },
      {
        name: 'nome',
        title: 'Nome',
        disabled: !editable || onlyAuth,
        placeholder: 'Digite aqui',
        col: ['100%'],
        validations: 'inputRequired',
        component: 'Input',
        value: data?.nome,
      },
      {
        name: 'login',
        title: 'Login',
        disabled: !editable || onlyAuth,
        placeholder: 'Digite aqui',
        col: ['100%', '50%'],
        validations: 'inputRequired',
        component: 'Input',
        value: data?.login,
      },
      {
        name: 'senha',
        title: 'Senha',
        disabled: !editable,
        placeholder: 'Digite aqui',
        col: ['100%', '50%'],
        component: 'InputPassword',
        value: '',
      },
      {
        name: 'button',
        component: !editable
          ? <Button onClick={handleModal} type="button">Fechar</Button>
          : <Button type="submit">Salvar</Button>,
        col: ['100%'],
      },
    ],
  },
])
