/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable import/extensions, import/no-unresolved */
import Qs from 'qs'
import { HttpStatusCode } from '../../protocols/http/http-client'
import { UnauthorizedError } from '../../../domain/errors/unauthorized-error'
import { AccessDeniedError } from '../../../domain/errors/access-denied-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import { NotFoundError } from '../../../domain/errors/not-found-error'

export class RemoteQuotasBu {
  url: string

  httpClient: any

  constructor(url: string, httpClient: any) {
    this.url = url
    this.httpClient = httpClient
  }

  async load(id: string, params?: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{ID}', id),
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async index(divisao: string, params?: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{DIVISAO}', divisao),
      method: 'get',
      params,
      paramsSerializer(paramsAux: any) {
        return Qs.stringify(paramsAux, { arrayFormat: 'repeat' })
      },
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async update(divisao: string, data: any, params: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{DIVISAO}', divisao),
      method: 'put',
      data,
      paramsSerializer(paramsAux: any) {
        return Qs.stringify(paramsAux, { arrayFormat: 'repeat' })
      },
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.notFound: throw new NotFoundError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async updateLine(data: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'put',
      data,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.notFound: throw new NotFoundError()
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async updateStatus(data: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'put',
      data,
    })

    const tempData = httpResponse.body.length > 0 ? httpResponse.body[0].msgUsuario : 'Erro na requisição'

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.notFound: throw new NotFoundError()
      case HttpStatusCode.badRequest: throw new Error(tempData)
      case HttpStatusCode.ok: return httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async updatePopUp(id: string, data: any, params: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url.replace('{ID}', id),
      method: 'put',
      data,
      params,
    })

    const tempData = httpResponse.body.length > 0 ? httpResponse.body[0].msgUsuario : 'Erro na requisição'

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.notFound: throw new NotFoundError()
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.badRequest: return new Error(tempData)
      default: throw new UnexpectedError()
    }
  }

  async post(data: any, params: any) {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: 'post',
      data,
      params,
    })

    const tempData = httpResponse.body.length > 0 ? httpResponse.body[0].msgUsuario : 'Erro na requisição'

    switch (httpResponse.statusCode) {
      case HttpStatusCode.unauthorized: throw new UnauthorizedError()
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      case HttpStatusCode.notFound: throw new NotFoundError()
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.badRequest: throw new Error(tempData)
      default: throw new UnexpectedError()
    }
  }
}
