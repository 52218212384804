import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { GuardedRoute } from 'react-router-guards'
import Loading from '../loading/loading'

type RouteFactoryProps = {
  routes: {
    layout: React.FC<any>;
    pages: {
      path: string;
      component: React.FC;
      meta?: object;
      exact?: boolean;
    }[];
  }[];
};

export const RouteFactory: React.FC<RouteFactoryProps> = ({
  routes,
}: RouteFactoryProps) => (
  <Switch>
    {routes.map(({ layout: Layout, pages }) => (
      pages.map(({ path, component, ...page }) => (
        <Route key={path} path={path} exact={typeof page.exact === 'boolean' ? page.exact : undefined}>
          <Suspense fallback={<Loading />}>
            <Layout>
              <Switch>
                <GuardedRoute
                  path={path}
                  component={component}
                  {...page}
                  exact={typeof page.exact === 'boolean' ? page.exact : undefined}
                />
              </Switch>
            </Layout>
          </Suspense>
        </Route>
      ))
    ))}
  </Switch>
)
