import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'formik'
import { TextArea } from '../form'
import TplGroup from './tpl_group'

interface Props {
  name: string,
  title: string,
  placeholder: string,
  isRequired: boolean,
  tabIndex: number,
  disabled: boolean,
  children: JSX.Element,
  setFieldValue: (name: string, value: string) => void,
  errors: {
    [key: string]: string
  }
}

const Textarea = ({
  name, title, placeholder, isRequired, tabIndex, disabled, ...el
}: Props): JSX.Element => (
  <TplGroup name={name} title={title} isRequired={isRequired} {...el}>
    <Field
      name={name}
      placeholder={placeholder || ''}
      tabIndex={tabIndex}
      required={isRequired}
      disabled={!!disabled}
      as={TextArea}
      onChange={(val: any) => {
        if (el.setFieldValue) {
          el.setFieldValue(name, val?.target?.value)
        }
      }}
    />
  </TplGroup>
)

Textarea.defaultProps = {
  title: '',
  placeholder: '',
  isRequired: false,
  tabIndex: 0,
  disabled: false,
}

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  tabIndex: PropTypes.number,
  disabled: PropTypes.bool,
}

export default Textarea
