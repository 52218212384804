/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign, import/extensions, import/no-unresolved */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { makeRemoteUpdateLine } from '../../usecases/quotas-managers/quotas-managers'
import {
  makeRemoteQuotasBu,
  makeRemoteQuotasBuManager,
  makeRemoteQuotasBuTotal,
  makeRemoteQuotasBuStatus,
  makeRemoteQuotasBuItemsPopUp,
  makeRemoteQuotasBuHeader,
  makeUpdateASP,
  makeUpdateGrowth,
  makeUpdateActualQuota,
  makeUpdateActualQtd,
  makeUpdateDivisionQuota,
} from '../../usecases/quotas-bu/quotas-bu'

interface QuotasBuStatus {
  readOnly: boolean,
  status: string,
  showButtonAprovacao?: boolean,
  showButtonReprovacao?: boolean,
  showButtonRevisao?: boolean,
  showButtonEnviarAprovacao?: boolean,
  dataReferencia?: string,
  aprovacoes: {
    dataHoraConfirmacao: string,
    ordem: number,
    papel: string,
    statusAprovacao: string,
    usuario: string
  }[],
  comentarios: {
    dataHora: string,
    reprovacao: boolean,
    texto: string,
    usuario: string
  }[],
}
export interface QuotasBuStateInterface {
  quotas: any,
  loading: boolean,
  loaded: boolean,
  filters: any[],
  size?: number,
  page?: number,
  totalPages?: number,
  totalElements?: number,
  numeroItem?: string,
  descricaoItem?: string,
  status: QuotasBuStatus,
  othersFilters?: {
    hasMesValor: boolean,
    shareAbaixoPendente:boolean,
    shareAcimaPendente: boolean,
  };

  // headers
  percentualCrescimento?: number,
  valorTotalLiquidoHistorico?: number,
  valorTotalLiquido?: number,
  valorTotal?: number,
  percentualTotalCotasFilhas?: number,
  headerLoading: boolean,
  onlyHeaderLoading?: boolean,
  headerLoaded: boolean,
  nome?: string,

  // managers
  managers: any[],
  managerLoading: boolean,
  managerloaded: boolean,
}

export interface QuotasBuParamsInterface {
  divisao: string,
  page?: number,
  size?: number,
  sort?: string,
  hasMesValor?: boolean,
  numeroItem?: string[],
  descricaoItem?: string[],
  marca?: string[],
  configuracaoPeriodoId?: number,
  shareAcimaPendente?: boolean,
  shareAbaixoPendente?: boolean,
  id?: string,
}

export interface QuotasBuHeaderUpdateInterface {
  divisao: string,
  data: {
    percentualCrescimento: number,
    configuracaoPeriodoId?: number,
  }
}

export interface GetLineQuotasBu {
  divisao: string,
  numeroItem: string,
  configuracaoPeriodoId: number,
}

export interface UpdateLineQuotasBu {
  configuracaoPeriodoId: number,
  percentualCrescimento?: number,
  valorUnitario: number,
  valor: number,
  quantidade: number,
  divisao: string,
  bu: string,
  numeroItem: string,
}

// Define the initial state using that type
const initialState: QuotasBuStateInterface = {
  quotas: [],
  loading: false,
  loaded: false,
  filters: [],
  managers: [],

  status: {
    readOnly: true,
    status: 'NAO_CADASTRADA',
    aprovacoes: [],
    comentarios: [],
  },

  headerLoading: true,
  headerLoaded: false,

  managerLoading: false,
  managerloaded: false,
}

export const fetchQuotasBuStatus = createAsyncThunk(
  'quotas-bu/status',
  async (params: QuotasBuParamsInterface) => {
    const { divisao, ...newParams } = params

    const response = await makeRemoteQuotasBuStatus().index(divisao, newParams)
    return response
  },
)

export const fetchQuotasBu = createAsyncThunk(
  'quotas-bu/current',
  async (params: QuotasBuParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(quotasBuLoading())

    const { divisao, ...newParams } = params

    const response = await makeRemoteQuotasBu().index(divisao, newParams)

    thunkAPI.dispatch(quotasBuReceived())
    thunkAPI.dispatch(quotasBuLoaded())
    return response
  },
)

export const fetchQuotasBuManager = createAsyncThunk(
  'quotas-bu/manager',
  async (params: QuotasBuParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(quotasBuManagerLoading())

    const { divisao, ...newParams } = params

    const response = await makeRemoteQuotasBuManager().index(divisao, newParams)

    thunkAPI.dispatch(quotasBuManagerReceived())
    thunkAPI.dispatch(quotasBuManagerLoaded())
    return response
  },
)

export const fetchQuotasBuHeader = createAsyncThunk(
  'quotas-bu/header',
  async (params: QuotasBuParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(quotasBuHeaderLoading())

    const { divisao, ...newParams } = params

    const response = await makeRemoteQuotasBuTotal().index(divisao, newParams)

    thunkAPI.dispatch(quotasBuHeaderReceived())
    thunkAPI.dispatch(quotasBuHeaderLoaded())
    return response
  },
)
export const OnlyQuotasBuHeader = createAsyncThunk(
  'quotas-bu/get-header',
  async (params: QuotasBuParamsInterface, thunkAPI) => {
    thunkAPI.dispatch(quotasBuOnlyHeaderLoading(true))

    const { divisao, ...newParams } = params

    const response = await makeRemoteQuotasBuTotal().index(divisao, newParams)

    thunkAPI.dispatch(quotasBuOnlyHeaderLoading(false))
    return response
  },
)

export const fetchQuotasBuHeaderUpdate = createAsyncThunk(
  'quotas-bu/header-update',
  async (params: QuotasBuHeaderUpdateInterface, thunkAPI) => {
    thunkAPI.dispatch(quotasBuOnlyHeaderLoading(true))

    const { divisao, data } = params

    const response = await makeRemoteQuotasBuHeader().update(divisao, data)

    thunkAPI.dispatch(quotasBuOnlyHeaderLoading(false))
    return response
  },
)

export const fetchBuItemsPopUp = createAsyncThunk(
  'quotas-bu/items-popUp',
  async (params: QuotasBuParamsInterface) => {
    const {
      divisao, id, ...newParams
    } = params

    const response = await makeRemoteQuotasBuItemsPopUp(divisao).load(id, newParams)

    return response
  },
)

export const getLineQuotasBu = createAsyncThunk(
  'quotas-bu/get-line',
  async (params: GetLineQuotasBu, thunkAPI) => {
    thunkAPI.dispatch(quotasBuLineLoading(params.numeroItem))

    const response = await makeRemoteUpdateLine({ ...params, bu: true, id: params.numeroItem })
      .load('', { configuracaoPeriodoId: params.configuracaoPeriodoId })

    return response
  },
)

export const updateLineQuotasBu = createAsyncThunk(
  'quotas-bu/update-line',
  async (params: UpdateLineQuotasBu, thunkAPI) => {
    thunkAPI.dispatch(quotasBuLineLoading(params.numeroItem))

    if (typeof params.valorUnitario !== 'undefined') {
      const response = await makeUpdateASP(params).updateLine({
        valorUnitario: params.valorUnitario,
        configuracaoPeriodoId: params.configuracaoPeriodoId,
      })

      return response
    }

    if (typeof params.percentualCrescimento !== 'undefined') {
      const response = await makeUpdateGrowth(params).updateLine({
        percentualCrescimento: params.percentualCrescimento,
        configuracaoPeriodoId: params.configuracaoPeriodoId,
      })

      return response
    }

    if (typeof params.valor !== 'undefined') {
      const response = await makeUpdateActualQuota(params).updateLine({
        valor: params.valor,
        configuracaoPeriodoId: params.configuracaoPeriodoId,
      })

      return response
    }

    if (typeof params.quantidade !== 'undefined') {
      const response = await makeUpdateActualQtd(params).updateLine({
        quantidade: params.quantidade,
        configuracaoPeriodoId: params.configuracaoPeriodoId,
      })

      return response
    }

    if (typeof params.bu !== 'undefined') {
      const response = await makeUpdateDivisionQuota(params).updateLine({
        divisao: params.bu,
        configuracaoPeriodoId: params.configuracaoPeriodoId,
      })

      return response
    }

    return Promise.reject(new Error('Campo não habilidato para edição'))
  },
)

const slice = createSlice({
  name: 'quotas-bu',
  initialState,
  reducers: {
    quotasBuLineLoading(state: QuotasBuStateInterface, action) {
      state.quotas = state.quotas.map((item: any) => {
        if (item.numeroItem === action.payload) {
          return ({
            ...item,
            loading: true,
          })
        }
        return item
      })
    },
    quotasBuLoaded(state: QuotasBuStateInterface) {
      state.loaded = true
    },
    quotasBuLoading(state: QuotasBuStateInterface) {
      if (!state?.loading) {
        state.loading = true
      }
    },
    quotasBuReceived(state: QuotasBuStateInterface) {
      if (state.loading) {
        state.loading = false
      }
    },
    quotasBuManagerLoaded(state: QuotasBuStateInterface) {
      state.managerloaded = true
    },
    quotasBuManagerLoading(state: QuotasBuStateInterface) {
      if (!state?.managerLoading) {
        state.managerLoading = true
      }
    },
    quotasBuManagerReceived(state: QuotasBuStateInterface) {
      if (state.managerLoading) {
        state.managerLoading = false
      }
    },
    quotasBuHeaderLoaded(state: QuotasBuStateInterface) {
      state.headerLoaded = true
    },
    quotasBuHeaderLoading(state: QuotasBuStateInterface) {
      if (!state?.headerLoading) {
        state.headerLoading = true
      }
    },
    quotasBuOnlyHeaderLoading(state: QuotasBuStateInterface, action) {
      state.onlyHeaderLoading = action.payload
    },
    quotasBuHeaderReceived(state: QuotasBuStateInterface) {
      if (state.headerLoading) {
        state.headerLoading = false
      }
    },
    quotasBuFilters(state: QuotasBuStateInterface, action) {
      state.filters = action.payload
    },
    quotasBuUpdateHasMesValor(state: QuotasBuStateInterface, action) {
      state.othersFilters = action.payload
    },
    resetStateBu(state: QuotasBuStateInterface) {
      state.quotas = []
      state.loading = false
      state.loaded = false
      state.filters = []
      state.managers = []
      state.status = {
        readOnly: true,
        status: 'NAO_CADASTRADA',
        aprovacoes: [],
        comentarios: [],
      }

      state.headerLoading = true
      state.headerLoaded = false

      state.managerLoading = false
      state.managerloaded = false

      state.percentualCrescimento = undefined
      state.valorTotalLiquidoHistorico = undefined
      state.valorTotalLiquido = undefined
      state.valorTotal = undefined
      state.percentualTotalCotasFilhas = undefined
      state.onlyHeaderLoading = undefined
      state.nome = undefined
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchQuotasBu.fulfilled, (state, action) => {
      state.quotas = action.payload.content
      state.size = action.payload.size
      state.page = action.payload.number
      state.totalPages = action.payload.totalPages
      state.totalElements = action.payload.totalElements
    })
    builder.addCase(fetchQuotasBuManager.fulfilled, (state, action) => {
      state.managers = action.payload
    })
    builder.addCase(fetchQuotasBuHeader.fulfilled, (state, action) => {
      state.percentualCrescimento = action.payload.percentualCrescimento
      state.valorTotalLiquidoHistorico = action.payload.valorTotalLiquidoHistorico
      state.valorTotalLiquido = action.payload.valorTotalLiquido
      state.valorTotal = action.payload.valorTotal
      state.percentualTotalCotasFilhas = action.payload.percentualTotalCotasFilhas
      state.nome = action.payload.nome
    })
    builder.addCase(OnlyQuotasBuHeader.fulfilled, (state, action) => {
      state.percentualCrescimento = action.payload.percentualCrescimento
      state.valorTotalLiquidoHistorico = action.payload.valorTotalLiquidoHistorico
      state.valorTotalLiquido = action.payload.valorTotalLiquido
      state.valorTotal = action.payload.valorTotal
      state.percentualTotalCotasFilhas = action.payload.percentualTotalCotasFilhas
      state.nome = action.payload.nome
    })
    builder.addCase(fetchQuotasBuStatus.fulfilled, (state, action) => {
      state.status = action.payload
    })
    builder.addCase(getLineQuotasBu.fulfilled, (state, action) => {
      state.quotas = state.quotas.map((item: any) => {
        if (item.numeroItem === action?.payload.numeroItem) {
          return action.payload
        }

        return item
      })
    })
    builder.addCase(updateLineQuotasBu.fulfilled, (state, action) => {
      state.quotas = state.quotas.map((item: any) => {
        if (item.numeroItem === action?.payload.numeroItem) {
          return action.payload
        }

        return item
      })
    })
    builder.addCase(updateLineQuotasBu.rejected, (state) => {
      state.quotas = state.quotas.map((item: any) => ({ ...item, loading: false }))
    })
  },
})

export const {
  quotasBuLoaded,
  quotasBuLoading,
  quotasBuReceived,
  quotasBuManagerLoaded,
  quotasBuManagerLoading,
  quotasBuManagerReceived,
  quotasBuHeaderLoaded,
  quotasBuHeaderLoading,
  quotasBuHeaderReceived,
  quotasBuLineLoading,
  quotasBuFilters,
  quotasBuOnlyHeaderLoading,
  resetStateBu,
  quotasBuUpdateHasMesValor,
} = slice.actions

export default slice.reducer
