import { theme } from 'src/presentation/styles/styles'
import styled from 'styled-components'

export const UserWidgetStyled = styled.div`
  position: relative;
`

export const Profile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  h2 {
    font-weight: 400;
    font-size: 16px;
    margin: 0 15px;

    @media (max-width: ${theme.breakpoints[2]}){
      display: none;
    }
  }
`

export const ProfileHandle = styled.button`
  cursor: pointer;
  display: block;
  background: none;
  padding: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 6px solid ${theme.colors.black};
  border-top: 9px solid #090909;
  border-radius: 4px 4px 0 0;

  @media (max-width: ${theme.breakpoints[2]}){
    display: none;
  }
`

export const ProfileContent = styled.div`
  background: ${theme.colors.white};
  position: absolute;
  top: 60px;
  right: 0;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 13px 10px 10px;
  border-radius: 10px;
  z-index: 1;
`

export const ProfileIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.primary};
  height: 34px;
  width: 34px;
  border-radius: 50%;
  
  svg {
    fill: ${theme.colors.black};
    height: 16px;
  }
`

export const Content = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  padding-right: 40px;
  border-radius: 10px;
  cursor: pointer;

  svg {
    fill: ${theme.colors.primary};
    margin: 3px 5px 0 0;
    font-size: 20px;
  }

  :hover {
    background-color: #f2f2f2;
  }
`

export const MetaInfo = styled.div`
  border-bottom: 1px solid #E6E6E6;
  
  div {
    display: flex;
    margin: 10px 0;
  }

  h3 {
    font-size: 14px;
  }

  p {
    font-size: 12px;
    color: #b3b3b3;
    line-height: 1;
    margin: 0;
  }

  span {
    display: flex;
    color: ${theme.colors.black};
    font-size: 12px;
    line-height: 1;
    margin-right: 5px;
  }
`

export const LogoutButton = styled.button`
  display: flex;
  border: none;
  background: none;
  cursor: pointer;
  align-items: center;
  padding: 7px 0 2px;
  width: 100%;

  span {
    line-height: 1;
    margin-top: 5px;
    font-weight: 400;
  }

  svg {
    fill: #EB5757;
    margin: 3px 5px 0 0;
    font-size: 20px;
  }
`
