/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState } from 'react'
import { Field, FieldProps } from 'formik'
import styled from 'styled-components'
import { FormControl, FormLabel } from '../form'
import { theme } from '../../../../styles/styles'

interface Option {
  value: string,
  text: string,
}

interface Styled {
  bordered: boolean,
}

interface CheckboxOptionProps {
  name: string,
  title: string,
  options: Option[] | [],
  tabIndex: number,
  maxChecked: number,
  checked: boolean,
  value: Option[] | [],
  setFieldValue: (name: string, value: any[]) => void,
  border: number
}

const CheckboxOption = ({
  name, title, maxChecked = 3, options = [], ...el
}: CheckboxOptionProps): JSX.Element => {
  const [check, setCheck] = useState<Option[] | []>(el.value || [])

  const isSelected = (option: any): boolean => {
    if (check.filter((item) => item.value === option.value).length > 0) return true
    return false
  }

  const handleChange = (value: Option): void => {
    if (isSelected(value)) {
      const newValues = check.filter((item) => item.value !== value.value)

      setCheck(newValues)
      if (el.setFieldValue) {
        el.setFieldValue(name, newValues)
      }
      return
    }

    if (check.length >= maxChecked) {
      return
    }

    setCheck([...check, value])

    if (el.setFieldValue) {
      el.setFieldValue(name, [...check, value])
    }
  }

  return (
    <FormControl style={{ marginBottom: 15 }}>
      {title && (
        <FormLabel htmlFor={name}>
          <BoxStyled bordered={!!el.border}>
            <MinTitle bordered={!!el?.border}>{title}</MinTitle>
            <Field name={name}>
              {({ field }: FieldProps) => (
                <Container>
                  {options.map((option, index) => (
                    <Item className="checkbox" key={`${name}-${index}`}>
                      <input
                        type="checkbox"
                        {...field}
                        tabIndex={el?.tabIndex}
                        checked={isSelected(option)}
                        id={`${index}-${name}`}
                        onChange={() => handleChange(option)}
                      />
                      <label htmlFor={`${index}-${name}`} dangerouslySetInnerHTML={{ __html: option.text }} />
                    </Item>
                  ))}
                </Container>
              )}
            </Field>
          </BoxStyled>
        </FormLabel>
      )}
    </FormControl>
  )
}

export default CheckboxOption

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

`
const Item = styled.div`
  width: fit-content;
  display: flex;
  border-radius: 26px;
  height: 32px;
  overflow: hidden;
  margin: 7px 15px 7px 0;
  cursor: pointer;
`

const BoxStyled = styled.div`
  padding: ${(props : Styled) => (props.bordered ? '15px' : 'unset')};
  border-radius: 6px;
  border: ${(props : Styled) => (props.bordered ? '1px solid #D8D8D8' : 'none')};
`

const MinTitle = styled.h3`
  color: ${theme.colors.backgroundColor}!important;
  margin: ${(props: Styled) => (props.bordered ? '8px 0 16px' : '0 0 10px')};
  font-size: ${(props: Styled) => (props.bordered ? '18px' : 'unset')};
`
