import styled from 'styled-components'

interface AspectRatioBox {
  ratio: number
}

const aspectRatio = (ratio: number = 4 / 3):string => `${(1 / ratio) * 100}%`

export const AspectRatioBox = styled.div`
  position: relative;

  &:before {
    content: '';
    height: 0;
    display: block;
    padding-bottom: ${(props:AspectRatioBox) => aspectRatio(props.ratio)}
  }

  > * {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`
