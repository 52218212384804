import React from 'react'
import { Flex } from '../../components'
import { theme } from '../../../../styles/styles'

interface RowProps {
  children: JSX.Element | JSX.Element[]
}

const Row = ({ children, ...rest }: RowProps): JSX.Element => (
  <Flex flexWrap="wrap" {...rest} mx={`-${theme.grid.gutter}`}>
    {children}
  </Flex>
)

export default Row
