/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Field, FieldProps } from 'formik'
import styled from 'styled-components'
import { FormControl, FormLabel } from '../form'
import { theme } from '../../../../styles/styles'

interface CheckboxOptionButtonProps {
  name: string,
  title: string,
  tabIndex: number,
  checked: boolean,
  value: boolean,
  setFieldValue: (name: string, value: boolean) => void,
  onChange: (item: any) => void,
  border: number,
  text: string,
}

const CheckboxOptionButton = ({
  name, title, tabIndex, ...el
}: CheckboxOptionButtonProps): JSX.Element => {
  const handleChange = (value: boolean): void => {
    el.onChange(value)
    el.setFieldValue(name, value)
  }

  return (
    <FormControl style={{ marginBottom: 15 }}>
      {
        title && (
          <Label htmlFor={name}>
            {title}
          </Label>
        )
      }

      <FormLabel htmlFor={name}>
        <Field name={name}>
          {({ field }: FieldProps) => (
            <Container>
              <Item className="checkbox">
                <input
                  type="checkbox"
                  {...field}
                  tabIndex={tabIndex}
                  checked={el.value}
                  onChange={() => handleChange(!el.value)}
                />
                <span>{el.text}</span>
                <div className="checkmark" />
              </Item>
            </Container>
          )}
        </Field>
      </FormLabel>
    </FormControl>
  )
}

export default CheckboxOptionButton

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Label = styled(FormLabel)`
  display: block;
  margin-bottom: 20px;
`

const Item = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  margin: 0 20px 20px 0;
  padding-left: 25px;
  box-sizing: border-box;

  @media (max-width: ${theme.breakpoints[2]}){
    min-width: calc(50% - 20px);
  }

  &.disabled {
    opacity: .2;
    cursor: progress;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      &:after {
        display: block;
      }
    }
  }

  span {
    line-height: 1;
    font-size: 14px;
    text-transform: uppercase;
    margin-left: 8px;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    left: 0;
    height: 18px;
    width: 18px;
    border: solid #828282 2px;
    transform: translateY(-50%);
    border-radius: 3px;

    &:after {
      content: "";
      position: absolute;
      display: none;

      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid ${theme.colors.black};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);

      @media (max-width: ${theme.breakpoints[2]}){
        width: 10px;
        height: 15px;
      }
    }

    @media (max-width: ${theme.breakpoints[2]}){
      height: 25px;
      width: 25px;
    }
  }
`
