import React from 'react'
import { FormLabel, FormControl, FormInput } from '../form'

export interface InputProps {
  disabled?: boolean,
  autofocus?: boolean,
  autoComplete?: boolean,
  title?: string,
  name?: string,
  type?: string,
  placeholder?: string,
  max?: number | undefined,
  min?: number | undefined,
  maxLength?: number | undefined,
  readonly?: number | undefined,
  value?: string,
  onChange?: (value: string) => void,
  onBlur?: (value: any) => void,
}

export const Input = ({
  name, title, type, placeholder, disabled, ...el
}:InputProps): JSX.Element => {
  const options = {
    ...(el.max) && { max: el.max },
    ...(el.min) && { min: el.min },
    ...(el.maxLength) && { maxLength: el.maxLength },
    ...(el.readonly) && { readonly: el.readonly },
    ...(el.autofocus) && { autoFocus: el.autofocus },
    ...(el.onBlur) && { onBlur: el.onBlur },
  }

  const handleChange = (event: any): void => {
    const { value } = event.target
    if (!!el.maxLength && value.length > el.maxLength) {
      return
    }

    if (el.onChange) {
      el.onChange(event.currentTarget.value)
    }
  }

  return (
    <FormControl>
      {title && (
        <FormLabel htmlFor={name}>
          {title}
        </FormLabel>
      )}
      <FormInput
        type={type || 'text'}
        name={name}
        placeholder={placeholder || ''}
        min={0}
        disabled={!!disabled}
        autoComplete={el.autoComplete ? 'on' : 'new-password'}
        {...options}
        onChange={(e: any) => handleChange(e)}
        value={el.value || undefined}
      />
    </FormControl>
  )
}
