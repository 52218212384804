export const theme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1400px', '1520px'],
  grid: {
    container: '130rem',
    gutter: window.innerWidth < 768 ? '.2rem' : '.5rem',
  },
  border: {
    radius: '16px',
    xradius: '24px',
  },
  font: {
    roboto:
      'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    poppins: '"Poppins", sans-serif',
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
    xbold: 700,
    sizes: {
      xxxxsmall: '0.750rem',
      xxxsmall: '0.875rem',
      xxsmall: '1.125rem',
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.5rem',
      large: '1.8rem',
      xlarge: '2.2rem',
      xxlarge: '2.8rem',
      huge: '4.6rem',
    },
  },
  fontSizes: {
    xxs: '0.650rem', // 10px
    uxs: '0.750rem', // 12px
    xs: '0.875rem', // 14px
    sm: '1rem', // 16px
    md: '1.125rem', // 18px
    hf: '1.375rem', // 22px
    lg: '1.75rem', // 28px
    xl: '2.125rem', // 34px
    xxl: '2.5rem', // 40px
    bigger: '7rem', // 112px
  },
  colors: {
    primary: '#02DAE2',
    secondary: '#2898be',
    mainBg: '#F2F2F2',
    white: '#FAFAFA',
    lightGray: '#BDBDBD',
    lightGrayBlue: '#E0EDF2',
    gray: '#231F20',
    darkGray: '#6f6f6e',
    black: '#090909',
    red: '#EB5757',
    backgroundColor: '#E0EDF2',
    blue: '#208AB7',
    green: '#219653',
    lightGreen: '#00C247',
    purple: '#6160DC',
  },
  transition: {
    default: '0.2s ease-in-out',
    fast: '0.1s ease-in-out',
  },
}
