import { Link } from 'react-router-dom'
import { theme } from 'src/presentation/styles/styles'
import styled from 'styled-components'

interface ItemProps {
  isActive: boolean
}

interface DropdownItemProps {
  isActive: boolean
  dropdown: boolean
}

interface LeftBarProps {
  open: boolean
}
interface RightContentProps {
  leftBar: number
}

export const Container = styled.div`
  min-height: 100vh;
  width:100%;
  max-width:100%;
  display: flex;
  flex-direction: column;
  background: ${theme.colors.backgroundColor};


  @media print {
    background-color: #E0EDF2;
    
    .menu {
      display: none;
    }

    header, footer {
      display: none;
    }
  }
`

export const InternContainer = styled.div`
  display: flex;
  flex-grow: 1;
  width:100%;
  max-width:100%;
`

export const LeftBar = styled.div<LeftBarProps>`
  z-index: 4;
  display: flex;
  flex-direction: column;
  max-width: fit-content;
  min-width: fit-content;

  background: rgb(9,9,9);
  background: linear-gradient(180deg, rgba(9,9,9,1) 0%, rgba(32,138,183,1) 130%);

  border-radius: 0 16px 16px 0;
  padding: 15px 20px 15px 10px;
  transition: ease all 500ms;
  margin-top: 10px;

  @media (max-width: ${theme.breakpoints[2]}){
    z-index: 4;
    position: fixed;
    height: calc(100% - 20px);
    left: ${(props) => (props.open ? '0' : '-100%')};
    bottom: 0;
    top: 0;

    .item {
      display: ${(props) => (props.open ? 'flex' : 'none')};
    }
  }
`
export const BackDrop = styled.div<LeftBarProps>`
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  background-color: transparent;
  z-index: 1;
  display: none;

  @media (max-width: ${theme.breakpoints[2]}){
    display: ${(props) => (props.open ? 'flex' : 'none')};
  }
`

export const RightContent = styled.div<RightContentProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${(props) => `calc(100% - ${props.leftBar}px)`};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0 10px;
  max-width: 100%;
`

export const Item = styled(Link)<ItemProps>`
  display: flex;
  align-items: center;
  margin-bottom: 2px;

  text-decoration: none;
  padding: 8px 5px 8px 0;

  background-color: ${(props) => (props?.isActive ? theme.colors.blue : 'transparent')};
  border-radius: 10px;
  position: relative;

  h6 {
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.black};
    color:  ${(props) => (props?.isActive ? theme.colors.black : theme.colors.lightGrayBlue)};
    font-weight: 400;
  }

  svg {
    margin: 5px 15px;
  }

  &:before {
    content: "";
    height: 64%;
    width: 4px;
    background-color: ${theme.colors.black};
    position: absolute;
    left: 0;
    border-radius: 5px;
    display:  ${(props) => (props?.isActive ? 'block' : 'none')};
  }

  :hover {
    h6, svg{
      opacity: 0.85;
    }
  }
`

export const DropdownItem = styled(Link)<ItemProps>`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 8px 5px 8px 0;

  h6 {
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.black};
    color:  ${(props) => (props?.isActive ? theme.colors.black : theme.colors.lightGrayBlue)};
    font-weight: 400;
  }

  svg {
    margin: 5px 15px;
  }

  :hover {
    h6, svg{
      opacity: 0.85;
    }
  }
`

export const Dropdown = styled.div<DropdownItemProps>`
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 8px 5px 8px 0;
  cursor: pointer;

  border-radius: 10px;
  border-radius: ${(props) => (props?.dropdown ? '10px 10px 0 0' : '10px')};
  position: relative;
  background-color: ${(props) => (props?.isActive ? theme.colors.blue : 'transparent')};

  h6 {
    font-size: ${theme.fontSizes.xs};
    color: ${theme.colors.black};
    color:  ${(props) => (props?.dropdown ? theme.colors.black : theme.colors.lightGrayBlue)};
    font-weight: 400;
  }

  svg {
    margin: 5px 15px;
  }

  &:before {
    content: "";
    height: 64%;
    width: 4px;
    background-color: ${theme.colors.black};
    position: absolute;
    left: 0;
    border-radius: 5px;
    display:  ${(props) => (props?.isActive ? 'block' : 'none')};
  }

  :hover {
    h6, svg{
      opacity: 0.85;
    }
  }
`

export const DropdownItems = styled.div`
  background: ${theme.colors.backgroundColor};
  border-radius: 0 0 10px 10px;
  width: 100%;
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 150px;
  padding: 0 3px;
  position: relative;

  svg {
    position: absolute;
    right: -28px;
    margin: 0;
  }
`

export const IconMenu = styled.div`
  width: fit-content;
  margin: 0px 0 10px 5px;
  padding: 10px;
  cursor: pointer;
`
