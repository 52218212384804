import { lazy } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { GuardProvider } from 'react-router-guards'
import { RouteFactory } from './route-factory'
import { guardsRoutes } from './utils/functions'

// Layouts base
import { BaseLayout, LoginLayout } from '../layouts'

// Pages
const Home = lazy(() => import('../../pages/home'))
const PageNotFound = lazy(() => import('../../pages/errors/page-not-found'))
const PageServerError = lazy(() => import('../../pages/errors/page-server-error'))
const PageNotAuthorized = lazy(() => import('../../pages/errors/page-not-authorized'))
const ForgotPassword = lazy(() => import('../../../main/factories/pages/auth/password-recovery-factory'))
const Login = lazy(() => import('../../../main/factories/pages/auth/login-factory'))
const DashboardMetas = lazy(() => import('../../../main/factories/pages/dashboard-metas-factory/dashboard-metas-factory'))
const Users = lazy(() => import('../../../main/factories/pages/users-factory/users-factory'))
const ApprovalGroup = lazy(() => import('../../../main/factories/pages/approval-group-factory/approval-group-factory'))
const Clients = lazy(() => import('../../../main/factories/pages/clients-factory/clients-factory'))
const ProgramRules = lazy(() => import('../../../main/factories/pages/program-rules-factory/program-rules'))
const Quotas = lazy(() => import('../../../main/factories/pages/quotas-factory/quotas-factory'))
const QuotasBU = lazy(() => import('../../../main/factories/pages/quotas-bu-factory/quotas-bu-factory'))
const QuotasManagers = lazy(() => import('../../../main/factories/pages/quotas-manager-factory/quotas-manager-factory'))
const GoalCalculations = lazy(() => import('../../../main/factories/pages/goal-calculations-factory/goal-calculations-factory'))
const Classifications = lazy(() => import('../../../main/factories/pages/classifications-factory/classifications-factory'))
const Types = lazy(() => import('../../../main/factories/pages/types-factory/types-factory'))
const DashboardKpi = lazy(() => import('../../../main/factories/pages/dashboard-kpi-factory/dashboard-kpi-factory'))
const CustomerGoalData = lazy(() => import('../../../main/factories/pages/customer-goals-data-factory/customer-goals-data-factory'))
const CustomerGoals = lazy(() => import('../../../main/factories/pages/customer-goals-factory/customer-goals-factory'))
const DistributionsList = lazy(() => import('../../../main/factories/pages/distributions-list-factory/distributions-list-factory'))
const Distributions = lazy(() => import('../../../main/factories/pages/distributions-factory/distributions-factory'))
const BonusProducts = lazy(() => import('../../../main/factories/pages/bonus-products-factory/bonus-products-factory'))
const DuplicateDiscount = lazy(() => import('../../../main/factories/pages/duplicate-discount-factory/duplicate-discount-factory'))
const ProgramRulesSettings = lazy(() => import('../../../main/factories/pages/program-rules-settings-factory/program-rules-settings-factory'))
const ImportSpreadsheet = lazy(() => import('../../../main/factories/pages/import-spreadsheet/import-spreadsheet'))
const GenerateCalculation = lazy(() => import('../../../main/factories/pages/generate-calculation-factory/generate-calculation-factory'))
const UpdateCalculation = lazy(() => import('../../../main/factories/pages/update-calculation-factory/update-calculation-factory'))
const DemandRansom = lazy(() => import('../../../main/factories/pages/demand-ransom-factory/demand-ransom-factory'))
const ApproveOrder = lazy(() => import('../../../main/factories/pages/approve-order-factory/approve-order-factory'))
const Balances = lazy(() => import('../../../main/factories/pages/balances-factory/balances-factory'))
const AddGoalFactory = lazy(() => import('../../../main/factories/pages/add-goal-factory/add-goal-factory'))
const DashboardOpeningReportFactory = lazy(() => import('../../../main/factories/pages/dashboard-opening-report-factory/dashboard-opening-report-factory'))
const DashboardCotaMetaFactory = lazy(() => import('../../../main/factories/pages/dashboard-cota-meta/dashboard-cota-meta-factory'))
const AcceptedProgramRulesFactory = lazy(() => import('../../../main/factories/pages/accept-program-rules-factory/accept-program-rules-factory'))

const Routes = (): JSX.Element => {
  const routes = [
    {
      layout: LoginLayout,
      pages: [
        { path: '/login', component: Login },
        { path: '/recuperar-senha', component: ForgotPassword },
        { path: '/403', component: PageNotAuthorized },
        { path: '/404', component: PageNotFound },
        { path: '/500', component: PageServerError },
        { path: '/sair', component: () => <></> },
      ],
    },
    {
      layout: BaseLayout,
      pages: [
        {
          path: '/', exact: true, component: Home, meta: { auth: true },
        },
        {
          path: '/dashboard-metas', exact: true, component: DashboardMetas, meta: { auth: true },
        },
        {
          path: '/dashboard-kpi', exact: true, component: DashboardKpi, meta: { auth: true },
        },
        {
          path: '/dashboard-relatorio', exact: true, component: DashboardOpeningReportFactory, meta: { auth: true },
        },
        {
          path: '/dashboard-cotas-metas', exact: true, component: DashboardCotaMetaFactory, meta: { auth: true },
        },
        {
          path: '/usuarios', exact: true, component: Users, meta: { auth: true },
        },
        {
          path: '/grupo-aprovacoes', exact: true, component: ApprovalGroup, meta: { auth: true },
        },
        {
          path: '/consulta-clientes', exact: true, component: Clients, meta: { auth: true },
        },
        {
          path: '/regras-programa', exact: true, component: ProgramRules, meta: { auth: true },
        },
        {
          path: '/cotas', exact: true, component: Quotas, meta: { auth: true },
        },
        {
          path: '/cotas-bu', exact: true, component: QuotasBU, meta: { auth: true },
        },
        {
          path: '/cotas/:gerente', exact: true, component: QuotasManagers, meta: { auth: true },
        },
        {
          path: '/apuracoes-metas', exact: true, component: GoalCalculations, meta: { auth: true },
        },
        {
          path: '/classificacoes', exact: true, component: Classifications, meta: { auth: true },
        },
        {
          path: '/tipos', exact: true, component: Types, meta: { auth: true },
        },
        {
          path: '/meta-cliente', exact: true, component: CustomerGoals, meta: { auth: true },
        },
        {
          path: '/adicionar-meta', exact: true, component: AddGoalFactory, meta: { auth: true },
        },
        {
          path: '/meta-cliente/:configuracaoPeriodoId/:divisao/:id', exact: true, component: CustomerGoalData, meta: { auth: true },
        },
        {
          path: '/distribuidores', exact: true, component: DistributionsList, meta: { auth: true },
        },
        {
          path: '/configuracoes/distribuidores', exact: true, component: Distributions, meta: { auth: true },
        },
        {
          path: '/produtos-para-bonificacao', exact: true, component: BonusProducts, meta: { auth: true },
        },
        {
          path: '/configuracoes/desconto-em-duplicata', exact: true, component: DuplicateDiscount, meta: { auth: true },
        },
        {
          path: '/configuracoes/regras-programa', exact: true, component: ProgramRulesSettings, meta: { auth: true },
        },
        {
          path: '/importacao', exact: true, component: ImportSpreadsheet, meta: { auth: true },
        },
        {
          path: '/gerar-apuracao', exact: true, component: GenerateCalculation, meta: { auth: true },
        },
        {
          path: '/atualizar-apuracao', exact: true, component: UpdateCalculation, meta: { auth: true },
        },
        {
          path: '/pedido-resgate', exact: true, component: DemandRansom, meta: { auth: true },
        },
        {
          path: '/aprovacao-pedido', exact: true, component: ApproveOrder, meta: { auth: true },
        },
        {
          path: '/saldos', exact: true, component: Balances, meta: { auth: true },
        },
        {
          path: '/aceite-regras-programa', exact: true, component: AcceptedProgramRulesFactory, meta: { auth: true },
        },
      ],
    },
    {
      layout: LoginLayout,
      pages: [
        { path: '*', component: PageNotFound },
      ],
    },
  ]

  return (
    <BrowserRouter>
      <GuardProvider guards={[guardsRoutes]} error={PageNotFound}>
        <RouteFactory routes={routes} />
      </GuardProvider>
    </BrowserRouter>
  )
}

export default Routes
