/* eslint-disable import/extensions, import/no-unresolved */

import { RemoteQuotasBu } from '../../../data/usecases/quotas-bu/quotas-bu'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'
import { makeAxiosHttpClient as makeNoRedirect } from '../../factories/http/axios-http-client-factory-no-redirect'

export const makeRemoteQuotasBu = ():any => new RemoteQuotasBu('/cota/divisao/{DIVISAO}', makeNoRedirect())

export const makeRemoteQuotasBuHeader = ():any => new RemoteQuotasBu('/cota/divisao/{DIVISAO}/percentualcrescimento', makeAxiosHttpClient())

export const makeRemoteQuotasBuImport = (divisao: string):any => (
  new RemoteQuotasBu(`/cota/divisao/${divisao}/import`, makeNoRedirect())
)

export const makeRemoteQuotasBuStatus = ():any => new RemoteQuotasBu('/cota/divisao/{DIVISAO}/status', makeAxiosHttpClient())

export const makeRemoteQuotasBuManager = ():any => new RemoteQuotasBu('/cota/divisao/{DIVISAO}/rateio', makeAxiosHttpClient())

export const makeRemoteQuotasBuTotal = ():any => new RemoteQuotasBu('/cota/divisao/{DIVISAO}/total', makeAxiosHttpClient())

export const makeRemoteQuotasBuItemsPopUp = (divisao: string):any => new RemoteQuotasBu(`/cota/divisao/${divisao}/item/{ID}/detalhe`, makeAxiosHttpClient())

export const makeUpdateGrowth = (data: any):any => (
  new RemoteQuotasBu(
    `/cota/divisao/${data.divisao}/item/${data.numeroItem}/percentualcrescimento`, makeNoRedirect(),
  )
)

export const makeUpdateActualQuota = (data: any):any => (
  new RemoteQuotasBu(
    `/cota/divisao/${data.divisao}/item/${data.numeroItem}/valor`, makeNoRedirect(),
  )
)

export const makeUpdateActualQtd = (data: any):any => (
  new RemoteQuotasBu(
    `/cota/divisao/${data.divisao}/item/${data.numeroItem}/quantidade`, makeNoRedirect(),
  )
)

export const makeUpdateDivisionQuota = (data: any):any => (
  new RemoteQuotasBu(
    `/cota/divisao/${data.divisao}/item/${data.numeroItem}/divisao`, makeNoRedirect(),
  )
)

export const makeUpdateASP = (data: any):any => (
  new RemoteQuotasBu(
    `/cota/divisao/${data.divisao}/item/${data.numeroItem}/valorunitario`, makeNoRedirect(),
  )
)

export const makeRemoteManagersForBU = (data: any):any => (
  new RemoteQuotasBu(`/cota/divisao/${data.divisao}/rateio/preview`, makeNoRedirect(
  )))

export const makeRemoteAllManagersForBU = ():any => (new RemoteQuotasBu('/pessoa/all/paged', makeNoRedirect()))

export const makeUpdateManagersForBU = (data: any):any => (
  new RemoteQuotasBu(`/cota/divisao/${data.divisao}/rateio`, makeNoRedirect())
)

export const makeUpdateShare = (data: any):any => {
  let url = '/cota/divisao/{DIVISAO}/item/{ITEM}/rateio'

  url = url.replace('{DIVISAO}', data.divisao)
  url = url.replace('{ITEM}', data.id)

  return new RemoteQuotasBu(url, makeNoRedirect())
}

export const makeRemoteApprove = (data: any):any => (
  new RemoteQuotasBu(`/cota/divisao/${data.divisao}/aprovacao`, makeNoRedirect())
)

export const makeRemoteDisapprove = (data: any):any => (
  new RemoteQuotasBu(`/cota/divisao/${data.divisao}/reprovacao`, makeNoRedirect())
)

export const makeRemoteSendToApprove = (data: any):any => (
  new RemoteQuotasBu(`/cota/divisao/${data.divisao}/enviaraprovacao`, makeNoRedirect())
)

export const makeRemoteReview = (data: any):any => (
  new RemoteQuotasBu(`/cota/divisao/${data.divisao}/revisar`, makeNoRedirect())
)
