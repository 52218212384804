import styled from 'styled-components'
import { applyProps, propTypesStyledHTML } from '../../../../utils/styled_helpers'

interface BoxProps extends propTypesStyledHTML {
  pos?: 'relative'| 'absolute'| 'static',
  opacity?: string
}

export const Box = styled.div`
  ${(props: BoxProps) => applyProps(props)}
`
