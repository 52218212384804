/* eslint-disable import/extensions, import/no-unresolved */

import { RemoteQuotas } from '../../../data/usecases/quotas/quotas'
import { makeAxiosHttpClient } from '../../factories/http/axios-http-client-factory'

export const makeRemoteQuotas = ():any => new RemoteQuotas('/cota/{QUOTA_ID}', makeAxiosHttpClient())

export const makeRemoteQuotasDivisions = ():any => new RemoteQuotas('/cota/divisao', makeAxiosHttpClient())

export const makeRemoteQuotasClient = ():any => new RemoteQuotas('/cota/cliente', makeAxiosHttpClient())
